import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { UtilService } from '../util.service';
import { TrakingBase } from './tracker-base';

@Injectable({
  providedIn: 'root'
})
export class FirebaseTrackerService extends TrakingBase {
  constructor(private utils: UtilService) {
    super();
  }

  public async appInit(data: any): Promise<boolean> {
    await this.logEvent({ name: 'app_init', params: data });
    return true;
  }

  public async initialize(): Promise<boolean> {
    if ('web' == Capacitor.getPlatform()) {
      await FirebaseAnalytics.initializeFirebase(environment.firebase);
    }

    if (!environment.is_native_portal) {
      await FirebaseAnalytics.setCollectionEnabled({
        enabled: true
      });
    }

    return true;
  }

  public async setUserIdx(user_idx: number): Promise<boolean> {
    await FirebaseAnalytics.setUserId({ userId: `${user_idx}` });
    return true;
  }

  public async logPageView(data: any): Promise<boolean> {
    try {
      const url = data.url;
      if (url) {
        data = {
          screenName: url.split('?')[0],
          nameOverride: this.utils.trimString(url)
        };
        await FirebaseAnalytics.setScreenName(data);
      }
    } catch (error) {
      console.log('error set screen name:', JSON.stringify(error));
    }
    return true;
  }

  async appInstall(data: any) {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({ name: 'app_install', params: data });
    return true;
  }

  public async appOpen(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({ name: 'app_open', params: data });
    return true;
  }

  public async setUserProperty(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const aData = { name: keys[i], value: `${data[keys[i]]}` };
      await FirebaseAnalytics.setUserProperty(aData);
    }
    return true;
  }

  public async logItemImpression(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'item_impression',
      params: data
    });
    return true;
  }

  public async logItemView(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'item_view',
      params: data
    });
    return true;
  }

  public async logItemClick(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'item_click',
      params: data
    });
    return true;
  }

  public async logCompleteOrderTransaction(data: any): Promise<boolean> {
    data = this.utils.filterObject(data, [
      'order_transaction_idx',
      'user_idx',
      'sales_channel_idx',
      'sales_channel_order_number',
      'post_code',
      'order_transaction_title',
      'total_count',
      'total_sell_price',
      'total_user_pay_price',
      'payment_type',
      'coupon_idx',
      'total_use_point'
    ]);

    await FirebaseAnalytics.logEvent({
      name: 'complete_order_transaction',
      params: data
    });
    return true;
  }

  public async logCompleteOrderItem(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'complete_order_item',
      params: data
    });
    return true;
  }

  public async logBannerView(banner: any): Promise<boolean> {
    banner = this.utils.filterFbObject(banner);
    await this.logEvent({
      name: 'banner_view',
      params: banner
    });
    return true;
  }

  public async logBannerImpression(banner: any): Promise<boolean> {
    banner = this.utils.filterFbObject(banner);
    await this.logEvent({
      name: 'banner_impression',
      params: banner
    });
    return true;
  }

  public async logShopSectionView(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'shop_section_view',
      params: data
    });
    return true;
  }
  public async logShopSectionImpression(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'shop_section_impression',
      params: data
    });
    return true;
  }
  public async logNoticeDialogView(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'notice_dialog_view',
      params: data
    });
    return true;
  }
  public async logNoticeDialogImpression(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'notice_dialog_impression',
      params: data
    });
    return true;
  }
  public async login(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'login',
      params: data
    });
    return true;
  }
  public async logPurchase(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'purchase',
      params: data
    });
    return true;
  }
  public async logAddToCart(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'add_cart',
      params: data
    });
    return true;
  }

  public async logWishItem(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'add_wish',
      params: data
    });
    return true;
  }

  public async logPostImpression(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'post_impression',
      params: data
    });
    return true;
  }
  public async logPostClick(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'post_click',
      params: data
    });
    return true;
  }
  public async logPostView(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'post_view',
      params: data
    });
    return true;
  }
  public async logPostLike(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'post_like',
      params: data
    });
    return true;
  }

  public async logPostReply(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'post_reply',
      params: data
    });
    return true;
  }

  public async logSearchItem(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'search_item',
      params: data
    });
    return true;
  }

  public async logSearchPost(data: any): Promise<boolean> {
    data = this.utils.filterFbObject(data);
    await this.logEvent({
      name: 'search_post',
      params: data
    });
    return true;
  }

  public async logEvent(data: { name: string; params: any }): Promise<boolean> {
    await FirebaseAnalytics.logEvent(data);
    return true;
  }

  public async logUserCheckIn(data: any): Promise<boolean> {
    return true;
  }
  public async logUserCheckOut(data: any): Promise<boolean> {
    return true;
  }

  async logFumistView(data: any) {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'class_room_view',
      params: data
    });
    return true;
  }

  async logFumistImpression(data: any) {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'class_room_impression',
      params: data
    });
    return true;
  }

  async logFumistClick(data: any) {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'class_room_click',
      params: data
    });
    return true;
  }

  async logFumistJoin(data: any) {
    data = this.utils.filterFbObject(data);
    await FirebaseAnalytics.logEvent({
      name: 'class_room_join',
      params: data
    });
    return true;
  }
}
