//easy_graphql_comment_start
/*
ab_experiment_idx
experiment_name
weight
finish_value
start_datetime
expire_datetime
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class AbExperiment {
ab_experiment_idx?: number

experiment_name?: string

weight?: string

finish_value?: string

start_datetime?: Date

expire_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

}

export class SelectAbExperimentListReturn {
count?: number;

rows?: AbExperiment[];
}


export class InputAbExperiment{
ab_experiment_idx?: number

experiment_name?: string

weight?: string

finish_value?: string

start_datetime?: Date

expire_datetime?: Date

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
ab_test_idx
user_idx
ab_experiment_idx
value
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class AbTest {
ab_test_idx?: number

user_idx?: number

ab_experiment_idx?: number

value?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectAbTestListReturn {
count?: number;

rows?: AbTest[];
}


export class InputAbTest{
ab_test_idx?: number

user_idx?: number

ab_experiment_idx?: number

value?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
access_history_idx
user_idx
device
regist_datetime
update_datetime
temp_user_idx
user_device_idx
platform
app_init_datetime
app_version
portal_version
app_build

*/
//easy_graphql_comment_end


export class AccessHistory {
access_history_idx?: number

user_idx?: number

device?: string

regist_datetime?: Date

update_datetime?: Date

temp_user_idx?: number

user_device_idx?: number

platform?: string

app_init_datetime?: Date

app_version?: string

portal_version?: string

app_build?: string

//start
user?: User
//end
}

export class SelectAccessHistoryListReturn {
count?: number;

rows?: AccessHistory[];
}


export class InputAccessHistory{
access_history_idx?: number

user_idx?: number

device?: string

regist_datetime?: Date

update_datetime?: Date

temp_user_idx?: number

user_device_idx?: number

platform?: string

app_init_datetime?: Date

app_version?: string

portal_version?: string

app_build?: string


}

//class_end






































//easy_graphql_comment_start
/*
access_history_idx
user_idx
device
regist_datetime
update_datetime
temp_user_idx
user_device_idx
platform
app_init_datetime
app_version
portal_version
app_build

*/
//easy_graphql_comment_end



export class AccessHistoryLog {
access_history_idx?: number

user_idx?: number

device?: string

regist_datetime?: Date

update_datetime?: Date

temp_user_idx?: number

user_device_idx?: number

platform?: string

app_init_datetime?: Date

app_version?: string

portal_version?: string

app_build?: string

}

export class SelectAccessHistoryLogListReturn {
count?: number;

rows?: AccessHistoryLog[];
}


export class InputAccessHistoryLog{
access_history_idx?: number

user_idx?: number

device?: string

regist_datetime?: Date

update_datetime?: Date

temp_user_idx?: number

user_device_idx?: number

platform?: string

app_init_datetime?: Date

app_version?: string

portal_version?: string

app_build?: string


}

//class_end
//easy_graphql_comment_start
/*
access_token_idx
user_idx
user_access_token
token_regist_datetime
token_expire_datetime
token_update_datetime

*/
//easy_graphql_comment_end


export class AccessToken {
access_token_idx?: number

user_idx?: number

user_access_token?: string

token_regist_datetime?: Date

token_expire_datetime?: Date

token_update_datetime?: Date

}

export class SelectAccessTokenListReturn {
count?: number;

rows?: AccessToken[];
}


export class InputAccessToken{
access_token_idx?: number

user_idx?: number

user_access_token?: string

token_regist_datetime?: Date

token_expire_datetime?: Date

token_update_datetime?: Date


}

//class_end





































//easy_graphql_comment_start
/*
ad_proxy_idx
success_login
user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class AdProxy {
ad_proxy_idx?: number

success_login?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectAdProxyListReturn {
count?: number;

rows?: AdProxy[];
}


export class InputAdProxy{
ad_proxy_idx?: number

success_login?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
address_book_idx
user_idx
address_title
address
address_detail
post_code
receiver_name
receiver_phone
receiver_phone_backup
is_base
regist_datetime
update_datetime

*/
//easy_graphql_comment_end






export class AddressBook {
address_book_idx?: number

user_idx?: number

address_title?: string

address?: string

address_detail?: string

post_code?: string

receiver_name?: string

receiver_phone?: string

receiver_phone_backup?: string

is_base?: boolean

regist_datetime?: Date

update_datetime?: Date

}

export class SelectAddressBookListReturn {
count?: number;

rows?: AddressBook[];
}


export class InputAddressBook{
address_book_idx?: number

user_idx?: number

address_title?: string

address?: string

address_detail?: string

post_code?: string

receiver_name?: string

receiver_phone?: string

receiver_phone_backup?: string

is_base?: boolean

regist_datetime?: Date

update_datetime?: Date


}

//class_end





































//easy_graphql_comment_start
/*
adjustment_idx
adjustment_request_idx
company_idx
total_sell_count
total_sell_price
total_delivery_fee
total_buy_price
total_extra_fee
final_adjustment_price
adjustment_fee_rate
adjustment_fee_type
total_refund_count
total_refund_price
regist_datetime
update_datetime
adjustment_status
bank_transfer_datetime
tax_issued_datetime
tax_issue_status

*/
//easy_graphql_comment_end



export class Adjustment {
adjustment_idx?: number

adjustment_request_idx?: number

company_idx?: number

total_sell_count?: number

total_sell_price?: number

total_delivery_fee?: number

total_buy_price?: number

total_extra_fee?: number

final_adjustment_price?: number

adjustment_fee_rate?: number

adjustment_fee_type?: string

total_refund_count?: number

total_refund_price?: number

regist_datetime?: Date

update_datetime?: Date

adjustment_status?: number

bank_transfer_datetime?: Date

tax_issued_datetime?: Date

tax_issue_status?: number

}

export class SelectAdjustmentListReturn {
count?: number;

rows?: Adjustment[];
}


export class InputAdjustment{
adjustment_idx?: number

adjustment_request_idx?: number

company_idx?: number

total_sell_count?: number

total_sell_price?: number

total_delivery_fee?: number

total_buy_price?: number

total_extra_fee?: number

final_adjustment_price?: number

adjustment_fee_rate?: number

adjustment_fee_type?: string

total_refund_count?: number

total_refund_price?: number

regist_datetime?: Date

update_datetime?: Date

adjustment_status?: number

bank_transfer_datetime?: Date

tax_issued_datetime?: Date

tax_issue_status?: number


}

//class_end
//easy_graphql_comment_start
/*
adjustment_request_idx
adjustment_type
adjustment_date
adjustment_count
adjustment_result
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class AdjustmentRequest {
adjustment_request_idx?: number

adjustment_type?: string

adjustment_date?: string

adjustment_count?: number

adjustment_result?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectAdjustmentRequestListReturn {
count?: number;

rows?: AdjustmentRequest[];
}


export class InputAdjustmentRequest{
adjustment_request_idx?: number

adjustment_type?: string

adjustment_date?: string

adjustment_count?: number

adjustment_result?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
admin_log_idx
ref_table
ref_table_idx
user_idx
message
regist_datetime

*/
//easy_graphql_comment_end



export class AdminLog {
admin_log_idx?: number

ref_table?: string

ref_table_idx?: number

user_idx?: number

message?: string

regist_datetime?: Date

//start
update_user?: User
//end
}

export class SelectAdminLogListReturn {
count?: number;

rows?: AdminLog[];
}


export class InputAdminLog{
admin_log_idx?: number

ref_table?: string

ref_table_idx?: number

user_idx?: number

message?: string

regist_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
admin_memo_idx
user_idx
ref_idx
ref_table
second_ref_idx
second_ref_table
memo
regist_datetime
update_datetime
is_deleted
local_idx

*/
//easy_graphql_comment_end



export class AdminMemo {
admin_memo_idx?: number

user_idx?: number

ref_idx?: number

ref_table?: string

second_ref_idx?: number

second_ref_table?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

is_deleted?: boolean

local_idx?: number

//start
user?: User
//end
}

export class SelectAdminMemoListReturn {
count?: number;

rows?: AdminMemo[];
}


export class InputAdminMemo{
admin_memo_idx?: number

user_idx?: number

ref_idx?: number

ref_table?: string

second_ref_idx?: number

second_ref_table?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

is_deleted?: boolean

local_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
analytics_idx
analytics_type
analytics_interval
sales_channel_idx
sales_channel_name
item_option_idx
total_count
total_origin_price
total_sell_price
total_buy_price
total_user_pay_price
total_platform_fee
total_payment_fee
total_company_delivery_fee
target_date
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class Analytics {
analytics_idx?: number

analytics_type?: string

analytics_interval?: string

sales_channel_idx?: number

sales_channel_name?: string

item_option_idx?: number

total_count?: number

total_origin_price?: number

total_sell_price?: number

total_buy_price?: number

total_user_pay_price?: number

total_platform_fee?: number

total_payment_fee?: number

total_company_delivery_fee?: number

target_date?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectAnalyticsListReturn {
count?: number;

rows?: Analytics[];
}


export class InputAnalytics{
analytics_idx?: number

analytics_type?: string

analytics_interval?: string

sales_channel_idx?: number

sales_channel_name?: string

item_option_idx?: number

total_count?: number

total_origin_price?: number

total_sell_price?: number

total_buy_price?: number

total_user_pay_price?: number

total_platform_fee?: number

total_payment_fee?: number

total_company_delivery_fee?: number

target_date?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end

export class CountAnalytics {

analytics_interval: string

total_count?: number

target_date?: string;
}



export class FumiAnalyticsListReturn {

//신규 가입자
regist_user?: number[];

total_regist_user?: number;

//접속자
access_user?: number[];

//게시글
post?: number[];

total_post?: number;

//댓글
reply?: number[];

total_reply?: number;
// //좋아요
// @Field(type => [CountAnalytics])
// like?: CountAnalytics[];

}




export class AnalyticsListReturn {

count?: number;

rows?: Analytics[];

range?: String[];

sales_channel_list?: SalesChannel[];
}


//easy_graphql_comment_start
/*
api_proxy_idx
user_idx
ip
api
param
return_code
response
status
process_datetime
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ApiProxy {
api_proxy_idx?: number

user_idx?: number

ip?: string

api?: string

param?: string

return_code?: number

response?: string

status?: number

process_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

}

export class SelectApiProxyListReturn {
count?: number;

rows?: ApiProxy[];
}


export class InputApiProxy{
api_proxy_idx?: number

user_idx?: number

ip?: string

api?: string

param?: string

return_code?: number

response?: string

status?: number

process_datetime?: Date

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
build
snapshot
url
app_id
channel_name
regist_datetime
update_datetime
android_version_code
ios_version_code
min_ios_version_code
min_android_version_code

*/
//easy_graphql_comment_end




export class AppBuild {
build?: number

snapshot?: string

url?: string

app_id?: string

channel_name?: string

regist_datetime?: Date

update_datetime?: Date

android_version_code?: string

ios_version_code?: string

min_ios_version_code?: string

min_android_version_code?: string

}

export class SelectAppBuildListReturn {
count?: number;

rows?: AppBuild[];
}


export class InputAppBuild{
build?: number

snapshot?: string

url?: string

app_id?: string

channel_name?: string

regist_datetime?: Date

update_datetime?: Date

android_version_code?: string

ios_version_code?: string

min_ios_version_code?: string

min_android_version_code?: string


}

//class_end
//easy_graphql_comment_start
/*
os
enable_update

*/
//easy_graphql_comment_end




export class AppBuildStatus {
os?: string

enable_update?: boolean

}

export class SelectAppBuildStatusListReturn {
count?: number;

rows?: AppBuildStatus[];
}


export class InputAppBuildStatus{
os?: string

enable_update?: boolean


}

//class_end
//easy_graphql_comment_start
/*
app_install_idx
fb_key
user_idx
user_device_idx
af_status
af_message
platform
install_time
is_first_launch
deep_link_value
regist_datetime
update_datetime
onelink_idx
app_open_pid
app_open_campaign
app_open_adset
after_login_user_idx
before_login_user_idx
reword_status
reword_check_datetime
point_ready_idx
point_idx
memo
user_install_idx

*/
//easy_graphql_comment_end



export class AppInstall {
app_install_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

af_status?: string

af_message?: string

platform?: string

install_time?: Date

is_first_launch?: boolean

deep_link_value?: string

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

after_login_user_idx?: number

before_login_user_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

point_idx?: number

memo?: string

user_install_idx?: number

//start
onelink?: Onelink


user?: User

//end
}

export class SelectAppInstallListReturn {
count?: number;

rows?: AppInstall[];
}


export class InputAppInstall{
app_install_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

af_status?: string

af_message?: string

platform?: string

install_time?: Date

is_first_launch?: boolean

deep_link_value?: string

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

after_login_user_idx?: number

before_login_user_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

point_idx?: number

memo?: string

user_install_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
app_install_idx
fb_key
user_idx
user_device_idx
af_status
af_message
platform
install_time
is_first_launch
deep_link_value
regist_datetime
update_datetime
onelink_idx
app_open_pid
app_open_campaign
app_open_adset
after_login_user_idx
before_login_user_idx
reword_status
reword_check_datetime
point_ready_idx
point_idx
memo
user_install_idx

*/
//easy_graphql_comment_end



export class AppInstallLog {
app_install_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

af_status?: string

af_message?: string

platform?: string

install_time?: Date

is_first_launch?: boolean

deep_link_value?: string

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

after_login_user_idx?: number

before_login_user_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

point_idx?: number

memo?: string

user_install_idx?: number

}

export class SelectAppInstallLogListReturn {
count?: number;

rows?: AppInstallLog[];
}


export class InputAppInstallLog{
app_install_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

af_status?: string

af_message?: string

platform?: string

install_time?: Date

is_first_launch?: boolean

deep_link_value?: string

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

after_login_user_idx?: number

before_login_user_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

point_idx?: number

memo?: string

user_install_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
app_open_idx
fb_key
user_idx
user_device_idx
platform
app_open_pid
app_open_campaign
app_open_adset
launch_source
etc
app_open_datetime
regist_datetime
update_datetime
url
ref_idx
ref_table
onelink_idx
reword_status
reword_check_datetime
point_ready_idx
memo

*/
//easy_graphql_comment_end



export class AppOpen {
app_open_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

platform?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

launch_source?: string

etc?: string

app_open_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

url?: string

ref_idx?: string

ref_table?: string

onelink_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

memo?: string

//start
onelink?: Onelink

user?: User


//end
}

export class SelectAppOpenListReturn {
count?: number;

rows?: AppOpen[];
}


export class InputAppOpen{
app_open_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

platform?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

launch_source?: string

etc?: string

app_open_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

url?: string

ref_idx?: string

ref_table?: string

onelink_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

memo?: string


}

//class_end
//easy_graphql_comment_start
/*
app_open_idx
fb_key
user_idx
user_device_idx
platform
app_open_pid
app_open_campaign
app_open_adset
launch_source
etc
app_open_datetime
regist_datetime
update_datetime
url
ref_idx
ref_table
onelink_idx
reword_status
reword_check_datetime
point_ready_idx
memo

*/
//easy_graphql_comment_end



export class AppOpenLog {
app_open_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

platform?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

launch_source?: string

etc?: string

app_open_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

url?: string

ref_idx?: string

ref_table?: string

onelink_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

memo?: string

}

export class SelectAppOpenLogListReturn {
count?: number;

rows?: AppOpenLog[];
}


export class InputAppOpenLog{
app_open_idx?: number

fb_key?: string

user_idx?: number

user_device_idx?: number

platform?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

launch_source?: string

etc?: string

app_open_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

url?: string

ref_idx?: string

ref_table?: string

onelink_idx?: number

reword_status?: number

reword_check_datetime?: Date

point_ready_idx?: number

memo?: string


}

//class_end
//easy_graphql_comment_start
/*
appsflyer_day_idx
key
value
value_number
target_date_string
target_date
media_source
campaign
country
etc
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class AppsflyerDayHistory {
appsflyer_day_idx?: number

key?: string

value?: string

value_number?: number

target_date_string?: string

target_date?: Date

media_source?: string

campaign?: string

country?: string

etc?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectAppsflyerDayHistoryListReturn {
count?: number;

rows?: AppsflyerDayHistory[];
}


export class InputAppsflyerDayHistory{
appsflyer_day_idx?: number

key?: string

value?: string

value_number?: number

target_date_string?: string

target_date?: Date

media_source?: string

campaign?: string

country?: string

etc?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
bank_account_idx
bank_name
account_number
balance
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class BankAccount {
bank_account_idx?: number

bank_name?: string

account_number?: string

balance?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectBankAccountListReturn {
count?: number;

rows?: BankAccount[];
}


export class InputBankAccount{
bank_account_idx?: number

bank_name?: string

account_number?: string

balance?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
bank_account_log_idx
bank_name
account_number
trans_ref_key
trans_datetime
withdraw
deposit
trans_remark
balance
regist_datetime
update_datetime
memo
is_fee
is_checked

*/
//easy_graphql_comment_end



export class BankAccountLog {
bank_account_log_idx?: number

bank_name?: string

account_number?: string

trans_ref_key?: string

trans_datetime?: Date

withdraw?: number

deposit?: number

trans_remark?: string

balance?: number

regist_datetime?: Date

update_datetime?: Date

memo?: string

is_fee?: number

is_checked?: number

}

export class SelectBankAccountLogListReturn {
count?: number;

rows?: BankAccountLog[];
}


export class InputBankAccountLog{
bank_account_log_idx?: number

bank_name?: string

account_number?: string

trans_ref_key?: string

trans_datetime?: Date

withdraw?: number

deposit?: number

trans_remark?: string

balance?: number

regist_datetime?: Date

update_datetime?: Date

memo?: string

is_fee?: number

is_checked?: number


}

//class_end
//easy_graphql_comment_start
/*
bank_transfer_idx
user_idx
order_transaction_idx
order_item_idx
payment_idx
check_sum
bank_name
bank_account
bank_owner
bank_code
amount
status
complete_datetime
memo
error
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class BankTransfer {
bank_transfer_idx?: number

user_idx?: number

order_transaction_idx?: number

order_item_idx?: number

payment_idx?: number

check_sum?: number

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

amount?: number

status?: number

complete_datetime?: Date

memo?: string

error?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectBankTransferListReturn {
count?: number;

rows?: BankTransfer[];
}


export class InputBankTransfer{
bank_transfer_idx?: number

user_idx?: number

order_transaction_idx?: number

order_item_idx?: number

payment_idx?: number

check_sum?: number

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

amount?: number

status?: number

complete_datetime?: Date

memo?: string

error?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
banner_idx
banner_title
banner_location
banner_link_type
banner_img_url
banner_link_url
banner_click_count
banner_onoff
display_order
regist_datetime
update_datetime
banner_type
banner_impression_count
banner_view_count

*/
//easy_graphql_comment_end





export class Banner {
banner_idx?: number

banner_title?: string

banner_location?: 'main_top'|'main_center'|'main_bottom'|'popup'

banner_link_type?: 'inside'|'external'

banner_img_url?: string

banner_link_url?: string

banner_click_count?: number

banner_onoff?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date

banner_type?: string

banner_impression_count?: number

banner_view_count?: number

//start
// @AfterCreate
// static async syncBannerCreate(post_tv: any) {
// 	console.log('callback banner create');
// 	await FirebaseService.getInstance().fullSyncBanner();
// }

// @AfterDestroy
// static async syncBannerDelete(post_tv: any) {
// 	console.log('callback banner delete');
// 	await FirebaseService.getInstance().fullSyncBanner();
// }

// // @AfterUpdate
// // @AfterUpsert
// // @AfterSave
// @AfterBulkUpdate
// static async syncBannerUpdate(post_tv: any) {
// 	console.log('callback banner update');
// 	await FirebaseService.getInstance().fullSyncBanner();
// }


//end
}

export class SelectBannerListReturn {
count?: number;

rows?: Banner[];
}


export class InputBanner{
banner_idx?: number

banner_title?: string

banner_location?: 'main_top'|'main_center'|'main_bottom'|'popup'

banner_link_type?: 'inside'|'external'

banner_img_url?: string

banner_link_url?: string

banner_click_count?: number

banner_onoff?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date

banner_type?: string

banner_impression_count?: number

banner_view_count?: number


}

//class_end
//easy_graphql_comment_start
/*
bigquery_analytics_idx
target_key
target_unique_key
target_date
impression_count
click_count
view_count
cart_count
complete_count
user_count
item_idx
shop_section_idx
banner_idx
notice_dialog_idx
regist_datetime
update_datetime
click_ratio
order_ratio
join_ratio
ref_idx
ref_table

*/
//easy_graphql_comment_end



export class BigqueryAnalytics {
bigquery_analytics_idx?: number

target_key?: string

target_unique_key?: string

target_date?: string

impression_count?: number

click_count?: number

view_count?: number

cart_count?: number

complete_count?: number

user_count?: number

item_idx?: number

shop_section_idx?: number

banner_idx?: number

notice_dialog_idx?: number

regist_datetime?: Date

update_datetime?: Date

click_ratio?: number

order_ratio?: number

join_ratio?: number

ref_idx?: number

ref_table?: string

//start
item?: Item

shop_section?: ShopSection

banner?: Banner

notice_dialog?: NoticeDialog

//end
}

export class SelectBigqueryAnalyticsListReturn {
count?: number;

rows?: BigqueryAnalytics[];
}


export class InputBigqueryAnalytics{
bigquery_analytics_idx?: number

target_key?: string

target_unique_key?: string

target_date?: string

impression_count?: number

click_count?: number

view_count?: number

cart_count?: number

complete_count?: number

user_count?: number

item_idx?: number

shop_section_idx?: number

banner_idx?: number

notice_dialog_idx?: number

regist_datetime?: Date

update_datetime?: Date

click_ratio?: number

order_ratio?: number

join_ratio?: number

ref_idx?: number

ref_table?: string


}

//class_end
//easy_graphql_comment_start
/*
bigquery_class_room_analytics
class_room_idx
target_key
target_unique_key
target_date
impression_count
click_count
view_count
join_count
user_count
regist_datetime
update_datetime
click_ratio
join_ratio
ref_idx
ref_table

*/
//easy_graphql_comment_end



export class BigqueryClassRoomAnalytics {
bigquery_class_room_analytics?: number

class_room_idx?: number

target_key?: string

target_unique_key?: string

target_date?: string

impression_count?: number

click_count?: number

view_count?: number

join_count?: number

user_count?: number

regist_datetime?: Date

update_datetime?: Date

click_ratio?: number

join_ratio?: number

ref_idx?: number

ref_table?: string

}

export class SelectBigqueryClassRoomAnalyticsListReturn {
count?: number;

rows?: BigqueryClassRoomAnalytics[];
}


export class InputBigqueryClassRoomAnalytics{
bigquery_class_room_analytics?: number

class_room_idx?: number

target_key?: string

target_unique_key?: string

target_date?: string

impression_count?: number

click_count?: number

view_count?: number

join_count?: number

user_count?: number

regist_datetime?: Date

update_datetime?: Date

click_ratio?: number

join_ratio?: number

ref_idx?: number

ref_table?: string


}

//class_end
//easy_graphql_comment_start
/*
brand_idx
name
description
thumbnail_url
is_open
display_order
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Brand {
brand_idx?: number

name?: string

description?: string

thumbnail_url?: string

is_open?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectBrandListReturn {
count?: number;

rows?: Brand[];
}


export class InputBrand{
brand_idx?: number

name?: string

description?: string

thumbnail_url?: string

is_open?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
category_idx
category_name
display_order
parent_category_idx
level
is_open
display_group_type
description
img_url
category_type
link_url
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Category {
category_idx?: number

category_name?: string

display_order?: number

parent_category_idx?: number

level?: number

is_open?: boolean

display_group_type?: string

description?: string

img_url?: string

category_type?: string

link_url?: string

regist_datetime?: Date

update_datetime?: Date

//start
parent_category?: Category
//end
}

export class SelectCategoryListReturn {
count?: number;

rows?: Category[];
}


export class InputCategory{
category_idx?: number

category_name?: string

display_order?: number

parent_category_idx?: number

level?: number

is_open?: boolean

display_group_type?: string

description?: string

img_url?: string

category_type?: string

link_url?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
chat_join_idx
user_idx
chat_room_idx
join_status
is_on
new_message_count
push_alarm_status
heart_beat_datetime
reject_message
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ChatJoin {
chat_join_idx?: number

user_idx?: number

chat_room_idx?: number

join_status?: number

is_on?: boolean

new_message_count?: number

push_alarm_status?: number

heart_beat_datetime?: Date

reject_message?: string

regist_datetime?: Date

update_datetime?: Date

//start
chat_room?: ChatRoom

user?: User
//end
}

export class SelectChatJoinListReturn {
count?: number;

rows?: ChatJoin[];
}


export class InputChatJoin{
chat_join_idx?: number

user_idx?: number

chat_room_idx?: number

join_status?: number

is_on?: boolean

new_message_count?: number

push_alarm_status?: number

heart_beat_datetime?: Date

reject_message?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end




export class InputChatJoinHeartBeat{

user_idx?: number

chat_room_idx?: number

is_on?: boolean

push_alarm_status?: number

heart_beat_datetime?: Date

}

//easy_graphql_comment_start
/*
chat_message_idx
chat_room_idx
user_idx
message_type
message
message_receiver_count
message_unread_count
noti_process_status
noti_process_error
regist_datetime
update_datetime
link_type
link_url
link_message
link_idx

*/
//easy_graphql_comment_end



export class ChatMessage {
chat_message_idx?: number

chat_room_idx?: number

user_idx?: number

message_type?: string

message?: string

message_receiver_count?: number

message_unread_count?: number

noti_process_status?: number

noti_process_error?: string

regist_datetime?: Date

update_datetime?: Date

link_type?: number

link_url?: string

link_message?: string

link_idx?: number

//start
user?: User

chat_room?: ChatRoom



//end
}

export class SelectChatMessageListReturn {
count?: number;

rows?: ChatMessage[];
}


export class InputChatMessage{
chat_message_idx?: number

chat_room_idx?: number

user_idx?: number

message_type?: string

message?: string

message_receiver_count?: number

message_unread_count?: number

noti_process_status?: number

noti_process_error?: string

regist_datetime?: Date

update_datetime?: Date

link_type?: number

link_url?: string

link_message?: string

link_idx?: number


}

//class_end



export class InputBulkChatMessage{
rows?: InputChatMessage[];
}



//easy_graphql_comment_start
/*
chat_message_read_idx
chat_message_idx
chat_room_idx
user_idx
message_read_datetime
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ChatMessageRead {
chat_message_read_idx?: number

chat_message_idx?: number

chat_room_idx?: number

user_idx?: number

message_read_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

//start
chat_message?: ChatMessage

//end
}

export class SelectChatMessageReadListReturn {
count?: number;

rows?: ChatMessageRead[];
}


export class InputChatMessageRead{
chat_message_read_idx?: number

chat_message_idx?: number

chat_room_idx?: number

user_idx?: number

message_read_datetime?: Date

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
chat_room_idx
master_user_idx
chat_room_key
chat_room_type
chat_room_title
chat_room_img_url
recent_message
join_user_count
ref_idx
ref_table
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ChatRoom {
chat_room_idx?: number

master_user_idx?: number

chat_room_key?: string

chat_room_type?: string

chat_room_title?: string

chat_room_img_url?: string

recent_message?: string

join_user_count?: number

ref_idx?: number

ref_table?: string

regist_datetime?: Date

update_datetime?: Date

//start
chat_join_list: ChatJoin[];
//end
}

export class SelectChatRoomListReturn {
count?: number;

rows?: ChatRoom[];
}


export class InputChatRoom{
chat_room_idx?: number

master_user_idx?: number

chat_room_key?: string

chat_room_type?: string

chat_room_title?: string

chat_room_img_url?: string

recent_message?: string

join_user_count?: number

ref_idx?: number

ref_table?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
class_join_idx
class_room_idx
user_idx
regist_datetime
update_datetime
memo
total_order_count
total_order_price
total_incentive_price
total_confirmed_order_price
total_confirmed_incentive_price
total_adjusted_incentive_price
total_canceled_order_price
total_canceled_count
total_canceled_incentive_price
total_receive_point
current_ready_incentive_price
current_confirmed_incentive_price

*/
//easy_graphql_comment_end



export class ClassJoin {
class_join_idx?: number

class_room_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

memo?: string

total_order_count?: number

total_order_price?: number

total_incentive_price?: number

total_confirmed_order_price?: number

total_confirmed_incentive_price?: number

total_adjusted_incentive_price?: number

total_canceled_order_price?: number

total_canceled_count?: number

total_canceled_incentive_price?: number

total_receive_point?: number

current_ready_incentive_price?: number

current_confirmed_incentive_price?: number

//start

class_room?: ClassRoom

user?: User



//end
}

export class SelectClassJoinListReturn {
count?: number;

rows?: ClassJoin[];
}


export class InputClassJoin{
class_join_idx?: number

class_room_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

memo?: string

total_order_count?: number

total_order_price?: number

total_incentive_price?: number

total_confirmed_order_price?: number

total_confirmed_incentive_price?: number

total_adjusted_incentive_price?: number

total_canceled_order_price?: number

total_canceled_count?: number

total_canceled_incentive_price?: number

total_receive_point?: number

current_ready_incentive_price?: number

current_confirmed_incentive_price?: number


}

//class_end
//easy_graphql_comment_start
/*
class_join_idx
class_room_idx
user_idx
regist_datetime
update_datetime
memo

*/
//easy_graphql_comment_end



export class ClassJoinLog {
class_join_idx?: number

class_room_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

memo?: string

}

export class SelectClassJoinLogListReturn {
count?: number;

rows?: ClassJoinLog[];
}


export class InputClassJoinLog{
class_join_idx?: number

class_room_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

memo?: string


}

//class_end
//easy_graphql_comment_start
/*
class_room_idx
class_name
user_idx
title
description
total_user_count
total_order_count
total_order_price
total_incentive_price
total_confirmed_order_price
total_confirmed_incentive_price
total_canceled_order_price
total_canceled_count
total_adjusted_incentive_price
current_ready_incentive_price
current_confirmed_incentive_price
class_img_url
class_status
regist_datetime
update_datetime
max_user_count
class_level
parent_class_room
is_leader
is_super_leader
current_order_price
current_point_balance
total_visit_count
is_point_add_enable
current_incentive_price
current_shop_point_balance
is_enable_incentive_pay
last_content_regist_datetime

*/
//easy_graphql_comment_end



export class ClassRoom {
class_room_idx?: number

class_name?: string

user_idx?: number

title?: string

description?: string

total_user_count?: number

total_order_count?: number

total_order_price?: number

total_incentive_price?: number

total_confirmed_order_price?: number

total_confirmed_incentive_price?: number

total_canceled_order_price?: number

total_canceled_count?: number

total_adjusted_incentive_price?: number

current_ready_incentive_price?: number

current_confirmed_incentive_price?: number

class_img_url?: string

class_status?: number

regist_datetime?: Date

update_datetime?: Date

max_user_count?: number

class_level?: number

parent_class_room?: number

is_leader?: boolean

is_super_leader?: boolean

current_order_price?: number

current_point_balance?: number

total_visit_count?: number

is_point_add_enable?: boolean

current_incentive_price?: number

current_shop_point_balance?: number

is_enable_incentive_pay?: number

last_content_regist_datetime?: Date

//start

user?: User

//end
}

export class SelectClassRoomListReturn {
count?: number;

rows?: ClassRoom[];
}


export class InputClassRoom{
class_room_idx?: number

class_name?: string

user_idx?: number

title?: string

description?: string

total_user_count?: number

total_order_count?: number

total_order_price?: number

total_incentive_price?: number

total_confirmed_order_price?: number

total_confirmed_incentive_price?: number

total_canceled_order_price?: number

total_canceled_count?: number

total_adjusted_incentive_price?: number

current_ready_incentive_price?: number

current_confirmed_incentive_price?: number

class_img_url?: string

class_status?: number

regist_datetime?: Date

update_datetime?: Date

max_user_count?: number

class_level?: number

parent_class_room?: number

is_leader?: boolean

is_super_leader?: boolean

current_order_price?: number

current_point_balance?: number

total_visit_count?: number

is_point_add_enable?: boolean

current_incentive_price?: number

current_shop_point_balance?: number

is_enable_incentive_pay?: number

last_content_regist_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
class_room_customer_order_item_idx
class_room_idx
class_join_idx
order_item_idx
order_transaction_idx
incentive_price
regist_datetime
update_datetime
status
order_cancel_datetime
incentive_insert_datetime
class_room_incentive_idx

*/
//easy_graphql_comment_end



export class ClassRoomCustomerOrderItem {
class_room_customer_order_item_idx?: number

class_room_idx?: number

class_join_idx?: number

order_item_idx?: number

order_transaction_idx?: number

incentive_price?: number

regist_datetime?: Date

update_datetime?: Date

status?: number

order_cancel_datetime?: Date

incentive_insert_datetime?: Date

class_room_incentive_idx?: number

//start
order_item?: OrderItem
//end
}

export class SelectClassRoomCustomerOrderItemListReturn {
count?: number;

rows?: ClassRoomCustomerOrderItem[];
}


export class InputClassRoomCustomerOrderItem{
class_room_customer_order_item_idx?: number

class_room_idx?: number

class_join_idx?: number

order_item_idx?: number

order_transaction_idx?: number

incentive_price?: number

regist_datetime?: Date

update_datetime?: Date

status?: number

order_cancel_datetime?: Date

incentive_insert_datetime?: Date

class_room_incentive_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
class_room_incentive_idx
class_room_idx
user_idx
target_month
total_incentive_price
status
bank_transfer_datetime
bank_name
bank_account
bank_owner
bank_code
memo
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ClassRoomIncentive {
class_room_incentive_idx?: number

class_room_idx?: number

user_idx?: number

target_month?: Date

total_incentive_price?: number

status?: number

bank_transfer_datetime?: Date

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectClassRoomIncentiveListReturn {
count?: number;

rows?: ClassRoomIncentive[];
}


export class InputClassRoomIncentive{
class_room_incentive_idx?: number

class_room_idx?: number

user_idx?: number

target_month?: Date

total_incentive_price?: number

status?: number

bank_transfer_datetime?: Date

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end



//easy_graphql_comment_start
/*
class_room_order_item_idx
class_room_idx
order_item_idx
order_transaction_idx
incentive_price
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ClassRoomOrderItem {
class_room_order_item_idx?: number

class_room_idx?: number

order_item_idx?: number

order_transaction_idx?: number

incentive_price?: number

regist_datetime?: Date

update_datetime?: Date

//start
order_item?: OrderItem
//end
}

export class SelectClassRoomOrderItemListReturn {
count?: number;

rows?: ClassRoomOrderItem[];
}


export class InputClassRoomOrderItem{
class_room_order_item_idx?: number

class_room_idx?: number

order_item_idx?: number

order_transaction_idx?: number

incentive_price?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
class_room_point_idx
class_room_idx
class_join_idx
point_amount
point_type
receive_user_idx
expire_date
point_idx
point_status
point_title
insert_key
regist_datetime
update_datetime
insert_point_datetime

*/
//easy_graphql_comment_end



export class ClassRoomPoint {
class_room_point_idx?: number

class_room_idx?: number

class_join_idx?: number

point_amount?: number

point_type?: string

receive_user_idx?: number

expire_date?: Date

point_idx?: number

point_status?: string

point_title?: string

insert_key?: string

regist_datetime?: Date

update_datetime?: Date

insert_point_datetime?: Date

//start
class_join?: ClassJoin
//end
}

export class SelectClassRoomPointListReturn {
count?: number;

rows?: ClassRoomPoint[];
}


export class InputClassRoomPoint{
class_room_point_idx?: number

class_room_idx?: number

class_join_idx?: number

point_amount?: number

point_type?: string

receive_user_idx?: number

expire_date?: Date

point_idx?: number

point_status?: string

point_title?: string

insert_key?: string

regist_datetime?: Date

update_datetime?: Date

insert_point_datetime?: Date


//input_start
index_key_month?: string

insert_count?: number
//input_end
}

//class_end
//easy_graphql_comment_start
/*
class_room_point_idx
class_room_idx
amount
point_type
receive_user_idx
expire_date
point_idx
point_status
point_title
insert_key
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ClassRoomPointLog {
class_room_point_idx?: number

class_room_idx?: number

amount?: number

point_type?: string

receive_user_idx?: number

expire_date?: Date

point_idx?: number

point_status?: string

point_title?: string

insert_key?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectClassRoomPointLogListReturn {
count?: number;

rows?: ClassRoomPointLog[];
}


export class InputClassRoomPointLog{
class_room_point_idx?: number

class_room_idx?: number

amount?: number

point_type?: string

receive_user_idx?: number

expire_date?: Date

point_idx?: number

point_status?: string

point_title?: string

insert_key?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
class_room_post_idx
class_room_idx
post_idx
is_open
display_order
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ClassRoomPost {
class_room_post_idx?: number

class_room_idx?: number

post_idx?: number

is_open?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date

//start
post?: Post
//end
}

export class SelectClassRoomPostListReturn {
count?: number;

rows?: ClassRoomPost[];
}


export class InputClassRoomPost{
class_room_post_idx?: number

class_room_idx?: number

post_idx?: number

is_open?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end



export class InputClassRoomPostList{
list?: InputClassRoomPost[];
}


//easy_graphql_comment_start
/*
class_room_rank_idx
class_room_idx
target_date
current_rank
before_rank
regist_datetime
update_datetime
total_point

*/
//easy_graphql_comment_end



export class ClassRoomRank {
class_room_rank_idx?: number

class_room_idx?: number

target_date?: string

current_rank?: number

before_rank?: number

regist_datetime?: Date

update_datetime?: Date

total_point?: number

//start
class_room?: ClassRoom

//end
}

export class SelectClassRoomRankListReturn {
count?: number;

rows?: ClassRoomRank[];
}


export class InputClassRoomRank{
class_room_rank_idx?: number

class_room_idx?: number

target_date?: string

current_rank?: number

before_rank?: number

regist_datetime?: Date

update_datetime?: Date

total_point?: number


}

//class_end
//easy_graphql_comment_start
/*
class_room_shop_section_idx
class_room_idx
shop_section_idx
is_open
display_order
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ClassRoomShopSection {
class_room_shop_section_idx?: number

class_room_idx?: number

shop_section_idx?: number

is_open?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date

//start
shop_section?: ShopSection
//end
}

export class SelectClassRoomShopSectionListReturn {
count?: number;

rows?: ClassRoomShopSection[];
}


export class InputClassRoomShopSection{
class_room_shop_section_idx?: number

class_room_idx?: number

shop_section_idx?: number

is_open?: boolean

display_order?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end




export class InputClassRoomShopSectionList{
list?: InputClassRoomShopSection[];
}

//easy_graphql_comment_start
/*
code_map_idx
type
code
mapped_code
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class CodeMap {
code_map_idx?: number

type?: string

code?: string

mapped_code?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectCodeMapListReturn {
count?: number;

rows?: CodeMap[];
}


export class InputCodeMap{
code_map_idx?: number

type?: string

code?: string

mapped_code?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end



export class CompositeFilterDescriptor {

filters?: FilterDescriptor[];

logic?: 'or' | 'and';

}

export class FilterDescriptor {

field?: string

operator?: string


value?: string;

ignoreCase?: boolean;

//compoise 타입
filters?: FilterDescriptor[];

logic?: 'or' | 'and';

}
// {"sort":[],"skip":0,"take":10,"filter":{"logic":"and","filters":[{"field":"user_name","operator":"contains","value":"배동환"}]}}
// {"sort":[],"skip":0,"take":10,"filter":{"logic":"and","filters":[{"field":"user_name","operator":"contains","value":"배동환"}]}}
export class State {

skip?: number;

take?: number;

filter?: CompositeFilterDescriptor;

group?: GroupDescriptor[];

sort?: SortDescriptor[];

}

export class SortDescriptor {

field?: string;

dir?: 'asc' | 'desc';


}


export class GroupDescriptor {

field?: string;

dir?: 'asc' | 'desc';


aggregates?: AggregateDescriptor[];
}

export class AggregateDescriptor {

/**
* The name of the record field on which the function will be executed.
*/
field: string;
/**
* The aggregate function that will be calculated.
*/
aggregate: 'count' | 'sum' | 'average' | 'min' | 'max';
}
//easy_graphql_comment_start
/*
company_idx
company_name
company_img_url
company_description
company_notice
company_address
company_type
regist_datetime
update_datetime
create_user_idx
update_user_idx
manager_user_idx
adjustment_period_type
adjustment_fee_type
adjustment_fee_rate
contract_type
launch_datetime
order_deadline
order_send_type
bank_name
bank_account
bank_owner
adjustment_fee_policy
adjustment_fee_event_policy
company_group_idx

*/
//easy_graphql_comment_end


export class Company {
company_idx?: number

company_name?: string

company_img_url?: string

company_description?: string

company_notice?: string

company_address?: string

company_type?: string

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

adjustment_period_type?: string

adjustment_fee_type?: string

adjustment_fee_rate?: number

contract_type?: string

launch_datetime?: Date

order_deadline?: Date

order_send_type?: string

bank_name?: string

bank_account?: string

bank_owner?: string

adjustment_fee_policy?: string

adjustment_fee_event_policy?: string

company_group_idx?: number

//start
manager_user?: User

create_user?: User

update_user?: User


company_manager_list: CompanyManager[];

company_worker_list: CompanyWorker[];


company_group?: CompanyGroup
//end
}

export class SelectCompanyListReturn {
count?: number;

rows?: Company[];
}


export class InputCompany{
company_idx?: number

company_name?: string

company_img_url?: string

company_description?: string

company_notice?: string

company_address?: string

company_type?: string

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

adjustment_period_type?: string

adjustment_fee_type?: string

adjustment_fee_rate?: number

contract_type?: string

launch_datetime?: Date

order_deadline?: Date

order_send_type?: string

bank_name?: string

bank_account?: string

bank_owner?: string

adjustment_fee_policy?: string

adjustment_fee_event_policy?: string

company_group_idx?: number


}

//class_end





































//easy_graphql_comment_start
/*
company_group_idx
group_type
profit_share_rate
group_description
is_enable_basket_coupon

*/
//easy_graphql_comment_end



export class CompanyGroup {
company_group_idx?: number

group_type?: string

profit_share_rate?: number

group_description?: string

is_enable_basket_coupon?: number

}

export class SelectCompanyGroupListReturn {
count?: number;

rows?: CompanyGroup[];
}


export class InputCompanyGroup{
company_group_idx?: number

group_type?: string

profit_share_rate?: number

group_description?: string

is_enable_basket_coupon?: number


}

//class_end
//easy_graphql_comment_start
/*
company_idx
user_idx
manager_type
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class CompanyManager {
company_idx?: number

user_idx?: number

manager_type?: string

regist_datetime?: Date

update_datetime?: Date

//start
company?: Company

//end
}

export class SelectCompanyManagerListReturn {
count?: number;

rows?: CompanyManager[];
}


export class InputCompanyManager{
company_idx?: number

user_idx?: number

manager_type?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end





































//easy_graphql_comment_start
/*
company_secret_idx
company_idx
secret
regist_datetiem
update_datetime

*/
//easy_graphql_comment_end



export class CompanySecret {
company_secret_idx?: number

company_idx?: string

secret?: string

regist_datetiem?: Date

update_datetime?: Date

}

export class SelectCompanySecretListReturn {
count?: number;

rows?: CompanySecret[];
}


export class InputCompanySecret{
company_secret_idx?: number

company_idx?: string

secret?: string

regist_datetiem?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
company_worker_idx
company_idx
worker_type
worker_name
worker_phone
worker_email
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class CompanyWorker {
company_worker_idx?: number

company_idx?: number

worker_type?: string

worker_name?: string

worker_phone?: string

worker_email?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectCompanyWorkerListReturn {
count?: number;

rows?: CompanyWorker[];
}


export class InputCompanyWorker{
company_worker_idx?: number

company_idx?: number

worker_type?: string

worker_name?: string

worker_phone?: string

worker_email?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end





































//easy_graphql_comment_start
/*
config_idx
key
value
explain
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Config {
config_idx?: number

key?: string

value?: string

explain?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectConfigListReturn {
count?: number;

rows?: Config[];
}


export class InputConfig{
config_idx?: number

key?: string

value?: string

explain?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
config_service_idx
key_name
key_value
key_type
explain_key
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class ConfigService {
config_service_idx?: number

key_name?: string

key_value?: string

key_type?: string

explain_key?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectConfigServiceListReturn {
count?: number;

rows?: ConfigService[];
}


export class InputConfigService{
config_service_idx?: number

key_name?: string

key_value?: string

key_type?: string

explain_key?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end





































export interface Upload {
filename: string;
mimetype: string;
encoding: string;
}




export enum PUSH_ALARM_STATUS {
READY = 0,
PENDING = 1,
COMPLETE = 2,
ERROR = 3,
CLOSED = 4
}


export enum CHAT_ROOM_JOIN_STATUS {
READY = 0,
APPROVED = 1,
REJECTED = 2,
}


export enum CHAT_MESSAGE_LINK_TYPE{
ITEM = 1,
POST  =2,
USER = 3,
STORE= 4,
EXTERNAL =5
}



export const item_status_list = [
{
status: null,
text: '판매상태'
},
{
status: 0,
text: '판매대기'
},
{
status: 1,
text: '판매중'
},
{
status: 2,
text: '품절'
},
{
status: 3,
text: '판매중지'
},
{
status: 4,
text: '판매종료'
},
{
status: 5,
text: '판매금지'
},
{
status: 6,
text: '판매예약중'
}
]

export const item_status_hash = {
0: '판매대기',
1: '판매중',
2: '품절',
3: '판매중지',
4: '판매종료',
5: '판매금지',
6: '판매예약중',
}

export const is_open_list = [
{
status: null,
text: '공개여부'
},
{
status: false,
text: '비공개'
},
{
status: true,
text: '공개'
}
]


export enum TEMP_ORDER_STATUS {
READY = 1,
CAN_REGIST = 2,
ALREADY = 3,
NO_ITEM_MATH = 4,
NO_STOCK = 5,
DONE = 6,
ERROR = 7
}

export const temp_order_status_list = [
{ status: null, text: '전체' },
{ status: 1, text: '처리전' },
{ status: 2, text: '등록가능' },
{ status: 3, text: '이미등록됨' },
{ status: 4, text: '바코드매핑실패' },
{ status: 5, text: '재고부족' },
{ status: 6, text: '등록완료' }
]


export const temp_order_status_hash = {
1: '처리전',
2: '등록가능',
3: '이미등록됨',
4: '바코드매핑실패',
5: '재고부족',
6: '등록완료'
}

export const height_options = [
{
text: '150cm 이하',
value: 145,
},
{
text: '150-155cm',
value: 150,
},
{
text: '155-160cm',
value: 155,
},
{
text: '160-165cm',
value: 160,
},
{
text: '165-170cm',
value: 165,
},
{
text: '170-175cm',
value: 170,
},
{
text: '175-180cm',
value: 175,
},
{
text: '180-185cm',
value: 180,
},
{
text: '185-190cm',
value: 185,
},
{
text: '190cm 이상',
value: 190,
},
];



export const top_size = {
female: [44, 55, 66, 77, 88, 99],
male: [95, 100, 105, 110, 115, 120],
};

export const bottom_size = [
23,
24,
25,
26,
27,
28,
29,
30,
31,
32,
33,
34,
35,
36,
37,
38,
39,
40,
];

export const worker_type_list = [
{
text: 'MD',
worker_type: 'md',
},
{
text: '정산',
worker_type: 'adjustment',
},
{
text: 'CS',
worker_type: 'cs',
},
{
text: '일반',
worker_type: 'other',
},
];

export const worker_type_hash = {
md: 'MD',
adjustment: '정산',
cs: 'CS',
other: '일반',
};



export const payment_type_list = [
{
type: 'card',
name: '카드'
},
{
type: 'account',
name: '가상계좌'
},
{
type: 'accountLegacy',
name: '무통장이체'
}
];

export const payment_type_hash: any =
{
card: '카드',
vbank: '가상계좌',
account: '가상계좌(구)',
kakaopay: '카카오페이',
html5_inicis: '이니시스',
external: '외부채널',
accountLegacy: '무통장이체',
innopay: '이노페이'
};

export const payment_status_hash: any =
{
pending: '결제대기중',
confirmed: '결제완료',
partial_canceled: '부분취소',
canceled: '취소',
ready: '결제준비'
};

export const delivery_status_hash: any =
{
registered: '배송요청완료',
ready: '배송대기',
moving: '배송중',
completed: '배송완료'
};


export const review_point_reward: any = {
short_text: 50,
text: 150,
photo: 300
};

export const review_point_idx: any = {
short_text: 6,
text: 3,
short_photo: 7,
photo: 2
};


export const cardHash: any = {
"200001": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200002": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200003": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200004": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200005": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200006": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200007": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200008": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200009": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200010": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200011": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200012": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200013": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200014": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200015": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200016": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200017": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200018": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200019": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200020": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200021": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200022": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200023": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200024": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200025": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200026": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200027": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200028": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200029": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200030": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200031": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200032": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200033": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200034": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200035": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200036": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200037": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200038": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200039": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200040": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200041": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200042": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200043": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200044": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200045": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200046": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200047": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200048": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200049": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200050": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200051": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200052": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200053": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200054": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200055": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200056": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200057": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200058": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200059": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200060": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200061": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200062": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200063": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200064": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200065": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200066": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200067": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200068": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200069": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200070": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200071": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200072": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200073": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200074": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200075": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200076": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200077": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200078": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200079": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200080": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200081": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200082": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200083": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200084": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200085": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200086": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200087": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200088": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200089": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200090": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200091": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200092": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200093": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200094": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200095": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200096": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200097": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200098": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"200099": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205001": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205002": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205003": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205004": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205005": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205006": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205007": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205008": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205009": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205010": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205011": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205012": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205013": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205014": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205015": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205016": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205017": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205018": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205019": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205020": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205021": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205022": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205023": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205024": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205025": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205026": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205027": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205028": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205029": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205030": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205031": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205032": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205033": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205034": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205035": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205036": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205037": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205038": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205039": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205040": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205041": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205042": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205043": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205044": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205045": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205046": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205047": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205048": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205049": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205050": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205051": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205052": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205053": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205054": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205055": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205056": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205057": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205058": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205059": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205060": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205061": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205062": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205063": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205064": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205065": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205066": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205067": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205068": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205069": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205070": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205071": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205072": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205073": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205074": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205075": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205076": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205077": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205078": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205079": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205080": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205081": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205082": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205083": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205084": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205085": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205086": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205087": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205088": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205089": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205090": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205091": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205092": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205093": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205094": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205095": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205096": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205097": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205098": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"205099": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"209304": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"356003": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356006": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356021": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356022": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356023": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356024": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356025": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356027": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356031": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356032": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356033": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356036": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356039": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356078": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356087": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356234": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356283": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356290": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"356291": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356292": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356293": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356294": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356295": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356296": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"356297": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356298": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"356303": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356306": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356311": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356312": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356316": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356317": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356320": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356321": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356322": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356323": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356324": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356325": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356327": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356331": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356332": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356333": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356336": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356339": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356406": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356407": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356413": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356414": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356415": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356416": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356417": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356454": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356478": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356479": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356503": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356506": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356511": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356512": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356516": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356517": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356520": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356521": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356522": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356523": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356525": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356527": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356531": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356532": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356533": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356534": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356539": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356543": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"356544": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"356545": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"356716": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356717": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356800": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"356801": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"356802": {
"company": "하나카드",
"owner": "",
"type": ""
},
"356803": {
"company": "하나카드",
"owner": "",
"type": ""
},
"356820": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356823": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356824": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"356878": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356891": {
"company": "삭제",
"owner": "",
"type": ""
},
"356892": {
"company": "삭제",
"owner": "",
"type": ""
},
"356900": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356901": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356902": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"356903": {
"company": "삭제",
"owner": "",
"type": ""
},
"356904": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356905": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356906": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356907": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356908": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"356910": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"356911": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356912": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"356914": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"356915": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"356916": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"356920": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356932": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"356978": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"356979": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"357104": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"357116": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"357117": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"357118": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"357119": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"360043": {
"company": "현대카드",
"owner": "",
"type": ""
},
"360069": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"361246": {
"company": "현대카드",
"owner": "",
"type": ""
},
"361247": {
"company": "현대카드",
"owner": "",
"type": ""
},
"361248": {
"company": "현대카드",
"owner": "",
"type": ""
},
"361650": {
"company": "현대카드",
"owner": "",
"type": ""
},
"361651": {
"company": "현대카드",
"owner": "",
"type": ""
},
"361652": {
"company": "현대카드",
"owner": "",
"type": ""
},
"361653": {
"company": "현대카드",
"owner": "",
"type": ""
},
"361654": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364124": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364125": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364178": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364445": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364681": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364682": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364683": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364828": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364829": {
"company": "현대카드",
"owner": "",
"type": ""
},
"364830": {
"company": "현대카드",
"owner": "",
"type": ""
},
"365405": {
"company": "현대카드",
"owner": "",
"type": ""
},
"365431": {
"company": "현대카드",
"owner": "",
"type": ""
},
"365573": {
"company": "현대카드",
"owner": "",
"type": ""
},
"365574": {
"company": "현대카드",
"owner": "",
"type": ""
},
"365645": {
"company": "현대카드",
"owner": "",
"type": ""
},
"365646": {
"company": "현대카드",
"owner": "",
"type": ""
},
"371001": {
"company": "하나카드",
"owner": "",
"type": ""
},
"371002": {
"company": "하나카드",
"owner": "",
"type": ""
},
"371003": {
"company": "하나카드",
"owner": "",
"type": ""
},
"374722": {
"company": "하나카드",
"owner": "",
"type": ""
},
"374723": {
"company": "하나카드",
"owner": "",
"type": ""
},
"374724": {
"company": "하나카드",
"owner": "",
"type": ""
},
"374725": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"374735": {
"company": "삭제",
"owner": "",
"type": ""
},
"375144": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"376250": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376251": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376252": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376253": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376254": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376255": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376256": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376257": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376258": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376259": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376260": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376261": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376262": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376263": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376264": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376265": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376266": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376267": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376268": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376269": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376270": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376271": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376272": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376273": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376274": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376275": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376276": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376277": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376278": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376279": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376290": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376291": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"376292": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"376293": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"376364": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"376365": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"377177": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"377178": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"377345": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"377346": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"377966": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"377967": {
"company": "삭제",
"owner": "",
"type": ""
},
"377968": {
"company": "삭제",
"owner": "",
"type": ""
},
"377969": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"377973": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377981": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"377982": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"377983": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"377984": {
"company": "삭제",
"owner": "",
"type": ""
},
"377985": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"377986": {
"company": "삭제",
"owner": "",
"type": ""
},
"377987": {
"company": "삭제",
"owner": "",
"type": ""
},
"377988": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"377989": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"377990": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377991": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377992": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377993": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377994": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377995": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377996": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377997": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377998": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"377999": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"379183": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"379184": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"379192": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"379193": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"379194": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"379220": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"379221": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"379348": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"379349": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"379350": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"379351": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"379909": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"379910": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"379971": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"400124": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"400223": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"400278": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"400558": {
"company": "신세계자사카드",
"owner": "개인",
"type": "체크"
},
"400569": {
"company": "씨티카드",
"owner": "개인",
"type": "체크"
},
"400696": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"400904": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"400905": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"400906": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"400907": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"400908": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"400909": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"400910": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"400911": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"400912": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"400913": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"400933": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"400951": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"400978": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"400990": {
"company": "삭제",
"owner": "",
"type": ""
},
"401120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"401278": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"401320": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"401678": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"401762": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"401787": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"401844": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"401895": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"402017": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"402072": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"402175": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"402359": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"402367": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"402502": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"402515": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"402516": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"402517": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"402596": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"402822": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"402857": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"402858": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"402859": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"402860": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"403011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403293": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"403302": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"403346": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"403803": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403806": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403811": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403812": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403820": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403821": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403823": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403827": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403831": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403839": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"403878": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"404143": {
"company": "삭제",
"owner": "",
"type": ""
},
"404331": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404332": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404339": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404611": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"404612": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"404678": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404720": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404726": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"404727": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"404728": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"404803": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404806": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404811": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404812": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404820": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404821": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404823": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404824": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404825": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404827": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"404933": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"404947": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"405203": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"405207": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"405220": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"405331": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"405432": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"405665": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"405753": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"405754": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"406020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"406078": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"406107": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"406158": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"406357": {
"company": "신세계자사카드",
"owner": "개인",
"type": "신용"
},
"406703": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406706": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406711": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406720": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406723": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406725": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406727": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406731": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406732": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406733": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406739": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"406745": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"407420": {
"company": "삭제",
"owner": "",
"type": ""
},
"407424": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"407503": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"407541": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"407558": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"407589": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"407590": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"407603": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"407625": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"407725": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"407785": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"407916": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"407917": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"408320": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"408511": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"408512": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"408539": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"408907": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"408966": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"409085": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"409216": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"409217": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"409231": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"409232": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"409336": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"409460": {
"company": "전북은행",
"owner": "개인",
"type": "기프트"
},
"410109": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"410120": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"410278": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"411133": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"411178": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"411471": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"411478": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"411825": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"411896": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"411897": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"411904": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"411905": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"412020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"412994": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"413526": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"413700": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"413720": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"413780": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"413837": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"414003": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414006": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414011": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414012": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414020": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414021": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414022": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414023": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414024": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414025": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414027": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414031": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414032": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414039": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414296": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"414520": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"414625": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"414710": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"414743": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"414744": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"414749": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"415525": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"416206": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"416207": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"416237": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"416320": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"416324": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"416332": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"416339": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"417103": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"417233": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"417256": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"418143": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"418163": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"418164": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"418173": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"418236": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"419696": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"419697": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"419698": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"419699": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"419803": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"420178": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"420189": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"420190": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"420701": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"420707": {
"company": "하나카드",
"owner": "",
"type": ""
},
"420720": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"420920": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"421103": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421106": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421111": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421112": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421121": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421123": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421127": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421131": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421132": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421134": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421139": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421417": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"421418": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"421420": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"421468": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"421573": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"421607": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"421685": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"421733": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"421820": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"421834": {
"company": "광주은행",
"owner": "",
"type": ""
},
"422015": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"422016": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"422018": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"422155": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"422190": {
"company": "수협은행",
"owner": "개인",
"type": "기프트"
},
"422715": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"422717": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"422727": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"423950": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"425863": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"425920": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"426066": {
"company": "현대카드",
"owner": "개인",
"type": "기프트"
},
"426578": {
"company": "삼성카드",
"owner": "",
"type": ""
},
"426586": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"426589": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"426907": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"426920": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"427120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"427203": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"427206": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"427329": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"427352": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"427386": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"428411": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"428412": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"428431": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"428432": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"428439": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"428465": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"428696": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"428697": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"428908": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"428909": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"429212": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"429498": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"429499": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"429612": {
"company": "신한카드",
"owner": "개인",
"type": ""
},
"430305": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"430306": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"430603": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"430969": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"430972": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"430984": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"432445": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"432627": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"432632": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"432768": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"433010": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"433028": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"433087": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"433276": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"433283": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"433290": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"433692": {
"company": "하나카드",
"owner": "",
"type": ""
},
"433693": {
"company": "하나카드",
"owner": "",
"type": ""
},
"434346": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"434347": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"434976": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"435661": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"436417": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"436420": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"436422": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"436477": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"436511": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"436550": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"437368": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"437432": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"437436": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"437437": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"437438": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"437719": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"438265": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"438654": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"438674": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"438676": {
"company": "신한카드",
"owner": "",
"type": ""
},
"438922": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"439110": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"440025": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"440445": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"440446": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"440448": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"442660": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"443233": {
"company": "삭제",
"owner": "",
"type": ""
},
"443567": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"443569": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"443579": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"445056": {
"company": "광주은행",
"owner": "개인",
"type": "기프트"
},
"445090": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"445096": {
"company": "현대카드",
"owner": "법인",
"type": "기프트"
},
"447320": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"447703": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"447781": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"447820": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448103": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448106": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448108": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"448111": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448112": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448121": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448123": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448125": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448127": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448131": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448133": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448135": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"448139": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"448168": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"448492": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"448494": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"448523": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"448537": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"448555": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"449914": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"450025": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"450203": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"450520": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"450596": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"451099": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"451100": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"451101": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"451245": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"451281": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"451842": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"451843": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"451844": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"451845": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"451846": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"452013": {
"company": "씨티카드",
"owner": "개인",
"type": "체크"
},
"452373": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"452387": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"452388": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"452389": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"453123": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"453261": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"453934": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"453936": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"454303": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"454478": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"455303": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455306": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455311": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455312": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455320": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455321": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455322": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455323": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455324": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455325": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455327": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455331": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455332": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455336": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455339": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455396": {
"company": "삭제",
"owner": "",
"type": ""
},
"455397": {
"company": "삭제",
"owner": "",
"type": ""
},
"455420": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"455437": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"456037": {
"company": "광주은행",
"owner": "법인",
"type": "체크"
},
"456058": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"456702": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"456703": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"457047": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"457048": {
"company": "하나카드",
"owner": "개인",
"type": ""
},
"457318": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"457492": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"457493": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"457905": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"457906": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"457972": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"457973": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"457992": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"457993": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"458484": {
"company": "삭제",
"owner": "",
"type": ""
},
"458525": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"458532": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"458568": {
"company": "삭제",
"owner": "",
"type": ""
},
"458856": {
"company": "삭제",
"owner": "",
"type": ""
},
"458857": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"459222": {
"company": "씨티카드",
"owner": "법인",
"type": "신용"
},
"459810": {
"company": "삭제",
"owner": "",
"type": ""
},
"459855": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"459856": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"459857": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"459858": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"459859": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"459860": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"459900": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"459901": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"459902": {
"company": "광주은행",
"owner": "",
"type": ""
},
"459903": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"459904": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"459906": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"459907": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"459910": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"459911": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"459912": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"459913": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"459927": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"459930": {
"company": "하나카드",
"owner": "",
"type": ""
},
"459931": {
"company": "하나카드",
"owner": "",
"type": ""
},
"459936": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"459950": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"459951": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"459952": {
"company": "광주은행",
"owner": "",
"type": ""
},
"459953": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"459954": {
"company": "삭제",
"owner": "",
"type": ""
},
"460561": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"461723": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"461724": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"461771": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"461774": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"461775": {
"company": "전북은행",
"owner": "법인",
"type": "신용"
},
"461954": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"461980": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"462890": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"463235": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"463732": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"463915": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"463916": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"464022": {
"company": "삭제",
"owner": "",
"type": ""
},
"464092": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"464562": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"464637": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"464647": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"464942": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"464955": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"464959": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"464960": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"464965": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"465306": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"465583": {
"company": "하나카드",
"owner": "",
"type": ""
},
"465613": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"465887": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"465888": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"465889": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"466571": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"467006": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"467007": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"467008": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"467009": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"467069": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"467077": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"467119": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"467120": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"467121": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"467122": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"467123": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"467309": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"467963": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"468780": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"468781": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"468782": {
"company": "신세계자사카드",
"owner": "개인",
"type": "신용"
},
"468908": {
"company": "삼성카드",
"owner": "",
"type": ""
},
"468909": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"468911": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"468912": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"468913": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"468914": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"468952": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"469536": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"469537": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"469631": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"469632": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"469639": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"470399": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"470547": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"470587": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"470588": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"470616": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"470617": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"470618": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"470630": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"470631": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"472175": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"472200": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"472246": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"472263": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"472264": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"472429": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"472632": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"472637": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"472650": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"473058": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"473059": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"473844": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"473867": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"474130": {
"company": "삭제",
"owner": "",
"type": ""
},
"474289": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"474290": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"474360": {
"company": "신세계자사카드",
"owner": "개인",
"type": "신용"
},
"474361": {
"company": "신세계자사카드",
"owner": "개인",
"type": "신용"
},
"474420": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"474523": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"474524": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"474606": {
"company": "하나카드",
"owner": "법인",
"type": "체크"
},
"474607": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"474608": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"474817": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"475503": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475506": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475511": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475512": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475520": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475521": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475523": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475525": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475527": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475531": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475532": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"475539": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"476020": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"476021": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"476063": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"478892": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"479588": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"479589": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"480074": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"480402": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"480434": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"480443": {
"company": "하나카드",
"owner": "",
"type": ""
},
"480444": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"480451": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"483543": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"483544": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"483578": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"483592": {
"company": "하나카드",
"owner": "법인",
"type": "체크"
},
"483632": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"483639": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"483780": {
"company": "삭제",
"owner": "",
"type": ""
},
"483781": {
"company": "삭제",
"owner": "",
"type": ""
},
"483792": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"483793": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"484087": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"484809": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"485475": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"485476": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"485477": {
"company": "삭제",
"owner": "",
"type": ""
},
"485478": {
"company": "삭제",
"owner": "",
"type": ""
},
"485479": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"485480": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"485492": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"485493": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"485903": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"485906": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"485920": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"485927": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"486467": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"486468": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"486578": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"486579": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"486678": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"486679": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"488906": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"488919": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"488920": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"488921": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"488972": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"489010": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"489515": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"489904": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"490011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490220": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490274": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"490298": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"490603": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490606": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490611": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490612": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490620": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490621": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490622": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490623": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490624": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490625": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490627": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490631": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490632": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490636": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"490639": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"491217": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"491218": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"492511": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"492512": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"493455": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"496603": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"498411": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"498412": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"498737": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"498819": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"498820": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"502123": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"502928": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"510003": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"510554": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"510737": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"511187": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"511845": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"512028": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"512085": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"512114": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512351": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512352": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512353": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512354": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512355": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512359": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512365": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"512462": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"512609": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"512635": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"512813": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"512830": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"513041": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"513243": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"513791": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"513792": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"513893": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"513894": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"513895": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"514043": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"514473": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"514564": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"514645": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"514876": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"515193": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"515197": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"515408": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"515594": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"515606": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"515708": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"515954": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"516006": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"516078": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"516191": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"516341": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"516408": {
"company": "삭제",
"owner": "",
"type": ""
},
"516411": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"516450": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"516453": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"516526": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"516574": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"516682": {
"company": "삭제",
"owner": "",
"type": ""
},
"516695": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"517134": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"517220": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"517548": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"517560": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"517662": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"517709": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"517820": {
"company": "삭제",
"owner": "",
"type": ""
},
"517827": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"518135": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"518171": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"518185": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"518283": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"518319": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"518420": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"518432": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"518669": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"518831": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"518928": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"518929": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"518930": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"519157": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"519303": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"519335": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"519635": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"519657": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"519706": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"519718": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"519751": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"519892": {
"company": "씨티카드",
"owner": "개인",
"type": "체크"
},
"520025": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"520045": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"520172": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"520421": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"520422": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"520504": {
"company": "국민카드",
"owner": "법인",
"type": "체크"
},
"520613": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"520667": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"521126": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"521135": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"521176": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"521186": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"521189": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"521717": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"521718": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"521826": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"522051": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"522108": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"522109": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"522111": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522112": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522114": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522115": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522119": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522123": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522124": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522125": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522129": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"522447": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"522509": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"522802": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"522855": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"522894": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"522971": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"523401": {
"company": "씨티카드",
"owner": "법인",
"type": "신용"
},
"523402": {
"company": "씨티카드",
"owner": "법인",
"type": "신용"
},
"523410": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"523424": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"523521": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"523527": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"523612": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"523651": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"523830": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"523832": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"523930": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524012": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524013": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524014": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524015": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524019": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524021": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524022": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524023": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524024": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524025": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524028": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"524029": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524041": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524140": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"524141": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"524144": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"524154": {
"company": "삭제",
"owner": "",
"type": ""
},
"524180": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"524220": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"524223": {
"company": "신세계자사카드",
"owner": "개인",
"type": "신용"
},
"524225": {
"company": "삭제",
"owner": "",
"type": ""
},
"524226": {
"company": "삭제",
"owner": "",
"type": ""
},
"524242": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"524285": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"524286": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"524333": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"524335": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"524531": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"524603": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"524713": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"525316": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"525549": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"525783": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"525982": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"525989": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"526251": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"526373": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"526878": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"527182": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"527257": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"527289": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"527419": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"527810": {
"company": "씨티카드",
"owner": "개인",
"type": "체크"
},
"528054": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"528268": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"528274": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"528523": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"528638": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"528664": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"528665": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"528936": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"528937": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"528949": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"528951": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"529014": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"529269": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"529281": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"529803": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"529875": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"529877": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"529899": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"530369": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"530432": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"531070": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"531072": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"531080": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"531082": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"531085": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"531088": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"531194": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"531408": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"531838": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"532092": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"532105": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"532147": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"532188": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"532288": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"532444": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"532793": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"532799": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"532913": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"533014": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"533032": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"533337": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"533349": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"533427": {
"company": "하나카드",
"owner": "법인",
"type": "체크"
},
"533774": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"533827": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"533872": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"534132": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"534204": {
"company": "삭제",
"owner": "",
"type": ""
},
"534291": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"534292": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"534553": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"535020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"535052": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"535180": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"536125": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"536142": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"536148": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"536181": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"536320": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"536323": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"536326": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"536460": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"536510": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"536539": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"536617": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"536623": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"536624": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"536648": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"536683": {
"company": "전북은행",
"owner": "법인",
"type": "신용"
},
"536802": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"536883": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"536945": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"536946": {
"company": "수협은행",
"owner": "법인",
"type": "체크"
},
"536984": {
"company": "전북은행",
"owner": "법인",
"type": "신용"
},
"537042": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"537044": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537102": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"537120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537145": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"537614": {
"company": "하나카드",
"owner": "법인",
"type": "체크"
},
"537620": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537659": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537662": {
"company": "광주은행",
"owner": "법인",
"type": "체크"
},
"537692": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"537703": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537706": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537711": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537712": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537720": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537723": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537725": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537727": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537731": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537732": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537736": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537739": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"537943": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"537952": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"538003": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"538159": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"538165": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"538179": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"538720": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"538803": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"538806": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"538811": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"538812": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538820": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"538823": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538825": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538827": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538831": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538832": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538836": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538839": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"538903": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"538920": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"539030": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"539771": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"540388": {
"company": "신세계자사카드",
"owner": "개인",
"type": "체크"
},
"540447": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"540486": {
"company": "신한카드",
"owner": "개인",
"type": ""
},
"540497": {
"company": "하나카드",
"owner": "",
"type": ""
},
"540528": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"540537": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"540538": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"540554": {
"company": "하나카드",
"owner": "",
"type": ""
},
"540591": {
"company": "삭제",
"owner": "",
"type": ""
},
"540689": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"540799": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"540926": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"540929": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"540935": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"540936": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"540947": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"540988": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"541145": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"541259": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"541468": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"541633": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"541707": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"541721": {
"company": "광주은행",
"owner": "법인",
"type": "체크"
},
"541757": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"542158": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"542184": {
"company": "삼성카드",
"owner": "",
"type": ""
},
"542220": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"542265": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"542416": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"542448": {
"company": "삭제",
"owner": "",
"type": ""
},
"542564": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"542586": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"542720": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"542879": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"543017": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"543241": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"543333": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"543341": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"543617": {
"company": "하나카드",
"owner": "",
"type": ""
},
"544004": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"544082": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"544120": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"544167": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"544174": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"544203": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"545089": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"546111": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"546112": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"546252": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"546418": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"546465": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"546596": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"547109": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"547149": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"547227": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"547422": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"547609": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"547671": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"547924": {
"company": "전북은행",
"owner": "법인",
"type": "체크"
},
"548020": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"548213": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"548238": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"548596": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"548732": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"548781": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"548811": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"548816": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"548818": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"548841": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"548869": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"548870": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"549029": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"549153": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"549155": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"549594": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"549840": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"549861": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"550485": {
"company": "삭제",
"owner": "",
"type": ""
},
"550744": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"551431": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"552014": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"552051": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552052": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552087": {
"company": "신한카드",
"owner": "",
"type": ""
},
"552103": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552106": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552111": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552112": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552114": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"552120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552122": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552123": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552124": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552125": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552131": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552132": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552133": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552136": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"552139": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552164": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552194": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"552220": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552248": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"552290": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552323": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"552376": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552377": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552386": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"552387": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"552407": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"552412": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"552423": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"552432": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"552441": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552445": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"552521": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552527": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552532": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552533": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552537": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"552576": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"552803": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552806": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552811": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552812": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552820": {
"company": "삭제",
"owner": "",
"type": ""
},
"552821": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552823": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552831": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552839": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"552920": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553078": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553142": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"553144": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"553146": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"553147": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"553150": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"553151": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"553152": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"553155": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"553159": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"553168": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"553169": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"553171": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"553172": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"553173": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"553176": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"553177": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"553178": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553179": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"553180": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"553181": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"553184": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"553190": {
"company": "전북은행",
"owner": "법인",
"type": "신용"
},
"553208": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553211": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553212": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553213": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553214": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553215": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553216": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553217": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553219": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553220": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553221": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553223": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553224": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"553225": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553226": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"553406": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"553423": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"553488": {
"company": "삭제",
"owner": "",
"type": ""
},
"553531": {
"company": "전북은행",
"owner": "법인",
"type": "신용"
},
"553615": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"553727": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"553820": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"553831": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"554049": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"554345": {
"company": "국민카드",
"owner": "법인",
"type": "체크"
},
"554346": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"554349": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"554382": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"554481": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"554578": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"554620": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"554648": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"554959": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"554963": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"554973": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"555013": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"555023": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"555027": {
"company": "삭제",
"owner": "",
"type": ""
},
"555511": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"555870": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"555904": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"555916": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"556608": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"556659": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"556678": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"557042": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"557889": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"558370": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"558420": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558453": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"558503": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558506": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558511": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558512": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558520": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558521": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558523": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558524": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558525": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558526": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"558527": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558531": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558532": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558533": {
"company": "삭제",
"owner": "",
"type": ""
},
"558539": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558725": {
"company": "삭제",
"owner": "",
"type": ""
},
"558735": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"558738": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"558749": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"558768": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"558817": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"558822": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"558875": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"558881": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"558885": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"558903": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558906": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558911": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558912": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558914": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558915": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"558917": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558920": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558921": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558923": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558925": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558927": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558931": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558932": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558933": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558939": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"558950": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"558953": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"558961": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"558970": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"558971": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"558972": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"558975": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"558976": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"558978": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"558985": {
"company": "삭제",
"owner": "",
"type": ""
},
"558986": {
"company": "삭제",
"owner": "",
"type": ""
},
"559084": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"559123": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"559246": {
"company": "전북은행",
"owner": "법인",
"type": "신용"
},
"559293": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"559329": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"559410": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"559434": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"559438": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"559571": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"559588": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"559620": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"559631": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"559632": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"559639": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"559794": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"559869": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"559913": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"559924": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"559957": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"559962": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"589802": {
"company": "삭제",
"owner": "",
"type": ""
},
"604823": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"605615": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"606045": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"606052": {
"company": "농협카드",
"owner": "",
"type": ""
},
"620011": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620027": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620031": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620039": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620103": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620106": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620120": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620123": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620125": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620132": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620220": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620278": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"620812": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"621003": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621006": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"621011": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621012": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621020": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621023": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621025": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621027": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"621031": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621032": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621039": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621051": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621078": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621079": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621220": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"621249": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"621484": {
"company": "수협은행",
"owner": "법인",
"type": "체크"
},
"621639": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"621640": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"621641": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"623024": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"623322": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"623323": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"623329": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"623340": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"623341": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"623358": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"623374": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"623379": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"623387": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623390": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623391": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623392": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623393": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623394": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623395": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623451": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623452": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623453": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623454": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623470": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"623489": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"624313": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"624320": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624321": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"624324": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624325": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"624331": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"624332": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"624333": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"624335": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624336": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624339": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"624340": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"624343": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"624344": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624345": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624346": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"624348": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"624349": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624350": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624358": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"624359": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624360": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"624361": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"624362": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"624363": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"624368": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"624370": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"624373": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"624374": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"624375": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624376": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"624377": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"624378": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"624379": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624380": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624381": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624382": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624383": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624384": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624387": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624388": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"624389": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"624399": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"624400": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"624401": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"624403": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"624404": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624410": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"624411": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"624414": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"624420": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624424": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624432": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"624434": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"624435": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"624436": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"624437": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624440": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"624441": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"624442": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"624443": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"624444": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"624446": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624448": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624449": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624450": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624451": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624452": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"624462": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"624479": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"624480": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"624482": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625003": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625006": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625023": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625025": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625031": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625032": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625039": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625078": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625079": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625103": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625111": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625112": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625125": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625127": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"625131": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625132": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625139": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625178": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625179": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"625220": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625241": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"625242": {
"company": "전북은행",
"owner": "법인",
"type": "신용"
},
"625243": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"625244": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"625245": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"625246": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"625320": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"625804": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"625814": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"625817": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"625840": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"625841": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"625904": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"626222": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"626236": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"626237": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"626250": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"626251": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"626261": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"626275": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"626277": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"626344": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"626399": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"626400": {
"company": "하나카드",
"owner": "법인",
"type": "체크"
},
"626402": {
"company": "국민카드",
"owner": "법인",
"type": "체크"
},
"626409": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"626415": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"626416": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"626422": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"626426": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"628179": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"628180": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"629141": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"636078": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"636093": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"636094": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"636095": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"636189": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"639339": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"639578": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"639650": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"654103": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654106": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654111": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654112": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654115": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654116": {
"company": "제주은행",
"owner": "법인",
"type": "신용"
},
"654117": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"654120": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654123": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654125": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654131": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654132": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654133": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"654139": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654150": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654203": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654206": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654211": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654212": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654215": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654220": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654223": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654225": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654231": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654232": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654239": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"654250": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"655603": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655606": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655611": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655612": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655614": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655615": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655616": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"655617": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"655618": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"655620": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655623": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655625": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655631": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655632": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655633": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"655639": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655650": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655803": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655806": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655811": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655812": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655814": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"655815": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655816": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"655817": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"655818": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"655820": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655823": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655825": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655831": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655832": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655839": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655850": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"655916": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"655918": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"655920": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"657016": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"657018": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"657020": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657031": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657032": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657039": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657303": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657306": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657311": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657312": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657313": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"657314": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657315": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657316": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"657317": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"657318": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"657320": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657323": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657325": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657327": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657331": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657332": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657333": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"657339": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"657350": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"700013": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"801004": {
"company": "유카드코리아",
"owner": "개인",
"type": "신용"
},
"811001": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811002": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811003": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811004": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811005": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811006": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811010": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811011": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811012": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811013": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811014": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811015": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811016": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811017": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811018": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811019": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811020": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811021": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811022": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811023": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811024": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811025": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811026": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811027": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811028": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811029": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"811030": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811031": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811032": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811033": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811034": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811035": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811036": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811037": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811038": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811039": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"811040": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811041": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811042": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811043": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811044": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811045": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811046": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811047": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811048": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811049": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"811050": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811051": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811052": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811053": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811054": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811055": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811056": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811057": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811058": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811059": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"811060": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811061": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811062": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811063": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811064": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811065": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811066": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811067": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811068": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811069": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811070": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811071": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811072": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811073": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811074": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811075": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811076": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811077": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811078": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811079": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811080": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811081": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811082": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811083": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811084": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811085": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811086": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811087": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811088": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811089": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811090": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811091": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811092": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811093": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811094": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811095": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811096": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811097": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811098": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"811099": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"834000": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"834024": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"834026": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"898212": {
"company": "KT월드패스",
"owner": "",
"type": "신용"
},
"898213": {
"company": "KT월드패스",
"owner": "",
"type": "신용"
},
"900001": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"900050": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"900101": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"903501": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903502": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903503": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903504": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903505": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903516": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"903517": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"903518": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"903519": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"903520": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"903551": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903552": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903553": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903554": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"903555": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"910003": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910006": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910007": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"910011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910021": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910023": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910025": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910027": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"910031": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910032": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910034": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"910035": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"910036": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910037": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"910039": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910050": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"910150": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"920002": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920003": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920006": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920007": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"920009": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920011": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920012": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920013": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920014": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920018": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920020": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920021": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920023": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920025": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920027": {
"company": "씨티카드",
"owner": "개인",
"type": "체크"
},
"920030": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920031": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920032": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920034": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"920035": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"920036": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920037": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"920039": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920042": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920045": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920048": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920050": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920059": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920061": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920063": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920064": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920071": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920079": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920080": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920091": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920092": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920095": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920096": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920120": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920215": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"920232": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920239": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920315": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"920432": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"920439": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"930003": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"930007": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"930011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"930020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"930023": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"930031": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"930032": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"930034": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"930037": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"930039": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"930050": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930103": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930111": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930112": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930123": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930125": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930131": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930132": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930139": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"930144": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"935003": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"935007": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"935016": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"935017": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"935020": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"935023": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"935031": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"935032": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"935034": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"935037": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"935039": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"935071": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"940010": {
"company": "신세계자사카드",
"owner": "개인",
"type": "신용"
},
"940011": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"940012": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"940020": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"940030": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"940040": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"940060": {
"company": "씨티카드",
"owner": "개인",
"type": "신용"
},
"940327": {
"company": "신세계자사카드",
"owner": "개인",
"type": "체크"
},
"940388": {
"company": "씨티카드",
"owner": "개인",
"type": "체크"
},
"940701": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"940702": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"940703": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"940704": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"940706": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"940707": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"940708": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"940910": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940911": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940912": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940913": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940914": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940915": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940916": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940917": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940918": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940919": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940920": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940921": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940922": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940923": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940924": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940925": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940926": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940927": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940928": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940929": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940930": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940931": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940932": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940933": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940934": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940935": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940936": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940937": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940938": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940939": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940940": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940941": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940942": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"940943": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940945": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940947": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940948": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940949": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940951": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940952": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940953": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940954": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940955": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940956": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940957": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"940958": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940960": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940961": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940962": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940963": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940964": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940965": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940966": {
"company": "롯데카드",
"owner": "법인",
"type": "기프트"
},
"940967": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940968": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940969": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940970": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940971": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940972": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940973": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940974": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940975": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940976": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940977": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940978": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940979": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940980": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940981": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940982": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940983": {
"company": "롯데카드",
"owner": "개인",
"type": "기프트"
},
"940991": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"940999": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"941000": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"941002": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941003": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941004": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941005": {
"company": "하나카드",
"owner": "",
"type": ""
},
"941006": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941008": {
"company": "수협은행",
"owner": "법인",
"type": "체크"
},
"941009": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941010": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941013": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"941014": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941015": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941017": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941018": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941019": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"941020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941021": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941022": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941023": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941024": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941025": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941026": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"941027": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941028": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941029": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"941030": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941031": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941032": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941034": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941035": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941036": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941037": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"941038": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941039": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941040": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"941041": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"941042": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"941043": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"941044": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"941045": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"941046": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"941047": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"941048": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"941049": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"941050": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"941051": {
"company": "하나카드",
"owner": "",
"type": ""
},
"941052": {
"company": "하나카드",
"owner": "",
"type": ""
},
"941053": {
"company": "하나카드",
"owner": "",
"type": ""
},
"941056": {
"company": "하나카드",
"owner": "",
"type": ""
},
"941057": {
"company": "하나카드",
"owner": "",
"type": ""
},
"941058": {
"company": "하나카드",
"owner": "",
"type": ""
},
"941059": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941060": {
"company": "롯데카드",
"owner": "개인",
"type": "신용"
},
"941061": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941062": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941063": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941064": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"941065": {
"company": "롯데카드",
"owner": "법인",
"type": "신용"
},
"941066": {
"company": "신한카드",
"owner": "법인",
"type": "기프트"
},
"941067": {
"company": "신한카드",
"owner": "법인",
"type": "기프트"
},
"941068": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"941069": {
"company": "신한카드",
"owner": "법인",
"type": "기프트"
},
"941073": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941074": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941075": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"941076": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941077": {
"company": "롯데카드",
"owner": "개인",
"type": "체크"
},
"941078": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941080": {
"company": "신한카드",
"owner": "",
"type": ""
},
"941081": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"941082": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"941083": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941084": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941085": {
"company": "삼성카드",
"owner": "",
"type": ""
},
"941086": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941087": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941088": {
"company": "삼성카드",
"owner": "",
"type": ""
},
"941089": {
"company": "삼성카드",
"owner": "",
"type": ""
},
"941090": {
"company": "삼성카드",
"owner": "",
"type": ""
},
"941091": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941092": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941093": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"941094": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"941096": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941097": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941098": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941099": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941111": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"941112": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"941116": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"941117": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"941120": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941161": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"941163": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941177": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941178": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941179": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"941181": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"941194": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941195": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"941199": {
"company": "농협카드",
"owner": "개인",
"type": "신용"
},
"941281": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"941381": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"941481": {
"company": "삭제",
"owner": "",
"type": ""
},
"941520": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941581": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"941607": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"941618": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941620": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941623": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941631": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941632": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941634": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"941635": {
"company": "제주은행",
"owner": "개인",
"type": "체크"
},
"941636": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941637": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"941639": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941642": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941643": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941645": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941648": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941650": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941671": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941681": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"941691": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941696": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"941707": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"941711": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941712": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941722": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941723": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941724": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941725": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941731": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941732": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941734": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"941735": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"941736": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941737": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"941739": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941750": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"941781": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"941807": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"941881": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"941981": {
"company": "하나카드",
"owner": "법인",
"type": "체크"
},
"942003": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942006": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942018": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"942019": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"942020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942021": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942022": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942023": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942024": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942025": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942027": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942031": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942032": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942033": {
"company": "삭제",
"owner": "",
"type": ""
},
"942034": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"942035": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"942036": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942039": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942061": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"942062": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"942063": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"942064": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"942065": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"942069": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"942070": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"942072": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"942073": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"942074": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"942075": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"942076": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"942077": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"942078": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942083": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"942084": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"942085": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"942086": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"942087": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"942088": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"942089": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"942090": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"942091": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"942092": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"942093": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"942094": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"942095": {
"company": "삼성카드",
"owner": "개인",
"type": "기프트"
},
"942096": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"942099": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"942101": {
"company": "삼성카드",
"owner": "법인",
"type": "신용"
},
"942120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942122": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942132": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942150": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942203": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942231": {
"company": "삭제",
"owner": "",
"type": ""
},
"942232": {
"company": "삭제",
"owner": "",
"type": ""
},
"942239": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942520": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942522": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942524": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942620": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942720": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"942820": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943003": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943006": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943011": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943012": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943016": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"943017": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"943020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943021": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943022": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943023": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943024": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943025": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943027": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943031": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943032": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943034": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"943036": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943039": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943050": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943062": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"943078": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943081": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"943116": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"943117": {
"company": "농협카드",
"owner": "법인",
"type": "체크"
},
"943132": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"943133": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"943134": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"943135": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"943137": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"943150": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943181": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"943210": {
"company": "하나카드",
"owner": "",
"type": ""
},
"943211": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"943212": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"943213": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"943214": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"943215": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"943216": {
"company": "하나카드",
"owner": "개인",
"type": "기프트"
},
"943281": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"943381": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"943416": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"943417": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"943481": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"943520": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"943541": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"943542": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"943543": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"943544": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"943581": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"943645": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"943646": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"943647": {
"company": "국민카드",
"owner": "법인",
"type": "체크"
},
"943648": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"943649": {
"company": "국민카드",
"owner": "법인",
"type": "체크"
},
"943907": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"943920": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"943921": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"943924": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"943925": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"943978": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"944002": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944003": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944006": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944007": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"944009": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944011": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944012": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944018": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944020": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944021": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944022": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944023": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944025": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944027": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944030": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944031": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944032": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944039": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944043": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944045": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944048": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944061": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944063": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944064": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944071": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944078": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944079": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944080": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944081": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"944095": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944096": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944103": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944111": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944112": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944116": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"944117": {
"company": "농협카드",
"owner": "개인",
"type": "체크"
},
"944120": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944122": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944124": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944131": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944132": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944137": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"944139": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944181": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"944192": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944203": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944222": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944224": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944281": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"944303": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944306": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944307": {
"company": "수협은행",
"owner": "법인",
"type": "체크"
},
"944311": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944312": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944320": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944322": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944324": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944331": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"944334": {
"company": "광주은행",
"owner": "법인",
"type": "체크"
},
"944337": {
"company": "전북은행",
"owner": "법인",
"type": "체크"
},
"944350": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944403": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944420": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944431": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944443": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944520": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944522": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944524": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944541": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"944542": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"944543": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"944545": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"944546": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"944547": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"944548": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"944549": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"944550": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"944551": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"944552": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"944603": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944620": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944622": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944624": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944631": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944664": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944691": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"944696": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944703": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944707": {
"company": "수협은행",
"owner": "개인",
"type": "체크"
},
"944720": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944920": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"944934": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"944948": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944949": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"944950": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"945003": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945006": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945007": {
"company": "수협은행",
"owner": "법인",
"type": "신용"
},
"945011": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945012": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945020": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945021": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945023": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945025": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945027": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945031": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945032": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945035": {
"company": "제주은행",
"owner": "법인",
"type": "신용"
},
"945037": {
"company": "전북은행",
"owner": "개인",
"type": "기프트"
},
"945039": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945078": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945081": {
"company": "하나카드",
"owner": "법인",
"type": "신용"
},
"945103": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"945107": {
"company": "수협은행",
"owner": "개인",
"type": "신용"
},
"945111": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"945112": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"945120": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945131": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"945132": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"945150": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"945161": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"945162": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"945203": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"945211": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"945212": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"945220": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945224": {
"company": "삭제",
"owner": "",
"type": ""
},
"945320": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945324": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945420": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945511": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"945520": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945620": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945720": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945810": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"945811": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"945812": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"945813": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"945814": {
"company": "신한카드",
"owner": "법인",
"type": "신용"
},
"945815": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"945816": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"945817": {
"company": "신한카드",
"owner": "법인",
"type": "체크"
},
"945818": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"945819": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"945820": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"945821": {
"company": "신한카드",
"owner": "개인",
"type": "체크"
},
"945822": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"945823": {
"company": "신한카드",
"owner": "개인",
"type": "기프트"
},
"945824": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"945825": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"945903": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945920": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"945934": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"945937": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"946003": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946007": {
"company": "수협은행",
"owner": "개인",
"type": "기프트"
},
"946011": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946012": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946020": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946021": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946023": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946025": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946027": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946031": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946032": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946034": {
"company": "광주은행",
"owner": "개인",
"type": "기프트"
},
"946039": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946044": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946046": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946050": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946103": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946106": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946111": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946112": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946116": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"946117": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"946120": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946124": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946127": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946131": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946132": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946134": {
"company": "광주은행",
"owner": "개인",
"type": "기프트"
},
"946137": {
"company": "전북은행",
"owner": "개인",
"type": "기프트"
},
"946139": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946144": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946150": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"946178": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946181": {
"company": "하나카드",
"owner": "법인",
"type": "체크"
},
"946216": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"946217": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"946220": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946250": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946316": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"946317": {
"company": "농협카드",
"owner": "개인",
"type": "기프트"
},
"946320": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946350": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946403": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946406": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946411": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946412": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946420": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946423": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946427": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946431": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946432": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946439": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946450": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946478": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946503": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946511": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946512": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946513": {
"company": "비씨카드",
"owner": "법인",
"type": "기프트"
},
"946514": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946520": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946531": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946532": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946539": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946544": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946545": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946550": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946620": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946644": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946650": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946720": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946750": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946803": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946820": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946850": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"946950": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"947003": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947006": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947011": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947012": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947020": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947021": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947023": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947025": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947027": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947031": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947032": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947033": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947039": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947050": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"947078": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947081": {
"company": "하나카드",
"owner": "개인",
"type": "신용"
},
"947120": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"947124": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"947134": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"947137": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"947150": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"947203": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"947206": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"947211": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"947221": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"947225": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"947232": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"947239": {
"company": "비씨카드",
"owner": "법인",
"type": "체크"
},
"947271": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"947520": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"947920": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"947934": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"947950": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"948020": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"948031": {
"company": "하나카드",
"owner": "개인",
"type": "체크"
},
"948034": {
"company": "광주은행",
"owner": "개인",
"type": "신용"
},
"948120": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"948124": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"948134": {
"company": "광주은행",
"owner": "법인",
"type": "신용"
},
"948135": {
"company": "제주은행",
"owner": "개인",
"type": "신용"
},
"948150": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"948220": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"948222": {
"company": "비씨카드",
"owner": "법인",
"type": "신용"
},
"948521": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"948816": {
"company": "농협카드",
"owner": "법인",
"type": "신용"
},
"948817": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"948911": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"948934": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"949005": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949010": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949011": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949012": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949013": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"949014": {
"company": "현대카드",
"owner": "개인",
"type": "기프트"
},
"949015": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"949016": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949017": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949018": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949019": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949020": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949021": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949022": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949023": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949024": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949025": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949026": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949027": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949028": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"949029": {
"company": "현대카드",
"owner": "법인",
"type": "체크"
},
"949032": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949033": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949034": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949035": {
"company": "현대카드",
"owner": "법인",
"type": "기프트"
},
"949037": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949038": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949039": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"949040": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949041": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"949042": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949043": {
"company": "현대카드",
"owner": "개인",
"type": "체크"
},
"949044": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949045": {
"company": "현대카드",
"owner": "법인",
"type": "체크"
},
"949046": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949047": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949048": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"949049": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"949050": {
"company": "현대카드",
"owner": "개인",
"type": "기프트"
},
"949051": {
"company": "현대카드",
"owner": "법인",
"type": "기프트"
},
"949052": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949053": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949054": {
"company": "현대카드",
"owner": "법인",
"type": "체크"
},
"949055": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"949056": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949057": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949058": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949059": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949060": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949061": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949062": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949063": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949064": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949065": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949066": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949067": {
"company": "현대카드",
"owner": "법인",
"type": "체크"
},
"949069": {
"company": "현대카드",
"owner": "법인",
"type": "체크"
},
"949070": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949071": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"949072": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"949073": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"949074": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"949075": {
"company": "전북은행",
"owner": "개인",
"type": "신용"
},
"949077": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949078": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949079": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949080": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"949081": {
"company": "전북은행",
"owner": "개인",
"type": "체크"
},
"949082": {
"company": "전북은행",
"owner": "개인",
"type": "기프트"
},
"949084": {
"company": "현대카드",
"owner": "개인",
"type": "기프트"
},
"949085": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949086": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949087": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949088": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949089": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949090": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"949091": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"949092": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"949093": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949094": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949095": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949096": {
"company": "국민카드",
"owner": "법인",
"type": "신용"
},
"949097": {
"company": "현대카드",
"owner": "개인",
"type": "신용"
},
"949098": {
"company": "국민카드",
"owner": "개인",
"type": "신용"
},
"949099": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949100": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"949101": {
"company": "국민카드",
"owner": "법인",
"type": "체크"
},
"949102": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"949103": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949104": {
"company": "국민카드",
"owner": "개인",
"type": "기프트"
},
"949105": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949107": {
"company": "국민카드",
"owner": "개인",
"type": "체크"
},
"949111": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949211": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949311": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949411": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949511": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949529": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949530": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949531": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949532": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949533": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949611": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949711": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949811": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"949911": {
"company": "지드림카드",
"owner": "개인",
"type": "신용"
},
"950003": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"950011": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"950012": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"950020": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"951001": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"951002": {
"company": "현대카드",
"owner": "법인",
"type": "신용"
},
"953001": {
"company": "광주은행",
"owner": "",
"type": ""
},
"953002": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"953003": {
"company": "광주은행",
"owner": "개인",
"type": "체크"
},
"953004": {
"company": "광주은행",
"owner": "법인",
"type": "체크"
},
"953005": {
"company": "광주은행",
"owner": "",
"type": ""
},
"970003": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"970031": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"970032": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"970039": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"970045": {
"company": "비씨카드",
"owner": "개인",
"type": "체크"
},
"971000": {
"company": "삼성카드",
"owner": "개인",
"type": "체크"
},
"972000": {
"company": "삼성카드",
"owner": "개인",
"type": "신용"
},
"999950": {
"company": "비씨카드",
"owner": "개인",
"type": "신용"
},
"999960": {
"company": "비씨카드",
"owner": "개인",
"type": "기프트"
},
"999999": {
"company": "신한카드",
"owner": "개인",
"type": "신용"
},
"019519": {
"company": "여가문화상품권",
"owner": "",
"type": "신용"
}
}


export type Pg = 'html5_inicis' | 'kcp' | 'kcp_billing' | 'uplus' | 'jtnet' | 'nice' | 'kakaopay' | 'kakao' | 'danal' | 'danal_tpay' | 'kicc' | 'paypal' | 'mobilians' | 'payco' | 'settle' | 'naverco' | 'naverpay' | 'smilepay';
export type PayMethod = 'card' | 'trans' | 'vbank' | 'phone' | 'samsung' | 'kpay' | 'cultureland' | 'smartculture' | 'happymoney' | 'booknlife';
export type Carrier = 'SKT' | 'KTF' | 'LGT' | 'MVNO';


interface eachPg {
value: Pg,
label: string,
text: string,
}

export interface eachMethod {
value: PayMethod,
label: string,
}

export interface eachQuota {
value: string,
label: string,
}

interface eachCarrier {
value: Carrier,
label: string,
}

export const PGS: Array<eachPg> = [
{
value: 'html5_inicis',
label: '웹 표준 이니시스',
text: '웹 표준 이니시스',
},
{
value: 'kcp',
label: 'NHN KCP',
text: 'NHN KCP',
},
{
value: 'kcp_billing',
label: 'NHN KCP 정기결제',
text: 'NHN KCP 정기결제',
},
{
value: 'uplus',
label: 'LG 유플러스',
text: 'LG 유플러스',
},
{
value: 'jtnet',
label: 'JTNET',
text: 'JTNET',
},
{
value: 'nice',
label: '나이스 정보통신',
text: '나이스 정보통신',
},
{
value: 'kakaopay',
label: '신 - 카카오페이',
text: '신 - 카카오페이',
},
{
value: 'kakao',
label: '구 - LG CNS 카카오페이',
text: '구 - LG CNS 카카오페이',
},
{
value: 'danal',
label: '다날 휴대폰 소액결제',
text: '다날 휴대폰 소액결제',
},
{
value: 'danal_tpay',
label: '다날 일반결제',
text: '다날 일반결제',
},
{
value: 'kicc',
label: '한국정보통신',
text: '한국정보통신',
},
{
value: 'paypal',
label: '페이팔',
text: '페이팔',
},
{
value: 'mobilians',
label: '모빌리언스',
text: '모빌리언스',
},
{
value: 'payco',
label: '페이코',
text: '페이코',
},
{
value: 'settle',
label: '세틀뱅크 가상계좌',
text: '세틀뱅크 가상계좌',
},
{
value: 'naverco',
label: '네이버 체크아웃',
text: '네이버 체크아웃',
},
{
value: 'naverpay',
label: '네이버페이',
text: '네이버페이',
},
{
value: 'smilepay',
label: '스마일페이',
text: '스마일페이',
},
];

export const METHODS: Array<eachMethod> = [
{
value: 'card',
label: '신용카드',
},
{
value: 'vbank',
label: '가상계좌',
},
{
value: 'trans',
label: '실시간 계좌이체',
},
{
value: 'phone',
label: '휴대폰 소액결제'
},
];

export const METHODS_FOR_INICIS: Array<eachMethod> =
METHODS.concat([
{
value: 'samsung',
label: '삼성페이',
},
{
value: 'kpay',
label: 'KPAY',
},
{
value: 'cultureland',
label: '문화상품권',
},
{
value: 'smartculture',
label: '스마트문상',
},
{
value: 'happymoney',
label: '해피머니',
},
]);

export const METHODS_FOR_UPLUS: Array<eachMethod> =
METHODS.concat([
{
value: 'cultureland',
label: '문화상품권',
},
{
value: 'smartculture',
label: '스마트문상',
},
{
value: 'booknlife',
label: '도서상품권',
},
]);

export const METHODS_FOR_KCP: Array<eachMethod> =
METHODS.concat([
{
value: 'samsung',
label: '삼성페이',
},
]);

export const METHODS_FOR_MOBILIANS: Array<eachMethod> = [
{
value: 'card',
label: '신용카드',
},
{
value: 'phone',
label: '휴대폰 소액결제',
},
];

export const METHOD_FOR_CARD: Array<eachMethod> = [
{
value: 'card',
label: '신용카드',
},
];

export const METHOD_FOR_PHONE: Array<eachMethod> = [
{
value: 'phone',
label: '휴대폰 소액결제',
},
];

export const METHOD_FOR_VBANK: Array<eachMethod> = [
{
value: 'vbank',
label: '가상계좌',
},
];

export const QUOTAS: Array<eachQuota> = [
{
value: '0',
label: 'PG사 기본 제공',
},
{
value: '1',
label: '일시불',
},
];

export const CARRIERS: Array<eachCarrier> = [
{
value: 'SKT',
label: 'SKT',
},
{
value: 'KTF',
label: 'KT',
},
{
value: 'LGT',
label: 'LGU+',
},
{
value: 'MVNO',
label: '알뜰폰',
},
];



export const DEFAULT_IDX = {
//기본업체
EFLUVI_COMPANY_IDX:1,

//기본 배송업체
SUPIGEN_COMPANY_IDX:2

}
export const EXTRA_DELIVERY_FEE = {
"제주특별자치도 제주시 추자면": 7000,
"제주특별자치도": 3000,
"인천 중구 큰무리로": 6000,
"인천 중구 무의대로": 6000,
"인천 중구 대무의로": 6000,
"인천 중구 하나개로": 6000,
"인천 중구 떼무리길": 6000,
"인천 중구 소무의로": 6000,
"인천 중구 팔미": 6000,
"인천 강화군 삼산면": 4500,
"인천 강화군 서도면": 4500,
"인천 강화군 교동면": 4500,
"인천 옹진군 영흥면": 0,
"인천 옹진군": 6000,
"경남 통영시 사량면": 4000,
"경남 통영시 산양읍 곤리": 4000,
"경남 통영시 산양읍 연대": 4000,
"경남 통영시 산양읍 만지": 4000,
"경남 통영시 산양읍 미조": 4000,
"경남 통영시 욕지면": 4000,
"경남 통영시 용남면 어의길": 4000,
"경남 통영시 용남면 갈받이길": 4000,
"경남 통영시 한산면": 4000,
"경북 울릉군": 5000,
"충남 당진시 석문면 난지": 4000,
"충남 당진시 신평면 구래길": 6000,
"충청남도 보령시 오천면 고대도": 5000,
"충청남도 보령시 오천면 녹도": 5000,
"충청남도 보령시 오천면 삽시도": 5000,
"충청남도 보령시 오천면 소도길": 5000,
"충청남도 보령시 오천면 외연도": 5000,
"충청남도 보령시 오천면 원산도": 5000,
"충청남도 보령시 오천면 월도길": 5000,
"충청남도 보령시 오천면 육도길": 5000,
"충청남도 보령시 오천면 장고도": 5000,
"충청남도 보령시 오천면 추도길": 5000,
"충청남도 보령시 오천면 허육도길": 5000,
"충청남도 보령시 오천면 호도길": 5000,
"충청남도 보령시 오천면 효자도길": 5000,
"충청남도 당진시 석문면 난지도리": 5000,
"충청남도 보령시 오천면 장고도리": 5000,
"충남 서산시 지곡면 갈마실": 7000,
"충남 서산시 지곡면 분점도": 7000,
"충남 태안군 근흥면 가의도": 5000,
"전북 군산시 옥도면": 5000,
"전북 부안군 위도면": 5000,
"전남 진도군 조도면": 5000,
"전남 완도군 청산면": 5000,
"전남 완도군 소안면": 5000,
"전남 완도군 생일면": 5000,
"전남 완도군 보길면": 5000,
"전남 완도군 노화읍": 5000,
"전남 완도군 금일읍": 7000,
"전남 완도군 금당면": 7000,
"전남 완도군 군외면 당인": 5000,
"전남 완도군 군외면 고마도": 5000,
"전남 완도군 군외면 대창": 5000,
"전남 완도군 군외면 군외중리길": 5000,
"전남 영광군 낙월면": 4000,
"전남 여수시 화정면 백야등대길": 0,
"전남 여수시 경도해양단지": 8000,
"전남 여수시 남면": 8000,
"전남 여수시 삼산면": 8000,
"전남 여수시 화정면": 8000,
"부산 강서구 가덕해안로": 4000,
"부산 강서구 동선새바지": 4000,
"부산 강서구 성북": 4000,
"전남 보성군 벌교읍 대촌길": 4000,
"전남 목포시 달동": 6000,
"전남 목포시 우도길": 6000,
"전남 목포시 율도": 6000,
"전남 고흥군 봉래면": 5000,
"전남 고흥군 도양읍 득량": 5000,
"전남 고흥군 도양읍 거금": 5000,
"전남 고흥군 도양읍 시산": 5000,
"전남 고흥군 도화면 대섬": 5000,
"경남 사천시 마도": 3000,
"경남 사천시 신수": 3000,
"경남 거제시 둔덕면 화도": 4000,
}


//easy_graphql_comment_start
/*
content_order_idx
content_page
content_name
content_name_internal
display_order
is_open

*/
//easy_graphql_comment_end



export class ContentOrder {
content_order_idx?: number

content_page?: string

content_name?: string

content_name_internal?: string

display_order?: number

is_open?: boolean

}

export class SelectContentOrderListReturn {
count?: number;

rows?: ContentOrder[];
}


export class InputContentOrder{
content_order_idx?: number

content_page?: string

content_name?: string

content_name_internal?: string

display_order?: number

is_open?: boolean


}

//class_end
//easy_graphql_comment_start
/*
contest_idx
contest_title
content_description
contest_banner_img_url
contest_full_img_url
conest_video_url
contest_html
contest_is_open
contest_start_datetime
contest_end_datetime
manager_user_idx
create_user_idx
update_user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class Contest {
contest_idx?: number

contest_title?: string

content_description?: string

contest_banner_img_url?: string

contest_full_img_url?: string

conest_video_url?: string

contest_html?: string

contest_is_open?: boolean

contest_start_datetime?: Date

contest_end_datetime?: Date

manager_user_idx?: number

create_user_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectContestListReturn {
count?: number;

rows?: Contest[];
}


export class InputContest{
contest_idx?: number

contest_title?: string

content_description?: string

contest_banner_img_url?: string

contest_full_img_url?: string

conest_video_url?: string

contest_html?: string

contest_is_open?: boolean

contest_start_datetime?: Date

contest_end_datetime?: Date

manager_user_idx?: number

create_user_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end


































//easy_graphql_comment_start
/*
contest_join_idx
secret_key
user_idx
contest_idx
post_idx
regist_datetime
update_datetime
join_user_name
join_user_phone
agree_policy
agree_datetime
average_point_image
average_point_pose
average_point_story
average_total_point
comment
final_result
sms_send_datetime

*/
//easy_graphql_comment_end

export class ContestJoin {
contest_join_idx?: number

secret_key?: string

user_idx?: number

contest_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

join_user_name?: string

join_user_phone?: string

agree_policy?: boolean

agree_datetime?: Date

average_point_image?: number

average_point_pose?: number

average_point_story?: number

average_total_point?: number

comment?: string

final_result?: number

sms_send_datetime?: Date

//start
user?: User

post?: Post

contest?: Contest

//end
}

export class SelectContestJoinListReturn {
count?: number;

rows?: ContestJoin[];
}


export class InputContestJoin{
contest_join_idx?: number

secret_key?: string

user_idx?: number

contest_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

join_user_name?: string

join_user_phone?: string

agree_policy?: boolean

agree_datetime?: Date

average_point_image?: number

average_point_pose?: number

average_point_story?: number

average_total_point?: number

comment?: string

final_result?: number

sms_send_datetime?: Date


}

//class_end




































//easy_graphql_comment_start
/*
contest_join_idx
secret_key
user_idx
contest_idx
post_idx
regist_datetime
update_datetime
join_user_name
join_user_phone
agree_policy
agree_datetime
average_point_image
average_point_pose
average_point_story
average_total_point
comment
final_result
sms_send_datetime

*/
//easy_graphql_comment_end



export class ContestJoinDeleted {
contest_join_idx?: number

secret_key?: string

user_idx?: number

contest_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

join_user_name?: string

join_user_phone?: string

agree_policy?: boolean

agree_datetime?: Date

average_point_image?: number

average_point_pose?: number

average_point_story?: number

average_total_point?: number

comment?: string

final_result?: boolean

sms_send_datetime?: Date

}

export class SelectContestJoinDeletedListReturn {
count?: number;

rows?: ContestJoinDeleted[];
}


export class InputContestJoinDeleted{
contest_join_idx?: number

secret_key?: string

user_idx?: number

contest_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

join_user_name?: string

join_user_phone?: string

agree_policy?: boolean

agree_datetime?: Date

average_point_image?: number

average_point_pose?: number

average_point_story?: number

average_total_point?: number

comment?: string

final_result?: boolean

sms_send_datetime?: Date


}

//class_end





































//easy_graphql_comment_start
/*
contest_review_idx
contest_idx
review_user_idx
post_idx
point_image
point_pose
point_story
point_extra
point_total
comment
final_result
regist_datetime
update_datetime
sms_send_datetime
push_send_datetime

*/
//easy_graphql_comment_end


export class ContestReview {
contest_review_idx?: number

contest_idx?: number

review_user_idx?: number

post_idx?: number

point_image?: number

point_pose?: number

point_story?: number

point_extra?: number

point_total?: number

comment?: string

final_result?: boolean

regist_datetime?: Date

update_datetime?: Date

sms_send_datetime?: Date

push_send_datetime?: Date

//start
review_user?: User

post?: Post

contest?: Contest
//end
}

export class SelectContestReviewListReturn {
count?: number;

rows?: ContestReview[];
}


export class InputContestReview{
contest_review_idx?: number

contest_idx?: number

review_user_idx?: number

post_idx?: number

point_image?: number

point_pose?: number

point_story?: number

point_extra?: number

point_total?: number

comment?: string

final_result?: boolean

regist_datetime?: Date

update_datetime?: Date

sms_send_datetime?: Date

push_send_datetime?: Date


}

//class_end


































//easy_graphql_comment_start
/*
coupon_idx
user_idx
coupon_rule_idx
order_transaction_idx
code
status
is_read
use_datetime
expire_datetime
regist_datetime
update_datetime
onelink_idx

*/
//easy_graphql_comment_end


export class Coupon {
coupon_idx?: number

user_idx?: number

coupon_rule_idx?: number

order_transaction_idx?: number

code?: string

status?: string

is_read?: boolean

use_datetime?: Date

expire_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number

//start
coupon_rule?: CouponRule

valid?: boolean
//end
}

export class SelectCouponListReturn {
count?: number;

rows?: Coupon[];
}


export class InputCoupon{
coupon_idx?: number

user_idx?: number

coupon_rule_idx?: number

order_transaction_idx?: number

code?: string

status?: string

is_read?: boolean

use_datetime?: Date

expire_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number


}

//class_end

export class SelectCouponInfo {
count?: number;

is_read?: boolean;
}

export class SelectCouponReturn {
valid_coupons?: SelectCouponListReturn;

invalid_coupons?: SelectCouponListReturn;
}

export class IssueCouponResult {
message?: string;

count?: number;
}
//easy_graphql_comment_start
/*
coupon_rule_idx
coupon_rule_group_idx
title
description
type
limit_count
amount
min_order_amount
use_ref_table
use_ref_idx_string
valid_day
expire_datetime
is_open
code
update_user_idx
start_datetime
end_datetime
regist_datetime
update_datetime
coupon_rule_id

*/
//easy_graphql_comment_end



export class CouponRule {
coupon_rule_idx?: number

coupon_rule_group_idx?: number

title?: string

description?: string

type?: string

limit_count?: number

amount?: number

min_order_amount?: number

use_ref_table?: string

use_ref_idx_string?: string

valid_day?: number

expire_datetime?: Date

is_open?: boolean

code?: string

update_user_idx?: number

start_datetime?: Date

end_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

coupon_rule_id?: string

//start
issued?: boolean
//end
}

export class SelectCouponRuleListReturn {
count?: number;

rows?: CouponRule[];
}


export class InputCouponRule{
coupon_rule_idx?: number

coupon_rule_group_idx?: number

title?: string

description?: string

type?: string

limit_count?: number

amount?: number

min_order_amount?: number

use_ref_table?: string

use_ref_idx_string?: string

valid_day?: number

expire_datetime?: Date

is_open?: boolean

code?: string

update_user_idx?: number

start_datetime?: Date

end_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

coupon_rule_id?: string


}

//class_end
//easy_graphql_comment_start
/*
coupon_rule_group_idx
title
description
ref_table
ref_table_idx
update_user_idx
regist_datetime
update_datetime
coupon_rule_group_id

*/
//easy_graphql_comment_end



export class CouponRuleGroup {
coupon_rule_group_idx?: number

title?: string

description?: string

ref_table?: string

ref_table_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

coupon_rule_group_id?: string

//start
coupon_rule?: CouponRule[]
//end
}

export class SelectCouponRuleGroupListReturn {
count?: number;

rows?: CouponRuleGroup[];
}


export class InputCouponRuleGroup{
coupon_rule_group_idx?: number

title?: string

description?: string

ref_table?: string

ref_table_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

coupon_rule_group_id?: string


}

//class_end
//easy_graphql_comment_start
/*
delivery_fee_templete_idx
user_idx
templete_title
departure_address
departure_delivery_company_name
return_address
return_delivery_company_name
as_departure_address
as_departure_delivery_company_name
as_return_address
as_return_delivery_company_name
delivery_adjustment_type
as_return_type
as_send_type
is_enable_package_delivery
delivery_type
delivery_departure_type
delivery_departure_fee
delivery_return_fee
delivery_exchange_fee
delivery_item_type
delivery_item_unit
delivery_item_fee
regist_datetime
update_datetime
extra_delivery_fee_type
extra_delivery_fee
extra_delivery_jeju_fee

*/
//easy_graphql_comment_end



export class DeliveryFeeTemplete {
delivery_fee_templete_idx?: number

user_idx?: number

templete_title?: string

departure_address?: string

departure_delivery_company_name?: string

return_address?: string

return_delivery_company_name?: string

as_departure_address?: string

as_departure_delivery_company_name?: string

as_return_address?: string

as_return_delivery_company_name?: string

delivery_adjustment_type?: string

as_return_type?: string

as_send_type?: string

is_enable_package_delivery?: boolean

delivery_type?: string

delivery_departure_type?: string

delivery_departure_fee?: number

delivery_return_fee?: number

delivery_exchange_fee?: number

delivery_item_type?: string

delivery_item_unit?: number

delivery_item_fee?: number

regist_datetime?: Date

update_datetime?: Date

extra_delivery_fee_type?: string

extra_delivery_fee?: number

extra_delivery_jeju_fee?: number

}

export class SelectDeliveryFeeTempleteListReturn {
count?: number;

rows?: DeliveryFeeTemplete[];
}


export class InputDeliveryFeeTemplete{
delivery_fee_templete_idx?: number

user_idx?: number

templete_title?: string

departure_address?: string

departure_delivery_company_name?: string

return_address?: string

return_delivery_company_name?: string

as_departure_address?: string

as_departure_delivery_company_name?: string

as_return_address?: string

as_return_delivery_company_name?: string

delivery_adjustment_type?: string

as_return_type?: string

as_send_type?: string

is_enable_package_delivery?: boolean

delivery_type?: string

delivery_departure_type?: string

delivery_departure_fee?: number

delivery_return_fee?: number

delivery_exchange_fee?: number

delivery_item_type?: string

delivery_item_unit?: number

delivery_item_fee?: number

regist_datetime?: Date

update_datetime?: Date

extra_delivery_fee_type?: string

extra_delivery_fee?: number

extra_delivery_jeju_fee?: number


}

//class_end
//easy_graphql_comment_start
/*
delivery_request_idx
order_transaction_idx
item_option_barcode
delivery_company_idx
delivery_company_name
sales_channel_id
sales_channel_order_number
sales_channel_order_number2
address
invoice
address_detail
request
post_code
order_user_name
order_user_phone
receiver_phone
receiver_phone_backup
item_title
item_option_name
order_transaction_memo
delivery_status
regist_datetime
update_datetime
shop_name
order_turn
good_name
barcode
quantity
receiver_name
receiver_contact
receiver_mobile
zip_code
delivery_memo
request_datetime
order_num
code
message

*/
//easy_graphql_comment_end



export class DeliveryRequest {
delivery_request_idx?: number

order_transaction_idx?: number

item_option_barcode?: number

delivery_company_idx?: number

delivery_company_name?: string

sales_channel_id?: string

sales_channel_order_number?: string

sales_channel_order_number2?: string

address?: string

invoice?: string

address_detail?: string

request?: string

post_code?: string

order_user_name?: string

order_user_phone?: string

receiver_phone?: string

receiver_phone_backup?: string

item_title?: string

item_option_name?: string

order_transaction_memo?: string

delivery_status?: string

regist_datetime?: Date

update_datetime?: Date

shop_name?: string

order_turn?: string

good_name?: string

barcode?: string

quantity?: number

receiver_name?: string

receiver_contact?: string

receiver_mobile?: string

zip_code?: string

delivery_memo?: string

request_datetime?: Date

order_num?: number

code?: number

message?: string

}

export class SelectDeliveryRequestListReturn {
count?: number;

rows?: DeliveryRequest[];
}


export class InputDeliveryRequest{
delivery_request_idx?: number

order_transaction_idx?: number

item_option_barcode?: number

delivery_company_idx?: number

delivery_company_name?: string

sales_channel_id?: string

sales_channel_order_number?: string

sales_channel_order_number2?: string

address?: string

invoice?: string

address_detail?: string

request?: string

post_code?: string

order_user_name?: string

order_user_phone?: string

receiver_phone?: string

receiver_phone_backup?: string

item_title?: string

item_option_name?: string

order_transaction_memo?: string

delivery_status?: string

regist_datetime?: Date

update_datetime?: Date

shop_name?: string

order_turn?: string

good_name?: string

barcode?: string

quantity?: number

receiver_name?: string

receiver_contact?: string

receiver_mobile?: string

zip_code?: string

delivery_memo?: string

request_datetime?: Date

order_num?: number

code?: number

message?: string


}

//class_end





































//easy_graphql_comment_start
/*
ecash_idx
class_room_idx
point_rule_idx
order_transaction_idx
order_item_idx
title
ecash_status
holding_date
amount
create_user_idx
add_ref_table_idx
add_ref_table
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Ecash {
ecash_idx?: number

class_room_idx?: number

point_rule_idx?: number

order_transaction_idx?: number

order_item_idx?: number

title?: string

ecash_status?: string

holding_date?: Date

amount?: number

create_user_idx?: number

add_ref_table_idx?: number

add_ref_table?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectEcashListReturn {
count?: number;

rows?: Ecash[];
}


export class InputEcash{
ecash_idx?: number

class_room_idx?: number

point_rule_idx?: number

order_transaction_idx?: number

order_item_idx?: number

title?: string

ecash_status?: string

holding_date?: Date

amount?: number

create_user_idx?: number

add_ref_table_idx?: number

add_ref_table?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
error_log_idx
error_code
error_msg
error_log
send_result
title
message
data
error
req_body
req_query
req_params
referer
ua
url
ip
device
user_idx
device_id
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ErrorLog {
error_log_idx?: number

error_code?: string

error_msg?: string

error_log?: string

send_result?: boolean

title?: string

message?: string

data?: string

error?: string

req_body?: string

req_query?: string

req_params?: string

referer?: string

ua?: string

url?: string

ip?: string

device?: string

user_idx?: number

device_id?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectErrorLogListReturn {
count?: number;

rows?: ErrorLog[];
}


export class InputErrorLog{
error_log_idx?: number

error_code?: string

error_msg?: string

error_log?: string

send_result?: boolean

title?: string

message?: string

data?: string

error?: string

req_body?: string

req_query?: string

req_params?: string

referer?: string

ua?: string

url?: string

ip?: string

device?: string

user_idx?: number

device_id?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
event_idx
title
description
banner_img_url
full_img_url
video_url
is_open
display_order
html
status
start_datetime
end_datetime
type
badge_url
update_user_idx
update_datetime
regist_datetime
event_tag
event_join_templete

*/
//easy_graphql_comment_end




export class Event {
event_idx?: number

title?: string

description?: string

banner_img_url?: string

full_img_url?: string

video_url?: string

is_open?: boolean

display_order?: number

html?: string

status?: number

start_datetime?: Date

end_datetime?: Date

type?: string

badge_url?: string

update_user_idx?: number

update_datetime?: Date

regist_datetime?: Date

event_tag?: string

event_join_templete?: string

//start
coupons?: CouponRule[];
//end
}

export class SelectEventListReturn {
count?: number;

rows?: Event[];
}


export class InputEvent{
event_idx?: number

title?: string

description?: string

banner_img_url?: string

full_img_url?: string

video_url?: string

is_open?: boolean

display_order?: number

html?: string

status?: number

start_datetime?: Date

end_datetime?: Date

type?: string

badge_url?: string

update_user_idx?: number

update_datetime?: Date

regist_datetime?: Date

event_tag?: string

event_join_templete?: string


}

//class_end

//easy_graphql_comment_start
/*
event_join_idx
secret_key
user_idx
event_idx
post_idx
user_name
user_phone
agree_policy
agree_datetime
final_result

*/
//easy_graphql_comment_end



export class EventJoin {
event_join_idx?: number

secret_key?: string

user_idx?: number

event_idx?: number

post_idx?: number

user_name?: string

user_phone?: string

agree_policy?: string

agree_datetime?: Date

final_result?: string

}

export class SelectEventJoinListReturn {
count?: number;

rows?: EventJoin[];
}


export class InputEventJoin{
event_join_idx?: number

secret_key?: string

user_idx?: number

event_idx?: number

post_idx?: number

user_name?: string

user_phone?: string

agree_policy?: string

agree_datetime?: Date

final_result?: string


}

//class_end
//easy_graphql_comment_start
/*
faq_idx
category
question
answer
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



























export class Faq {
faq_idx?: number

category?: '구매'|'판매'|'환불정책'|'결제'|'기타'

question?: string

answer?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectFaqListReturn {
count?: number;

rows?: Faq[];
}


export class InputFaq{
faq_idx?: number

category?: '구매'|'판매'|'환불정책'|'결제'|'기타'

question?: string

answer?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end





































//easy_graphql_comment_start
/*
idx
post_idx
is_open
type
ref_table
ref_table_idx
button_text
regist_datetime
update_datetime
featured_img_url

*/
//easy_graphql_comment_end



export class FeaturedContent {
idx?: number

post_idx?: number

is_open?: boolean

type?: string

ref_table?: string

ref_table_idx?: number

button_text?: string

regist_datetime?: Date

update_datetime?: Date

featured_img_url?: string

//start
post?: Post
//end
}

export class SelectFeaturedContentListReturn {
count?: number;

rows?: FeaturedContent[];
}


export class InputFeaturedContent{
idx?: number

post_idx?: number

is_open?: boolean

type?: string

ref_table?: string

ref_table_idx?: number

button_text?: string

regist_datetime?: Date

update_datetime?: Date

featured_img_url?: string


}

//class_end
//easy_graphql_comment_start
/*
from_user_idx
to_user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class Follow {
from_user_idx?: number

to_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
user?: User

from_user?: User

to_user?: User
//end
}

export class SelectFollowListReturn {
count?: number;

rows?: Follow[];
}


export class InputFollow{
from_user_idx?: number

to_user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end





































export class FollowListReturnSchema {

count?: number;

rows?: Follow[];

user?: User;

error?: string;

}


//easy_graphql_comment_start
/*
fumi_tracker_idx
user_idx
user_device_idx
event_name
event_value
target_date
target_date_string
regist_datetime
update_datetime
url

*/
//easy_graphql_comment_end



export class FumiTracker {
fumi_tracker_idx?: number

user_idx?: number

user_device_idx?: number

event_name?: string

event_value?: string

target_date?: Date

target_date_string?: string

regist_datetime?: Date

update_datetime?: Date

url?: string

}

export class SelectFumiTrackerListReturn {
count?: number;

rows?: FumiTracker[];
}


export class InputFumiTracker{
fumi_tracker_idx?: number

user_idx?: number

user_device_idx?: number

event_name?: string

event_value?: string

target_date?: Date

target_date_string?: string

regist_datetime?: Date

update_datetime?: Date

url?: string


}

//class_end
//easy_graphql_comment_start
/*
fumi_tracker_param_idx
fumi_tracker_idx
key_name
key_value

*/
//easy_graphql_comment_end



export class FumiTrackerParam {
fumi_tracker_param_idx?: number

fumi_tracker_idx?: number

key_name?: string

key_value?: string

}

export class SelectFumiTrackerParamListReturn {
count?: number;

rows?: FumiTrackerParam[];
}


export class InputFumiTrackerParam{
fumi_tracker_param_idx?: number

fumi_tracker_idx?: number

key_name?: string

key_value?: string


}

//class_end
//easy_graphql_comment_start
/*
invoice_idx
order_num
order_turn
invoice_num
shop_name
order_seq
invoice_delivery_company_name
processed
error
regist_datetime
update_datetime
order_class_name
good_name
good_option
quantity
insert_date
delivery_date
cancel_date
search_date
receiver_name
receiver_phone
receiver_mobile

*/
//easy_graphql_comment_end

export class Invoice {
invoice_idx?: number

order_num?: string

order_turn?: string

invoice_num?: string

shop_name?: string

order_seq?: string

invoice_delivery_company_name?: string

processed?: number

error?: string

regist_datetime?: Date

update_datetime?: Date

order_class_name?: string

good_name?: string

good_option?: string

quantity?: string

insert_date?: string

delivery_date?: string

cancel_date?: string

search_date?: string

receiver_name?: string

receiver_phone?: string

receiver_mobile?: string

}

export class SelectInvoiceListReturn {
count?: number;

rows?: Invoice[];
}


export class InputInvoice{
invoice_idx?: number

order_num?: string

order_turn?: string

invoice_num?: string

shop_name?: string

order_seq?: string

invoice_delivery_company_name?: string

processed?: number

error?: string

regist_datetime?: Date

update_datetime?: Date

order_class_name?: string

good_name?: string

good_option?: string

quantity?: string

insert_date?: string

delivery_date?: string

cancel_date?: string

search_date?: string

receiver_name?: string

receiver_phone?: string

receiver_mobile?: string


}

//class_end





































export class InputInvoiceList {

count?: number;

rows?: InputInvoice[];

}


//easy_graphql_comment_start
/*
item_idx
company_idx
create_user_idx
update_user_idx
manager_user_idx
delivery_company_idx
category
category_idx
brand_idx
item_status
is_open
is_recommend
title
title_internal
description
size
origin
item_sell_price
item_buy_price
item_origin_price
item_profit_share_price
platform_fee
item_delivery_fee
item_company_delivery_fee
main_item_resource_idx
main_video_resource_idx
main_image_resource_idx
total_stock
store_idx
crawling_url
memo
regist_datetime
update_datetime
item_image_url
item_impression_count
item_view_count
item_total_sell_count
item_click_ratio
item_order_ratio
item_total_sell_price
review_count
average_review_rate
skip_sync_firebase
enable_basket_coupon

*/
//easy_graphql_comment_end




export class Item {
item_idx?: number

company_idx?: number

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

delivery_company_idx?: number

category?: string

category_idx?: number

brand_idx?: number

item_status?: number

is_open?: boolean

is_recommend?: boolean

title?: string

title_internal?: string

description?: string

size?: string

origin?: string

item_sell_price?: number

item_buy_price?: number

item_origin_price?: number

item_profit_share_price?: number

platform_fee?: number

item_delivery_fee?: number

item_company_delivery_fee?: number

main_item_resource_idx?: number

main_video_resource_idx?: number

main_image_resource_idx?: number

total_stock?: number

store_idx?: number

crawling_url?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

item_image_url?: string

item_impression_count?: number

item_view_count?: number

item_total_sell_count?: number

item_click_ratio?: number

item_order_ratio?: number

item_total_sell_price?: number

review_count?: number

average_review_rate?: number

skip_sync_firebase?: number

enable_basket_coupon?: number

//start
manager_user?: User

create_user?: User

update_user?: User


main_item_resource?: ItemResource

main_image_resource?: ItemResource

main_video_resource?: ItemResource

delivery_company?: Company

brand?: Brand

store?: Store

company?: Company

item_sales_channel_list?: ItemSalesChannel[];

item_option_list?: ItemOption[];

item_company_list?: ItemCompany[];

item_resource_list?: ItemResourceLink[];

item_price_history_list?: ItemPriceHistory[];

item_post_list?: PostItem[];

item_section_list?: ShopSectionItem[];

review_list?: Review[];

item_category?: Category

item_delivery?: ItemDelivery

shop_wish?: ShopWish

thumbnail_images?: ItemResource[];

item_option_barcode_list?: string;

average_rate?: string;


max_coupon_amount?: number;

//end
}

export class SelectItemListReturn {
count?: number;

rows?: Item[];
}


export class InputItem{
item_idx?: number

company_idx?: number

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

delivery_company_idx?: number

category?: string

category_idx?: number

brand_idx?: number

item_status?: number

is_open?: boolean

is_recommend?: boolean

title?: string

title_internal?: string

description?: string

size?: string

origin?: string

item_sell_price?: number

item_buy_price?: number

item_origin_price?: number

item_profit_share_price?: number

platform_fee?: number

item_delivery_fee?: number

item_company_delivery_fee?: number

main_item_resource_idx?: number

main_video_resource_idx?: number

main_image_resource_idx?: number

total_stock?: number

store_idx?: number

crawling_url?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

item_image_url?: string

item_impression_count?: number

item_view_count?: number

item_total_sell_count?: number

item_click_ratio?: number

item_order_ratio?: number

item_total_sell_price?: number

review_count?: number

average_review_rate?: number

skip_sync_firebase?: number

enable_basket_coupon?: number


}

//class_end


export class InputMergeItem {
from_item_idx: number

to_item_idx: number
}

export class InputExcelItem {
item?: InputItem

thumbnail_url?: string

item_option_list?: InputItemOption[]
}

export class InputExcelItemList {
item_list?: InputExcelItem[]
}

export class DeleteItemResult {
success?: boolean;

message?: string;
}


export class InputBasketCouponCompany {
enable?: boolean;

company_idx_list: number[]
}



//easy_graphql_comment_start
/*
item_company_idx
item_idx
company_idx
item_company_type
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class ItemCompany {
item_company_idx?: number

item_idx?: number

company_idx?: number

item_company_type?: string

regist_datetime?: Date

update_datetime?: Date

//start
company?: Company

item?: Item
//end
}

export class SelectItemCompanyListReturn {
count?: number;

rows?: ItemCompany[];
}


export class InputItemCompany{
item_company_idx?: number

item_idx?: number

company_idx?: number

item_company_type?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end


































//easy_graphql_comment_start
/*
item_delivery_idx
item_idx
delivery_fee_templete_idx
departure_address
departure_delivery_company_name
departure_delivery_company_credit_code
return_address
return_delivery_company_name
return_delivery_company_credit_code
as_departure_address
as_departure_delivery_company_name
as_departure_delivery_company_credit_code
as_return_address
as_return_delivery_company_name
as_return_delivery_company_credit_code
is_credit_delivery
delivery_adjustment_type
delivery_type_one
delivery_type_two
storage_method
as_return_type
as_send_type
is_enable_store_return
is_enable_package_delivery
delivery_fee_type
delivery_departure_type
free_delivery_minimum_price
delivery_departure_fee
delivery_return_fee
delivery_exchange_fee
delivery_item_type
delivery_item_unit
delivery_item_fee
extra_delivery_fee_type
extra_delivery_fee
extra_delivery_jeju_fee
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ItemDelivery {
item_delivery_idx?: number

item_idx?: number

delivery_fee_templete_idx?: number

departure_address?: string

departure_delivery_company_name?: string

departure_delivery_company_credit_code?: string

return_address?: string

return_delivery_company_name?: string

return_delivery_company_credit_code?: string

as_departure_address?: string

as_departure_delivery_company_name?: string

as_departure_delivery_company_credit_code?: string

as_return_address?: string

as_return_delivery_company_name?: string

as_return_delivery_company_credit_code?: string

is_credit_delivery?: boolean

delivery_adjustment_type?: string

delivery_type_one?: string

delivery_type_two?: string

storage_method?: string

as_return_type?: string

as_send_type?: string

is_enable_store_return?: boolean

is_enable_package_delivery?: boolean

delivery_fee_type?: string

delivery_departure_type?: string

free_delivery_minimum_price?: number

delivery_departure_fee?: number

delivery_return_fee?: number

delivery_exchange_fee?: number

delivery_item_type?: string

delivery_item_unit?: number

delivery_item_fee?: number

extra_delivery_fee_type?: string

extra_delivery_fee?: number

extra_delivery_jeju_fee?: number

regist_datetime?: Date

update_datetime?: Date

//start
item?: Item
//end
}

export class SelectItemDeliveryListReturn {
count?: number;

rows?: ItemDelivery[];
}


export class InputItemDelivery{
item_delivery_idx?: number

item_idx?: number

delivery_fee_templete_idx?: number

departure_address?: string

departure_delivery_company_name?: string

departure_delivery_company_credit_code?: string

return_address?: string

return_delivery_company_name?: string

return_delivery_company_credit_code?: string

as_departure_address?: string

as_departure_delivery_company_name?: string

as_departure_delivery_company_credit_code?: string

as_return_address?: string

as_return_delivery_company_name?: string

as_return_delivery_company_credit_code?: string

is_credit_delivery?: boolean

delivery_adjustment_type?: string

delivery_type_one?: string

delivery_type_two?: string

storage_method?: string

as_return_type?: string

as_send_type?: string

is_enable_store_return?: boolean

is_enable_package_delivery?: boolean

delivery_fee_type?: string

delivery_departure_type?: string

free_delivery_minimum_price?: number

delivery_departure_fee?: number

delivery_return_fee?: number

delivery_exchange_fee?: number

delivery_item_type?: string

delivery_item_unit?: number

delivery_item_fee?: number

extra_delivery_fee_type?: string

extra_delivery_fee?: number

extra_delivery_jeju_fee?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_image_download_idx
origin_img_url
down_img_url
status

*/
//easy_graphql_comment_end



export class ItemImageDownload {
item_image_download_idx?: number

origin_img_url?: string

down_img_url?: string

status?: boolean

}

export class SelectItemImageDownloadListReturn {
count?: number;

rows?: ItemImageDownload[];
}


export class InputItemImageDownload{
item_image_download_idx?: number

origin_img_url?: string

down_img_url?: string

status?: boolean


}

//class_end
//easy_graphql_comment_start
/*
item_idx
migration_count
regist_datetime
update_datetime
error_count
error_url

*/
//easy_graphql_comment_end



export class ItemImageMigration {
item_idx?: number

migration_count?: number

regist_datetime?: Date

update_datetime?: Date

error_count?: number

error_url?: string

}

export class SelectItemImageMigrationListReturn {
count?: number;

rows?: ItemImageMigration[];
}


export class InputItemImageMigration{
item_idx?: number

migration_count?: number

regist_datetime?: Date

update_datetime?: Date

error_count?: number

error_url?: string


}

//class_end
//easy_graphql_comment_start
/*
item_error_idx
item_idx
url
regist_datetime
update_datetime
status

*/
//easy_graphql_comment_end



export class ItemImageMigrationError {
item_error_idx?: number

item_idx?: number

url?: string

regist_datetime?: Date

update_datetime?: Date

status?: number

}

export class SelectItemImageMigrationErrorListReturn {
count?: number;

rows?: ItemImageMigrationError[];
}


export class InputItemImageMigrationError{
item_error_idx?: number

item_idx?: number

url?: string

regist_datetime?: Date

update_datetime?: Date

status?: number


}

//class_end
//easy_graphql_comment_start
/*
item_option_idx
item_option_barcode
item_idx
item_option_name
need_text_option
text_option_title
item_option_code
item_resource_idx
item_option_stock
item_option_plus_sell_price
item_option_plus_buy_price
regist_datetime
update_datetime
item_option_barcode_backup
item_option_code_backup
temp_refer_order_phone
checker
create_user_idx
update_user_idx
manager_user_idx
is_open

*/
//easy_graphql_comment_end


export class ItemOption {
item_option_idx?: number

item_option_barcode?: string

item_idx?: number

item_option_name?: string

need_text_option?: boolean

text_option_title?: string

item_option_code?: string

item_resource_idx?: number

item_option_stock?: number

item_option_plus_sell_price?: number

item_option_plus_buy_price?: number

regist_datetime?: Date

update_datetime?: Date

item_option_barcode_backup?: string

item_option_code_backup?: string

temp_refer_order_phone?: string

checker?: string

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

is_open?: boolean

//start
create_user?: User

update_user?: User

item_option_resource?: ItemResource

item_option_sales_channel_list?: ItemOptionSalesChannel[];

item?: Item
//end
}

export class SelectItemOptionListReturn {
count?: number;

rows?: ItemOption[];
}


export class InputItemOption{
item_option_idx?: number

item_option_barcode?: string

item_idx?: number

item_option_name?: string

need_text_option?: boolean

text_option_title?: string

item_option_code?: string

item_resource_idx?: number

item_option_stock?: number

item_option_plus_sell_price?: number

item_option_plus_buy_price?: number

regist_datetime?: Date

update_datetime?: Date

item_option_barcode_backup?: string

item_option_code_backup?: string

temp_refer_order_phone?: string

checker?: string

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

is_open?: boolean


}

//class_end


export class InputMergeItemOption {
from_item_option_idx?: number

to_item_option_idx?: number
}


export class DeleteItemOptionResult {
success?: boolean;

message?: string;
}

//easy_graphql_comment_start
/*
item_option_barcode

*/
//easy_graphql_comment_end

export class ItemOptionBarcode {
item_option_barcode?: number

}

export class SelectItemOptionBarcodeListReturn {
count?: number;

rows?: ItemOptionBarcode[];
}


export class InputItemOptionBarcode{
item_option_barcode?: number


}

//class_end


































//easy_graphql_comment_start
/*
item_option_idx
item_option_barcode
item_idx
item_option_name
need_text_option
text_option_title
item_option_code
item_resource_idx
item_option_stock
item_plus_price
regist_datetime
update_datetime
item_option_barcode_backup
item_option_code_backup
temp_refer_order_phone
checker

*/
//easy_graphql_comment_end



export class ItemOptionCopy {
item_option_idx?: number

item_option_barcode?: string

item_idx?: number

item_option_name?: string

need_text_option?: boolean

text_option_title?: string

item_option_code?: string

item_resource_idx?: number

item_option_stock?: number

item_plus_price?: number

regist_datetime?: Date

update_datetime?: Date

item_option_barcode_backup?: string

item_option_code_backup?: string

temp_refer_order_phone?: string

checker?: string

}

export class SelectItemOptionCopyListReturn {
count?: number;

rows?: ItemOptionCopy[];
}


export class InputItemOptionCopy{
item_option_idx?: number

item_option_barcode?: string

item_idx?: number

item_option_name?: string

need_text_option?: boolean

text_option_title?: string

item_option_code?: string

item_resource_idx?: number

item_option_stock?: number

item_plus_price?: number

regist_datetime?: Date

update_datetime?: Date

item_option_barcode_backup?: string

item_option_code_backup?: string

temp_refer_order_phone?: string

checker?: string


}

//class_end





































//easy_graphql_comment_start
/*
item_option_sales_channel_idx
sales_channel_idx
item_option_idx
item_idx
item_option_sales_channel_name
item_option_sales_channel_status
item_option_sales_channel_plus_price
manager_user_idx
create_user_idx
update_user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class ItemOptionSalesChannel {
item_option_sales_channel_idx?: number

sales_channel_idx?: number

item_option_idx?: number

item_idx?: number

item_option_sales_channel_name?: string

item_option_sales_channel_status?: number

item_option_sales_channel_plus_price?: number

manager_user_idx?: number

create_user_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
sales_channel?: SalesChannel

item_option?: Item
//end
}

export class SelectItemOptionSalesChannelListReturn {
count?: number;

rows?: ItemOptionSalesChannel[];
}


export class InputItemOptionSalesChannel{
item_option_sales_channel_idx?: number

sales_channel_idx?: number

item_option_idx?: number

item_idx?: number

item_option_sales_channel_name?: string

item_option_sales_channel_status?: number

item_option_sales_channel_plus_price?: number

manager_user_idx?: number

create_user_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end


































//easy_graphql_comment_start
/*
item_page_idx
item_idx
item_resource_idx
item_page_order
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ItemPage {
item_page_idx?: number

item_idx?: number

item_resource_idx?: number

item_page_order?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectItemPageListReturn {
count?: number;

rows?: ItemPage[];
}


export class InputItemPage{
item_page_idx?: number

item_idx?: number

item_resource_idx?: number

item_page_order?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_price_history_idx
item_idx
item_price
memo
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class ItemPriceHistory {
item_price_history_idx?: number

item_idx?: number

item_price?: number

memo?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectItemPriceHistoryListReturn {
count?: number;

rows?: ItemPriceHistory[];
}


export class InputItemPriceHistory{
item_price_history_idx?: number

item_idx?: number

item_price?: number

memo?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_qna_idx
parent_item_qna_idx
item_idx
user_idx
title
description
is_open
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ItemQna {
item_qna_idx?: number

parent_item_qna_idx?: number

item_idx?: number

user_idx?: number

title?: string

description?: string

is_open?: boolean

regist_datetime?: Date

update_datetime?: Date

}

export class SelectItemQnaListReturn {
count?: number;

rows?: ItemQna[];
}


export class InputItemQna{
item_qna_idx?: number

parent_item_qna_idx?: number

item_idx?: number

user_idx?: number

title?: string

description?: string

is_open?: boolean

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_report_idx
user_idx
item_idx
regist_datetime
update_datetime
report_status

*/
//easy_graphql_comment_end



export class ItemReport {
item_report_idx?: number

user_idx?: number

item_idx?: number

regist_datetime?: Date

update_datetime?: Date

report_status?: number

}

export class SelectItemReportListReturn {
count?: number;

rows?: ItemReport[];
}


export class InputItemReport{
item_report_idx?: number

user_idx?: number

item_idx?: number

regist_datetime?: Date

update_datetime?: Date

report_status?: number


}

//class_end
//easy_graphql_comment_start
/*
item_resource_idx
item_idx
display_order
item_resource_type
item_resource_url
item_resource_content
filename
width
height
color
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class ItemResource {
item_resource_idx?: number

item_idx?: number

display_order?: number

item_resource_type?: string

item_resource_url?: string

item_resource_content?: string

filename?: string

width?: number

height?: number

color?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectItemResourceListReturn {
count?: number;

rows?: ItemResource[];
}


export class InputItemResource{
item_resource_idx?: number

item_idx?: number

display_order?: number

item_resource_type?: string

item_resource_url?: string

item_resource_content?: string

filename?: string

width?: number

height?: number

color?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_resource_link_idx
item_idx
item_resource_idx
item_resource_link_order
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class ItemResourceLink {
item_resource_link_idx?: number

item_idx?: number

item_resource_idx?: number

item_resource_link_order?: number

regist_datetime?: Date

update_datetime?: Date

//start
item_resource?: ItemResource
//end
}

export class SelectItemResourceLinkListReturn {
count?: number;

rows?: ItemResourceLink[];
}


export class InputItemResourceLink{
item_resource_link_idx?: number

item_idx?: number

item_resource_idx?: number

item_resource_link_order?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_sales_channel_idx
sales_channel_idx
item_idx
item_sales_channel_plus_price
item_sales_channel_fee
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class ItemSalesChannel {
item_sales_channel_idx?: number

sales_channel_idx?: number

item_idx?: number

item_sales_channel_plus_price?: number

item_sales_channel_fee?: number

regist_datetime?: Date

update_datetime?: Date

//start
sales_channel?: SalesChannel

item?: Item
//end
}

export class SelectItemSalesChannelListReturn {
count?: number;

rows?: ItemSalesChannel[];
}


export class InputItemSalesChannel{
item_sales_channel_idx?: number

sales_channel_idx?: number

item_idx?: number

item_sales_channel_plus_price?: number

item_sales_channel_fee?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_sales_link_idx
sales_channel_idx
item_sales_link_url
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ItemSalesLink {
item_sales_link_idx?: number

sales_channel_idx?: number

item_sales_link_url?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectItemSalesLinkListReturn {
count?: number;

rows?: ItemSalesLink[];
}


export class InputItemSalesLink{
item_sales_link_idx?: number

sales_channel_idx?: number

item_sales_link_url?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
kakao_share_callback_idx
chat_type
hash_chat_id
template_id
user_idx
onelink_idx
body
memo
regist_datetime
update_datetime
ref_idx
ref_table
device_idx

*/
//easy_graphql_comment_end



export class KakaoShareCallback {
kakao_share_callback_idx?: number

chat_type?: string

hash_chat_id?: string

template_id?: string

user_idx?: number

onelink_idx?: number

body?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

ref_idx?: number

ref_table?: string

device_idx?: number

}

export class SelectKakaoShareCallbackListReturn {
count?: number;

rows?: KakaoShareCallback[];
}


export class InputKakaoShareCallback{
kakao_share_callback_idx?: number

chat_type?: string

hash_chat_id?: string

template_id?: string

user_idx?: number

onelink_idx?: number

body?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

ref_idx?: number

ref_table?: string

device_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
user_idx
post_idx
regist_datetime
update_datetime
ip

*/
//easy_graphql_comment_end


export class Like {
user_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string

//start
user?: User


post?: Post
//end
}

export class SelectLikeListReturn {
count?: number;

rows?: Like[];
}


export class InputLike{
user_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
user_idx
post_idx
regist_datetime
update_datetime
ip

*/
//easy_graphql_comment_end



export class LikeDeleted {
user_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string

}

export class SelectLikeDeletedListReturn {
count?: number;

rows?: LikeDeleted[];
}


export class InputLikeDeleted{
user_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
user_idx
post_idx
regist_datetime
update_datetime
ip

*/
//easy_graphql_comment_end



export class LikeLog {
user_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string

}

export class SelectLikeLogListReturn {
count?: number;

rows?: LikeLog[];
}


export class InputLikeLog{
user_idx?: number

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
log_auth_idx
title
message
data
req_body
req_query
req_params
referer
url
ip
device
uid
did
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class LogAuth {
log_auth_idx?: number

title?: string

message?: string

data?: string

req_body?: string

req_query?: string

req_params?: string

referer?: string

url?: string

ip?: string

device?: string

uid?: number

did?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectLogAuthListReturn {
count?: number;

rows?: LogAuth[];
}


export class InputLogAuth{
log_auth_idx?: number

title?: string

message?: string

data?: string

req_body?: string

req_query?: string

req_params?: string

referer?: string

url?: string

ip?: string

device?: string

uid?: number

did?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
error_idx
title
message
data
error
req_body
req_query
req_params
referer
url
ip
device
uid
did
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class LogError {
error_idx?: number

title?: string

message?: string

data?: string

error?: string

req_body?: string

req_query?: string

req_params?: string

referer?: string

url?: string

ip?: string

device?: string

uid?: number

did?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectLogErrorListReturn {
count?: number;

rows?: LogError[];
}


export class InputLogError{
error_idx?: number

title?: string

message?: string

data?: string

error?: string

req_body?: string

req_query?: string

req_params?: string

referer?: string

url?: string

ip?: string

device?: string

uid?: number

did?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
log_http_idx
method
req_body
req_query
req_params
res_data
status_code
delay
referer
url
ip
device
uid
did
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class LogHttp {
log_http_idx?: number

method?: string

req_body?: string

req_query?: string

req_params?: string

res_data?: string

status_code?: number

delay?: number

referer?: string

url?: string

ip?: string

device?: string

uid?: number

did?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectLogHttpListReturn {
count?: number;

rows?: LogHttp[];
}


export class InputLogHttp{
log_http_idx?: number

method?: string

req_body?: string

req_query?: string

req_params?: string

res_data?: string

status_code?: number

delay?: number

referer?: string

url?: string

ip?: string

device?: string

uid?: number

did?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
login_history_idx
user_type
user_idx
regist_datetime
update_datetime
ip
device_info

*/
//easy_graphql_comment_end



export class LoginHistory {
login_history_idx?: number

user_type?: 'user'|'admin'|'agency'

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string

device_info?: string

}

export class SelectLoginHistoryListReturn {
count?: number;

rows?: LoginHistory[];
}


export class InputLoginHistory{
login_history_idx?: number

user_type?: 'user'|'admin'|'agency'

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

ip?: string

device_info?: string


}

//class_end
//easy_graphql_comment_start
/*
mail_idx
to
from
title
content
status
send_datetime
error
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Mail {
mail_idx?: number

to?: string

from?: string

title?: string

content?: string

status?: string

send_datetime?: Date

error?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMailListReturn {
count?: number;

rows?: Mail[];
}


export class InputMail{
mail_idx?: number

to?: string

from?: string

title?: string

content?: string

status?: string

send_datetime?: Date

error?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
mail_template_idx
template_name
title
content
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class MailTemplate {
mail_template_idx?: number

template_name?: string

title?: string

content?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMailTemplateListReturn {
count?: number;

rows?: MailTemplate[];
}


export class InputMailTemplate{
mail_template_idx?: number

template_name?: string

title?: string

content?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
message_automation_idx
message_automation_id
message_param_query
message_templete_id
message_templete_idx
user_segment_rule_idx
is_on
automatin_end_datetime
repeat_type
push_send_type
push_time
regist_datetime
update_datetime
check_hour

*/
//easy_graphql_comment_end



export class MessageAutomation {
message_automation_idx?: number

message_automation_id?: string

message_param_query?: string

message_templete_id?: string

message_templete_idx?: number

user_segment_rule_idx?: number

is_on?: number

automatin_end_datetime?: Date

repeat_type?: string

push_send_type?: string

push_time?: number

regist_datetime?: Date

update_datetime?: Date

check_hour?: number

//start
message_templete?: MessageTemplete


//end
}

export class SelectMessageAutomationListReturn {
count?: number;

rows?: MessageAutomation[];
}


export class InputMessageAutomation{
message_automation_idx?: number

message_automation_id?: string

message_param_query?: string

message_templete_id?: string

message_templete_idx?: number

user_segment_rule_idx?: number

is_on?: number

automatin_end_datetime?: Date

repeat_type?: string

push_send_type?: string

push_time?: number

regist_datetime?: Date

update_datetime?: Date

check_hour?: number


}

//class_end
//easy_graphql_comment_start
/*
message_automation_check_history
message_automation_idx
check_date
check_count
send_count
status
memo
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class MessageAutomationCheckHistory {
message_automation_check_history?: number

message_automation_idx?: number

check_date?: string

check_count?: number

send_count?: number

status?: number

memo?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMessageAutomationCheckHistoryListReturn {
count?: number;

rows?: MessageAutomationCheckHistory[];
}


export class InputMessageAutomationCheckHistory{
message_automation_check_history?: number

message_automation_idx?: number

check_date?: string

check_count?: number

send_count?: number

status?: number

memo?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
message_send_key_idx
message_send_key
user_idx
message_type
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class MessageSendKey {
message_send_key_idx?: number

message_send_key?: string

user_idx?: number

message_type?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMessageSendKeyListReturn {
count?: number;

rows?: MessageSendKey[];
}


export class InputMessageSendKey{
message_send_key_idx?: number

message_send_key?: string

user_idx?: number

message_type?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
message_templete_idx
message_templete_id
title
message
alarm_type
alarm_url
memo
regist_datetime
update_datetime
is_on
push_alarm_type
push_type
onelink_idx
force_send

*/
//easy_graphql_comment_end



export class MessageTemplete {
message_templete_idx?: number

message_templete_id?: string

title?: string

message?: string

alarm_type?: string

alarm_url?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

is_on?: boolean

push_alarm_type?: number

push_type?: number

onelink_idx?: number

force_send?: number

}

export class SelectMessageTempleteListReturn {
count?: number;

rows?: MessageTemplete[];
}


export class InputMessageTemplete{
message_templete_idx?: number

message_templete_id?: string

title?: string

message?: string

alarm_type?: string

alarm_url?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

is_on?: boolean

push_alarm_type?: number

push_type?: number

onelink_idx?: number

force_send?: number


}

//class_end
//easy_graphql_comment_start
/*
migration_history_idx
from_idx
to_idx
ref_key
ref_table
data
regist_datetime
update_datetime
manager_user_idx

*/
//easy_graphql_comment_end



export class MigrationHistory {
migration_history_idx?: number

from_idx?: number

to_idx?: number

ref_key?: string

ref_table?: string

data?: string

regist_datetime?: Date

update_datetime?: Date

manager_user_idx?: number

}

export class SelectMigrationHistoryListReturn {
count?: number;

rows?: MigrationHistory[];
}


export class InputMigrationHistory{
migration_history_idx?: number

from_idx?: number

to_idx?: number

ref_key?: string

ref_table?: string

data?: string

regist_datetime?: Date

update_datetime?: Date

manager_user_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
mobile_catalog_idx
title
mobile_catalog_item_count
description
preview_img_url
status
view_count
regist_datetime
update_datetime

*/
//easy_graphql_comment_end





















export class MobileCatalog {
mobile_catalog_idx?: number

title?: string

mobile_catalog_item_count?: number

description?: string

preview_img_url?: string

status?: string

view_count?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMobileCatalogListReturn {
count?: number;

rows?: MobileCatalog[];
}


export class InputMobileCatalog{
mobile_catalog_idx?: number

title?: string

mobile_catalog_item_count?: number

description?: string

preview_img_url?: string

status?: string

view_count?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
























//easy_graphql_comment_start
/*
mobile_catalog_item_idx
mobile_catalog_idx
position
item_resource_idx
item_type
item_img_url
item_link_url
item_video_url
video_width
video_height
title
description
origin_price
price
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class MobileCatalogItem {
mobile_catalog_item_idx?: number

mobile_catalog_idx?: number

position?: number

item_resource_idx?: number

item_type?: string

item_img_url?: string

item_link_url?: string

item_video_url?: string

video_width?: number

video_height?: number

title?: string

description?: string

origin_price?: number

price?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMobileCatalogItemListReturn {
count?: number;

rows?: MobileCatalogItem[];
}


export class InputMobileCatalogItem{
mobile_catalog_item_idx?: number

mobile_catalog_idx?: number

position?: number

item_resource_idx?: number

item_type?: string

item_img_url?: string

item_link_url?: string

item_video_url?: string

video_width?: number

video_height?: number

title?: string

description?: string

origin_price?: number

price?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
mobile_catalog_idx
model_user_idx
title
description
total_like_count
total_reply_count
resource_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class MobileCatalogModel {
mobile_catalog_idx?: number

model_user_idx?: number

title?: number

description?: string

total_like_count?: number

total_reply_count?: number

resource_idx?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMobileCatalogModelListReturn {
count?: number;

rows?: MobileCatalogModel[];
}


export class InputMobileCatalogModel{
mobile_catalog_idx?: number

model_user_idx?: number

title?: number

description?: string

total_like_count?: number

total_reply_count?: number

resource_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
mobile_catalog_model_item_idx
mobile_catalog_model_idx
resource_idx
like_count
reply_count
item_idx
title
description
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class MobileCatalogModelItem {
mobile_catalog_model_item_idx?: number

mobile_catalog_model_idx?: number

resource_idx?: number

like_count?: number

reply_count?: number

item_idx?: number

title?: string

description?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectMobileCatalogModelItemListReturn {
count?: number;

rows?: MobileCatalogModelItem[];
}


export class InputMobileCatalogModelItem{
mobile_catalog_model_item_idx?: number

mobile_catalog_model_idx?: number

resource_idx?: number

like_count?: number

reply_count?: number

item_idx?: number

title?: string

description?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
model_user_idx
user_idx
model_name
profile_resource_idx
etc
regist_datetime
update_datetime

*/
//easy_graphql_comment_end




















export class ModelUser {
model_user_idx?: number

user_idx?: number

model_name?: string

profile_resource_idx?: number

etc?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectModelUserListReturn {
count?: number;

rows?: ModelUser[];
}


export class InputModelUser{
model_user_idx?: number

user_idx?: number

model_name?: string

profile_resource_idx?: number

etc?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end























//easy_graphql_comment_start
/*
new_user_promotion_idx
user_idx
strategy_id
is_success
process_datetime
reserve_process_datetime
status
error
regist_datetime
update_datetime
memo
is_skip

*/
//easy_graphql_comment_end



export class NewUserPromotion {
new_user_promotion_idx?: number

user_idx?: number

strategy_id?: string

is_success?: boolean

process_datetime?: Date

reserve_process_datetime?: Date

status?: number

error?: string

regist_datetime?: Date

update_datetime?: Date

memo?: string

is_skip?: boolean

//start
user?: User
//end
}

export class SelectNewUserPromotionListReturn {
count?: number;

rows?: NewUserPromotion[];
}


export class InputNewUserPromotion{
new_user_promotion_idx?: number

user_idx?: number

strategy_id?: string

is_success?: boolean

process_datetime?: Date

reserve_process_datetime?: Date

status?: number

error?: string

regist_datetime?: Date

update_datetime?: Date

memo?: string

is_skip?: boolean


}

//class_end
//easy_graphql_comment_start
/*
new_user_promotion_strategy_idx
strategy_id
goal
description
condition

*/
//easy_graphql_comment_end



export class NewUserPromotionStrategy {
new_user_promotion_strategy_idx?: number

strategy_id?: string

goal?: string

description?: string

condition?: string

}

export class SelectNewUserPromotionStrategyListReturn {
count?: number;

rows?: NewUserPromotionStrategy[];
}


export class InputNewUserPromotionStrategy{
new_user_promotion_strategy_idx?: number

strategy_id?: string

goal?: string

description?: string

condition?: string


}

//class_end
//easy_graphql_comment_start
/*
notice_idx
title
notice_onoff
main_img_url
content
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Notice {
notice_idx?: number

title?: string

notice_onoff?: boolean

main_img_url?: string

content?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectNoticeListReturn {
count?: number;

rows?: Notice[];
}


export class InputNotice{
notice_idx?: number

title?: string

notice_onoff?: boolean

main_img_url?: string

content?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end






















//easy_graphql_comment_start
/*
notice_dialog_idx
title
img_url
link_url
button_text
button_color
link_url_two
button_text_two
button_color_two
type
is_open
display_order
start_datetime
end_datetime
regist_datetime
update_datetime
is_login
notice_dialog_impression_count
notice_dialog_view_count

*/
//easy_graphql_comment_end



export class NoticeDialog {
notice_dialog_idx?: number

title?: string

img_url?: string

link_url?: string

button_text?: string

button_color?: string

link_url_two?: string

button_text_two?: string

button_color_two?: string

type?: string

is_open?: boolean

display_order?: number

start_datetime?: Date

end_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

is_login?: boolean

notice_dialog_impression_count?: number

notice_dialog_view_count?: number

}

export class SelectNoticeDialogListReturn {
count?: number;

rows?: NoticeDialog[];
}


export class InputNoticeDialog{
notice_dialog_idx?: number

title?: string

img_url?: string

link_url?: string

button_text?: string

button_color?: string

link_url_two?: string

button_text_two?: string

button_color_two?: string

type?: string

is_open?: boolean

display_order?: number

start_datetime?: Date

end_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

is_login?: boolean

notice_dialog_impression_count?: number

notice_dialog_view_count?: number


}

//class_end
//easy_graphql_comment_start
/*
notice_dialog_history_idx
notice_dialog_idx
user_idx
regist_datetime

*/
//easy_graphql_comment_end



export class NoticeDialogViewHistory {
notice_dialog_history_idx?: number

notice_dialog_idx?: number

user_idx?: number

regist_datetime?: Date

}

export class SelectNoticeDialogViewHistoryListReturn {
count?: number;

rows?: NoticeDialogViewHistory[];
}


export class InputNoticeDialogViewHistory{
notice_dialog_history_idx?: number

notice_dialog_idx?: number

user_idx?: number

regist_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
onelink_idx
onelink_group_idx
onelink_template_id
landing_type
app_open_pid
app_open_campaign
app_open_adset
deep_link_value
is_retargeting
onelink_url
short_link
push_web_url
push_app_url
memo
regist_datetime
update_datetime
create_user_idx
app_install_count
app_open_count
order_item_count
order_item_amount
order_conversion_rate
order_roas
is_company_link
onelink_tag
marketing_fee
marketing_page_link
send_count
open_ratio
order_ratio
add_title
image_url

*/
//easy_graphql_comment_end




export class Onelink {
onelink_idx?: number

onelink_group_idx?: number

onelink_template_id?: string

landing_type?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

deep_link_value?: string

is_retargeting?: boolean

onelink_url?: string

short_link?: string

push_web_url?: string

push_app_url?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

app_install_count?: number

app_open_count?: number

order_item_count?: number

order_item_amount?: number

order_conversion_rate?: number

order_roas?: number

is_company_link?: boolean

onelink_tag?: string

marketing_fee?: number

marketing_page_link?: string

send_count?: number

open_ratio?: number

order_ratio?: number

add_title?: string

image_url?: string

//start
user?: User
//end
}

export class SelectOnelinkListReturn {
count?: number;

rows?: Onelink[];
}


export class InputOnelink{
onelink_idx?: number

onelink_group_idx?: number

onelink_template_id?: string

landing_type?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

deep_link_value?: string

is_retargeting?: boolean

onelink_url?: string

short_link?: string

push_web_url?: string

push_app_url?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

app_install_count?: number

app_open_count?: number

order_item_count?: number

order_item_amount?: number

order_conversion_rate?: number

order_roas?: number

is_company_link?: boolean

onelink_tag?: string

marketing_fee?: number

marketing_page_link?: string

send_count?: number

open_ratio?: number

order_ratio?: number

add_title?: string

image_url?: string


}

//class_end


export class OrderLitemOnelink {

target_date?: string;

onelink_idx?: number;

total_count?: number;

total_sell_price?: number;

total_buy_price?: number;

}
//easy_graphql_comment_start
/*
onelink_analytics_idx
target_date
target_key
onelink_idx
user_count
total_sell_count
total_sell_price
total_buy_price
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class OnelinkAnalytics {
onelink_analytics_idx?: number

target_date?: string

target_key?: string

onelink_idx?: number

user_count?: number

total_sell_count?: number

total_sell_price?: number

total_buy_price?: number

regist_datetime?: Date

update_datetime?: Date

//start
onelink?: Onelink
//end
}

export class SelectOnelinkAnalyticsListReturn {
count?: number;

rows?: OnelinkAnalytics[];
}


export class InputOnelinkAnalytics{
onelink_analytics_idx?: number

target_date?: string

target_key?: string

onelink_idx?: number

user_count?: number

total_sell_count?: number

total_sell_price?: number

total_buy_price?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
onelink_group_idx
title
description
create_user_idx
update_user_idx
marketing_fee
app_install_count
order_item_count
app_open_count
send_count
regist_datetime
update_datetime
tag
app_open_pid
app_open_campaign
app_open_adset
marketing_page_link

*/
//easy_graphql_comment_end



export class OnelinkGroup {
onelink_group_idx?: number

title?: string

description?: string

create_user_idx?: number

update_user_idx?: number

marketing_fee?: number

app_install_count?: number

order_item_count?: number

app_open_count?: number

send_count?: number

regist_datetime?: Date

update_datetime?: Date

tag?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

marketing_page_link?: string

//start
onelink_list?: Onelink[];
//end
}

export class SelectOnelinkGroupListReturn {
count?: number;

rows?: OnelinkGroup[];
}


export class InputOnelinkGroup{
onelink_group_idx?: number

title?: string

description?: string

create_user_idx?: number

update_user_idx?: number

marketing_fee?: number

app_install_count?: number

order_item_count?: number

app_open_count?: number

send_count?: number

regist_datetime?: Date

update_datetime?: Date

tag?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

marketing_page_link?: string


//input_start
onelink_idx_list?: number[];
//input_end
}

//class_end
//easy_graphql_comment_start
/*
order_call_idx
user_idx
item_idx
ref_post_idx
order_call_solved
regist_datetime
update_datetime
onelink_idx

*/
//easy_graphql_comment_end



















export class OrderCall {
order_call_idx?: number

user_idx?: number

item_idx?: number

ref_post_idx?: number

order_call_solved?: boolean

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number

//start
user?: User

ref_post?: Post

item?: Item
//end
}

export class SelectOrderCallListReturn {
count?: number;

rows?: OrderCall[];
}


export class InputOrderCall{
order_call_idx?: number

user_idx?: number

item_idx?: number

ref_post_idx?: number

order_call_solved?: boolean

regist_datetime?: Date

update_datetime?: Date

onelink_idx?: number


}

//class_end






















//easy_graphql_comment_start
/*
order_cancel_idx
user_idx
order_transaction_idx
order_item_idx_list
create_user_idx
cancel_type
request_type
total_cancel_price
total_refund_price
cancel_fee
cancel_user_pay_price
refund_user_pay_price
cancel_point
refund_point
cancel_coupon_price
cancel_status
change_status
refund_status
cancel_reason
cancel_message
cancel_result
user_pay_fee
manager_check_datetime
user_pay_fee_confirm_status
user_pay_fee_confirm_datetime
user_pay_fee_confirm_user_idx
item_inspect_status
item_inspect_message
item_inspect_datetime
item_inspect_user_idx
item_return_invoice_num
item_return_delivery_company_name
item_return_invoice_regist_datetime
item_return_request_datetime
item_return_request_user_idx
item_change_invoice_num
item_change_delivery_company_name
item_change_invoice_regist_datetime
refund_confirm_user_idx
refund_confirm_status
refund_confirm_datetime
refund_payment_type
refund_payment_error
refund_request_code
refund_request_message
refund_request_response
refund_request_tid
refund_payment_datetime
send_cancel_message_status
send_cancel_message_datetime
send_cancel_mssage_error
memo
update_datetime
regist_datetime
reject_order_cancel_datetime
reject_message
next_check_datetime

*/
//easy_graphql_comment_end



export class OrderCancel {
order_cancel_idx?: number

user_idx?: number

order_transaction_idx?: number

order_item_idx_list?: string

create_user_idx?: number

cancel_type?: string

request_type?: string

total_cancel_price?: number

total_refund_price?: number

cancel_fee?: number

cancel_user_pay_price?: number

refund_user_pay_price?: number

cancel_point?: number

refund_point?: number

cancel_coupon_price?: number

cancel_status?: string

change_status?: string

refund_status?: string

cancel_reason?: string

cancel_message?: string

cancel_result?: string

user_pay_fee?: number

manager_check_datetime?: Date

user_pay_fee_confirm_status?: number

user_pay_fee_confirm_datetime?: Date

user_pay_fee_confirm_user_idx?: number

item_inspect_status?: number

item_inspect_message?: string

item_inspect_datetime?: Date

item_inspect_user_idx?: number

item_return_invoice_num?: string

item_return_delivery_company_name?: string

item_return_invoice_regist_datetime?: Date

item_return_request_datetime?: Date

item_return_request_user_idx?: number

item_change_invoice_num?: string

item_change_delivery_company_name?: string

item_change_invoice_regist_datetime?: Date

refund_confirm_user_idx?: number

refund_confirm_status?: number

refund_confirm_datetime?: Date

refund_payment_type?: string

refund_payment_error?: string

refund_request_code?: string

refund_request_message?: string

refund_request_response?: string

refund_request_tid?: string

refund_payment_datetime?: Date

send_cancel_message_status?: number

send_cancel_message_datetime?: Date

send_cancel_mssage_error?: string

memo?: string

update_datetime?: Date

regist_datetime?: Date

reject_order_cancel_datetime?: Date

reject_message?: string

next_check_datetime?: Date

//start
user?: User

order_transaction?: OrderTransaction

order_cancel_item_list?: OrderCancelItem[]

//end
}

export class SelectOrderCancelListReturn {
count?: number;

rows?: OrderCancel[];
}


export class InputOrderCancel{
order_cancel_idx?: number

user_idx?: number

order_transaction_idx?: number

order_item_idx_list?: string

create_user_idx?: number

cancel_type?: string

request_type?: string

total_cancel_price?: number

total_refund_price?: number

cancel_fee?: number

cancel_user_pay_price?: number

refund_user_pay_price?: number

cancel_point?: number

refund_point?: number

cancel_coupon_price?: number

cancel_status?: string

change_status?: string

refund_status?: string

cancel_reason?: string

cancel_message?: string

cancel_result?: string

user_pay_fee?: number

manager_check_datetime?: Date

user_pay_fee_confirm_status?: number

user_pay_fee_confirm_datetime?: Date

user_pay_fee_confirm_user_idx?: number

item_inspect_status?: number

item_inspect_message?: string

item_inspect_datetime?: Date

item_inspect_user_idx?: number

item_return_invoice_num?: string

item_return_delivery_company_name?: string

item_return_invoice_regist_datetime?: Date

item_return_request_datetime?: Date

item_return_request_user_idx?: number

item_change_invoice_num?: string

item_change_delivery_company_name?: string

item_change_invoice_regist_datetime?: Date

refund_confirm_user_idx?: number

refund_confirm_status?: number

refund_confirm_datetime?: Date

refund_payment_type?: string

refund_payment_error?: string

refund_request_code?: string

refund_request_message?: string

refund_request_response?: string

refund_request_tid?: string

refund_payment_datetime?: Date

send_cancel_message_status?: number

send_cancel_message_datetime?: Date

send_cancel_mssage_error?: string

memo?: string

update_datetime?: Date

regist_datetime?: Date

reject_order_cancel_datetime?: Date

reject_message?: string

next_check_datetime?: Date


}

//class_end



//easy_graphql_comment_start
/*
order_cancel_item_idx
order_cancel_idx
order_item_idx

*/
//easy_graphql_comment_end



export class OrderCancelItem {
order_cancel_item_idx?: number

order_cancel_idx?: number

order_item_idx?: number

//start
order_cancel?: OrderCancel

order_item?: OrderItem
//end
}

export class SelectOrderCancelItemListReturn {
count?: number;

rows?: OrderCancelItem[];
}


export class InputOrderCancelItem{
order_cancel_item_idx?: number

order_cancel_idx?: number

order_item_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
order_claim_idx
order_transaction_idx
order_item_idx
payment_idx
item_idx
status
reason
cancel_price
cancel_datetime
delivery_fee
user_pay_delivery_fee
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class OrderClaim {
order_claim_idx?: number

order_transaction_idx?: number

order_item_idx?: number

payment_idx?: number

item_idx?: number

status?: string

reason?: string

cancel_price?: number

cancel_datetime?: Date

delivery_fee?: number

user_pay_delivery_fee?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectOrderClaimListReturn {
count?: number;

rows?: OrderClaim[];
}


export class InputOrderClaim{
order_claim_idx?: number

order_transaction_idx?: number

order_item_idx?: number

payment_idx?: number

item_idx?: number

status?: string

reason?: string

cancel_price?: number

cancel_datetime?: Date

delivery_fee?: number

user_pay_delivery_fee?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
order_item_idx
user_idx
order_transaction_idx
sales_channel_idx
item_idx
item_option_idx
order_count
item_option_text
sell_price
origin_price
buy_price
admin_plus_price
user_pay_price
external_plus_price
platform_fee
payment_fee
delivery_fee
company_delivery_fee
use_point
add_point
order_item_status
order_item_title
order_item_delivery_status
order_item_payment_status
invoice
invoice_num
invoice_delivery_company_name
invoice_check_datetime
delivery_complete_datetime
delivery_complete_sms_send_datetime
order_num
order_turn
regist_datetime
update_datetime
create_user_idx
update_user_idx
manager_user_idx
delivery_regist_invoice_datetime
apply_stock
adjustment_idx
arrival_source
app_open_pid
app_open_campaign
app_open_adset
onelink_idx
ref_idx
ref_table
point_ready_idx
point_ready_ref_idx
reword_check_datetime
reword_status
reword_memo
memo
shop_cart_idx
order_item_payment_confirm_datetime
order_item_payment_confirm_user_idx
ip
order_item_complete_order_datetime
review_enable_datetime
write_review_datetime
order_item_cancel_datetime
order_item_cancel_status
order_item_change_status
order_item_refund_status
incentive_check_datetime
incentive_check_status
incentive_check_momo
order_cancel_idx
next_check_datetime
is_deleted

*/
//easy_graphql_comment_end

































export class OrderItem {
order_item_idx?: number

user_idx?: number

order_transaction_idx?: number

sales_channel_idx?: number

item_idx?: number

item_option_idx?: number

order_count?: number

item_option_text?: string

sell_price?: number

origin_price?: number

buy_price?: number

admin_plus_price?: number

user_pay_price?: number

external_plus_price?: number

platform_fee?: number

payment_fee?: number

delivery_fee?: number

company_delivery_fee?: number

use_point?: number

add_point?: number

order_item_status?: string

order_item_title?: string

order_item_delivery_status?: string

order_item_payment_status?: string

invoice?: string

invoice_num?: string

invoice_delivery_company_name?: string

invoice_check_datetime?: Date

delivery_complete_datetime?: Date

delivery_complete_sms_send_datetime?: Date

order_num?: string

order_turn?: string

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

delivery_regist_invoice_datetime?: Date

apply_stock?: boolean

adjustment_idx?: number

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

onelink_idx?: number

ref_idx?: number

ref_table?: string

point_ready_idx?: number

point_ready_ref_idx?: number

reword_check_datetime?: Date

reword_status?: number

reword_memo?: string

memo?: string

shop_cart_idx?: number

order_item_payment_confirm_datetime?: Date

order_item_payment_confirm_user_idx?: number

ip?: string

order_item_complete_order_datetime?: Date

review_enable_datetime?: Date

write_review_datetime?: Date

order_item_cancel_datetime?: Date

order_item_cancel_status?: string

order_item_change_status?: string

order_item_refund_status?: string

incentive_check_datetime?: Date

incentive_check_status?: number

incentive_check_momo?: string

order_cancel_idx?: number

next_check_datetime?: Date

is_deleted?: number

//start
manager_user?: User

create_user?: User

update_user?: User

item_option?: ItemOption

item?: Item

order_transaction?: OrderTransaction

user?: User

onelink?: Onelink

sales_channel?: SalesChannel

review?: Review[];

order_cancel_item?: OrderCancelItem


//랭킹 비교용 컬럼
total_count: number;

total_amount: number;


//end
}

export class SelectOrderItemListReturn {
count?: number;

rows?: OrderItem[];
}


export class InputOrderItem{
order_item_idx?: number

user_idx?: number

order_transaction_idx?: number

sales_channel_idx?: number

item_idx?: number

item_option_idx?: number

order_count?: number

item_option_text?: string

sell_price?: number

origin_price?: number

buy_price?: number

admin_plus_price?: number

user_pay_price?: number

external_plus_price?: number

platform_fee?: number

payment_fee?: number

delivery_fee?: number

company_delivery_fee?: number

use_point?: number

add_point?: number

order_item_status?: string

order_item_title?: string

order_item_delivery_status?: string

order_item_payment_status?: string

invoice?: string

invoice_num?: string

invoice_delivery_company_name?: string

invoice_check_datetime?: Date

delivery_complete_datetime?: Date

delivery_complete_sms_send_datetime?: Date

order_num?: string

order_turn?: string

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

delivery_regist_invoice_datetime?: Date

apply_stock?: boolean

adjustment_idx?: number

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

onelink_idx?: number

ref_idx?: number

ref_table?: string

point_ready_idx?: number

point_ready_ref_idx?: number

reword_check_datetime?: Date

reword_status?: number

reword_memo?: string

memo?: string

shop_cart_idx?: number

order_item_payment_confirm_datetime?: Date

order_item_payment_confirm_user_idx?: number

ip?: string

order_item_complete_order_datetime?: Date

review_enable_datetime?: Date

write_review_datetime?: Date

order_item_cancel_datetime?: Date

order_item_cancel_status?: string

order_item_change_status?: string

order_item_refund_status?: string

incentive_check_datetime?: Date

incentive_check_status?: number

incentive_check_momo?: string

order_cancel_idx?: number

next_check_datetime?: Date

is_deleted?: number


}

//class_end































//easy_graphql_comment_start
/*
order_item_delivery
order_item_idx
delivery_item_count
delivery_fee
invoice_num
invoice_delivery_company_name
invoice_check_datetime
delivery_regist_invoice_datetime

*/
//easy_graphql_comment_end



export class OrderItemDelivery {
order_item_delivery?: number

order_item_idx?: number

delivery_item_count?: number

delivery_fee?: number

invoice_num?: string

invoice_delivery_company_name?: string

invoice_check_datetime?: Date

delivery_regist_invoice_datetime?: Date

}

export class SelectOrderItemDeliveryListReturn {
count?: number;

rows?: OrderItemDelivery[];
}


export class InputOrderItemDelivery{
order_item_delivery?: number

order_item_idx?: number

delivery_item_count?: number

delivery_fee?: number

invoice_num?: string

invoice_delivery_company_name?: string

invoice_check_datetime?: Date

delivery_regist_invoice_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
order_refer_idx
order_transaction_idx
post_idx
user_idx
item_idx
status
selected
comment
rate
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class OrderRefer {
order_refer_idx?: number

order_transaction_idx?: number

post_idx?: number

user_idx?: number

item_idx?: number

status?: number

selected?: number

comment?: string

rate?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectOrderReferListReturn {
count?: number;

rows?: OrderRefer[];
}


export class InputOrderRefer{
order_refer_idx?: number

order_transaction_idx?: number

post_idx?: number

user_idx?: number

item_idx?: number

status?: number

selected?: number

comment?: string

rate?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
order_transaction_idx
order_object_id
user_idx
sales_channel_idx
sales_channel_order_number
sales_channel_order_number2
sales_channel_item_code_idx
sales_channel_request_number
ref_user_idx
ref_post_idx
address
address_detail
post_code
request
order_user_name
order_user_phone
receiver_name
receiver_phone
receiver_phone_backup
order_transaction_title
order_transaction_img_url
total_count
total_origin_price
total_sell_price
total_cancel_price
total_refund_price
total_admin_plus_price
total_external_plus_price
total_user_pay_price
total_cancel_user_pay_price
total_buy_price
total_platform_fee
total_payment_fee
total_use_point
total_cancel_point
total_add_point
total_payment_price
total_delivery_fee
total_company_delivery_fee
total_use_coupon_price
total_cancel_coupon_price
payment_type
payment_idx
coupon_idx
order_status
payment_status
payment_confirm_datetime
payment_confirm_user_idx
order_transaction_memo
delivery_status
auto_order_complete_sms
auto_payment_complete_sms
regist_datetime
update_datetime
create_user_idx
update_user_idx
manager_user_idx
invoice_delivery_company_name
invoice_num
order_num
device
delivery_request_datetime
delivery_regist_invoice_datetime
ref_idx
ref_table
onelink_idx
app_open_pid
app_open_campaign
app_open_adset
arrival_source
point_ready_idx
order_transaction_pending_idx
ip
is_deleted

*/
//easy_graphql_comment_end




export class OrderTransaction {
order_transaction_idx?: number

order_object_id?: string

user_idx?: number

sales_channel_idx?: number

sales_channel_order_number?: string

sales_channel_order_number2?: string

sales_channel_item_code_idx?: number

sales_channel_request_number?: string

ref_user_idx?: number

ref_post_idx?: number

address?: string

address_detail?: string

post_code?: string

request?: string

order_user_name?: string

order_user_phone?: string

receiver_name?: string

receiver_phone?: string

receiver_phone_backup?: string

order_transaction_title?: string

order_transaction_img_url?: string

total_count?: number

total_origin_price?: number

total_sell_price?: number

total_cancel_price?: number

total_refund_price?: number

total_admin_plus_price?: number

total_external_plus_price?: number

total_user_pay_price?: number

total_cancel_user_pay_price?: number

total_buy_price?: number

total_platform_fee?: number

total_payment_fee?: number

total_use_point?: number

total_cancel_point?: number

total_add_point?: number

total_payment_price?: number

total_delivery_fee?: number

total_company_delivery_fee?: number

total_use_coupon_price?: number

total_cancel_coupon_price?: number

payment_type?: string

payment_idx?: number

coupon_idx?: number

order_status?: string

payment_status?: string

payment_confirm_datetime?: Date

payment_confirm_user_idx?: number

order_transaction_memo?: string

delivery_status?: string

auto_order_complete_sms?: boolean

auto_payment_complete_sms?: boolean

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

invoice_delivery_company_name?: string

invoice_num?: string

order_num?: string

device?: string

delivery_request_datetime?: Date

delivery_regist_invoice_datetime?: Date

ref_idx?: number

ref_table?: string

onelink_idx?: number

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

arrival_source?: string

point_ready_idx?: number

order_transaction_pending_idx?: number

ip?: string

is_deleted?: boolean

//start
user?: User

create_user?: User

update_user?: User

manager_user?: User

ref_user?: User

ref_post?: Post

sales_channel?: SalesChannel

order_item_list?: OrderItem[]

payment_list?: Payment[]

payment?: Payment

coupon?: Coupon

order_cancel_list?: OrderCancel[]


payment_cancel_request_list?: PaymentCancelRequest[]


//end
}

export class SelectOrderTransactionListReturn {
count?: number;

rows?: OrderTransaction[];
}


export class InputOrderTransaction{
order_transaction_idx?: number

order_object_id?: string

user_idx?: number

sales_channel_idx?: number

sales_channel_order_number?: string

sales_channel_order_number2?: string

sales_channel_item_code_idx?: number

sales_channel_request_number?: string

ref_user_idx?: number

ref_post_idx?: number

address?: string

address_detail?: string

post_code?: string

request?: string

order_user_name?: string

order_user_phone?: string

receiver_name?: string

receiver_phone?: string

receiver_phone_backup?: string

order_transaction_title?: string

order_transaction_img_url?: string

total_count?: number

total_origin_price?: number

total_sell_price?: number

total_cancel_price?: number

total_refund_price?: number

total_admin_plus_price?: number

total_external_plus_price?: number

total_user_pay_price?: number

total_cancel_user_pay_price?: number

total_buy_price?: number

total_platform_fee?: number

total_payment_fee?: number

total_use_point?: number

total_cancel_point?: number

total_add_point?: number

total_payment_price?: number

total_delivery_fee?: number

total_company_delivery_fee?: number

total_use_coupon_price?: number

total_cancel_coupon_price?: number

payment_type?: string

payment_idx?: number

coupon_idx?: number

order_status?: string

payment_status?: string

payment_confirm_datetime?: Date

payment_confirm_user_idx?: number

order_transaction_memo?: string

delivery_status?: string

auto_order_complete_sms?: boolean

auto_payment_complete_sms?: boolean

regist_datetime?: Date

update_datetime?: Date

create_user_idx?: number

update_user_idx?: number

manager_user_idx?: number

invoice_delivery_company_name?: string

invoice_num?: string

order_num?: string

device?: string

delivery_request_datetime?: Date

delivery_regist_invoice_datetime?: Date

ref_idx?: number

ref_table?: string

onelink_idx?: number

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

arrival_source?: string

point_ready_idx?: number

order_transaction_pending_idx?: number

ip?: string

is_deleted?: boolean


}

//class_end


export class InputOrderPayment {

order_transaction_idx?: number;

payment_idx?: number;

update_user_idx?: number;
}




export class InputOrder {

order_transaction?: InputOrderTransaction

order_item_list?: [InputOrderItem]
}


export class InputUserOrder {

address_book_idx?: number;

ref_user_idx?: number;

ref_post_idx?: number;

ref_idx?: number;

ref_table?: string;

payment_type?: string

request?: string;

total_use_point?: number

total_sell_price?: number;

total_delivery_fee?: number;

coupon_idx?: number

total_use_coupon_price?: number

arrival_source?: string;

app_open_pid?: string;

app_open_campaign?: string;

app_open_adset?: string;

onelink_idx?: number

order_transaction_pending_idx?: number

order_list?: InputUserOrderItem[]
}


export class InputUserOrderItem {
item_idx?: number

item_option_idx?: number

order_item_title?: string;

item_option_name?: string;

price?: number

count?: number

arrival_source?: string;

app_open_pid?: string;

app_open_campaign?: string;

app_open_adset?: string;

onelink_idx?: number

ref_idx?: number

ref_table?: string;
//사용하지 않는 field입니다.
img_url?: string;
}


export class InputUserPaymentCallback {

imp_uid?: string;

merchant_uid?: string;
}





export class DeliveryOrderItemListReturn {

count?: number;

rows?: DeliveryOrderItem[];
}


export class DeliveryOrderItem {

order_transaction_idx?: number;

order_item_idx?: number;

item_option_barcode?: string

delivery_company_idx?: number;

delivery_company_name?: string

sales_channel_id?: string

sales_channel_order_number?: string

order_turn?: string

address?: string

address_detail?: string

request?: string

post_code?: string

order_user_name?: string

order_user_phone?: string

receiver_name?: string

receiver_phone?: string

receiver_phone_backup?: string

item_title?: string

item_option_name?: string

item_sell_price?: number

order_item_count?: number


invoice?: string

order_transaction_memo?: string

order_item_memo?: string

delivery_status?: string

regist_datetime?: any;

update_datetime?: any;

crawling_url?: string

}



//easy_graphql_comment_start
/*
order_transaction_delay_idx
order_transaction_idx
delay_step
delay_message_idx
delay_point_idx
regist_datetime
update_datetime
error
process_datetime
process_status

*/
//easy_graphql_comment_end



export class OrderTransactionDelay {
order_transaction_delay_idx?: number

order_transaction_idx?: number

delay_step?: number

delay_message_idx?: number

delay_point_idx?: number

regist_datetime?: Date

update_datetime?: Date

error?: string

process_datetime?: Date

process_status?: number

}

export class SelectOrderTransactionDelayListReturn {
count?: number;

rows?: OrderTransactionDelay[];
}


export class InputOrderTransactionDelay{
order_transaction_delay_idx?: number

order_transaction_idx?: number

delay_step?: number

delay_message_idx?: number

delay_point_idx?: number

regist_datetime?: Date

update_datetime?: Date

error?: string

process_datetime?: Date

process_status?: number


}

//class_end
//easy_graphql_comment_start
/*
order_transaction_pending_idx
title
user_idx
order_transaction_idx
regist_datetime
update_datetime
expire_datetime

*/
//easy_graphql_comment_end



export class OrderTransactionPending {
order_transaction_pending_idx?: number

title?: string

user_idx?: number

order_transaction_idx?: number

regist_datetime?: Date

update_datetime?: Date

expire_datetime?: Date

}

export class SelectOrderTransactionPendingListReturn {
count?: number;

rows?: OrderTransactionPending[];
}


export class InputOrderTransactionPending{
order_transaction_pending_idx?: number

title?: string

user_idx?: number

order_transaction_idx?: number

regist_datetime?: Date

update_datetime?: Date

expire_datetime?: Date


//input_start
shop_cart_idx_list?: number[];
//input_end
}

//class_end
//easy_graphql_comment_start
/*
payment_idx
user_idx
payment_object_id
order_transaction_idx
order_price
complete_price
payment_price
payment_code
payment_status
payment_account
payment_type
payment_method
cancel_price
cancel_datetime
cancel_reason
confirm_datetime
create_user_idx
update_user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

































export class Payment {
payment_idx?: number

user_idx?: number

payment_object_id?: string

order_transaction_idx?: number

order_price?: number

complete_price?: number

payment_price?: number

payment_code?: string

payment_status?: string

payment_account?: string

payment_type?: string

payment_method?: string

cancel_price?: number

cancel_datetime?: Date

cancel_reason?: string

confirm_datetime?: Date

create_user_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
user?: User

create_user?: User

update_user?: User
//end
}

export class SelectPaymentListReturn {
count?: number;

rows?: Payment[];
}


export class InputPayment{
payment_idx?: number

user_idx?: number

payment_object_id?: string

order_transaction_idx?: number

order_price?: number

complete_price?: number

payment_price?: number

payment_code?: string

payment_status?: string

payment_account?: string

payment_type?: string

payment_method?: string

cancel_price?: number

cancel_datetime?: Date

cancel_reason?: string

confirm_datetime?: Date

create_user_idx?: number

update_user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end

export class InputIamportRequestBill {
customer_uid?: string

merchant_uid?: string

subscribe_card_info_idx?: number

amount?: number

name?: string

buyer_name?: string

buyer_tel?: string

buyer_addr?: string

buyer_postcode?: string

card_quota?: number
}


































export class InputUpdatePaymentStatus {

payment_idx?: number;

order_transaction_idx?: number;

payment_status?: string

update_user_idx?: number;

confirm_datetime?: any;

payment_method?: string
}


export class CardPaymentReturn {

result?: boolean;

result_message?: string;
}

export class IamportPayReturn {

success?: boolean

imp_uid?: string;

merchant_uid?: string;
}


//easy_graphql_comment_start
/*
payment_callback_idx
data
source
handled
result
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class PaymentCallback {
payment_callback_idx?: number

data?: string

source?: string

handled?: boolean

result?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectPaymentCallbackListReturn {
count?: number;

rows?: PaymentCallback[];
}


export class InputPaymentCallback{
payment_callback_idx?: number

data?: string

source?: string

handled?: boolean

result?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
payment_cancel_request_idx
pg
payment_method
payment_code
cancel_amount
cancel_reason
refund_request_code
refund_request_response
refund_request_message
regist_datetime
update_datetime
payment_idx
order_transaction_idx
order_cancel_idx

*/
//easy_graphql_comment_end



export class PaymentCancelRequest {
payment_cancel_request_idx?: number

pg?: string

payment_method?: string

payment_code?: string

cancel_amount?: number

cancel_reason?: string

refund_request_code?: string

refund_request_response?: string

refund_request_message?: string

regist_datetime?: Date

update_datetime?: Date

payment_idx?: number

order_transaction_idx?: number

order_cancel_idx?: number

//start

order_transaction?: OrderTransaction


//end
}

export class SelectPaymentCancelRequestListReturn {
count?: number;

rows?: PaymentCancelRequest[];
}


export class InputPaymentCancelRequest{
payment_cancel_request_idx?: number

pg?: string

payment_method?: string

payment_code?: string

cancel_amount?: number

cancel_reason?: string

refund_request_code?: string

refund_request_response?: string

refund_request_message?: string

regist_datetime?: Date

update_datetime?: Date

payment_idx?: number

order_transaction_idx?: number

order_cancel_idx?: number


}

//class_end
//easy_graphql_comment_start
/*
payment_type
is_use
regist_datetime
update_datetime
text
display_order

*/
//easy_graphql_comment_end



export class PaymentType {
payment_type?: string

is_use?: number

regist_datetime?: Date

update_datetime?: Date

text?: string

display_order?: number

}

export class SelectPaymentTypeListReturn {
count?: number;

rows?: PaymentType[];
}


export class InputPaymentType{
payment_type?: string

is_use?: number

regist_datetime?: Date

update_datetime?: Date

text?: string

display_order?: number


}

//class_end
//easy_graphql_comment_start
/*
point_idx
user_idx
point_rule_idx
order_transaction_idx
title
point_status
expire_date
point_amount
create_user_idx
add_ref_table_idx
add_ref_table
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Point {
point_idx?: number

user_idx?: number

point_rule_idx?: number

order_transaction_idx?: number

title?: string

point_status?: string

expire_date?: Date

point_amount?: number

create_user_idx?: number

add_ref_table_idx?: number

add_ref_table?: string

regist_datetime?: Date

update_datetime?: Date

//start
user?: User
//end
}

export class SelectPointListReturn {
count?: number;

rows?: Point[];
}


export class InputPoint{
point_idx?: number

user_idx?: number

point_rule_idx?: number

order_transaction_idx?: number

title?: string

point_status?: string

expire_date?: Date

point_amount?: number

create_user_idx?: number

add_ref_table_idx?: number

add_ref_table?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end

export class InputPointList {
count?: number;

rows?: InputPoint[];
}

export class SelectPointAllInfoReturn {

count?: number;

rows?: Point[];

point_balance?: number;

point_to_be_expired?: number;
}
//easy_graphql_comment_start
/*
point_analytics_idx
target_date
total_add_point
total_use_point
total_add_count
total_use_count
total_add_user_count
total_use_user_count
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class PointAnalytics {
point_analytics_idx?: number

target_date?: string

total_add_point?: number

total_use_point?: number

total_add_count?: number

total_use_count?: number

total_add_user_count?: number

total_use_user_count?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectPointAnalyticsListReturn {
count?: number;

rows?: PointAnalytics[];
}


export class InputPointAnalytics{
point_analytics_idx?: number

target_date?: string

total_add_point?: number

total_use_point?: number

total_add_count?: number

total_use_count?: number

total_add_user_count?: number

total_use_user_count?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
point_log_idx
point_idx
user_idx
point_status
point_amount
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class PointLog {
point_log_idx?: number

point_idx?: number

user_idx?: number

point_status?: string

point_amount?: number

regist_datetime?: Date

update_datetime?: Date

//start
total_amount?: number
//end
}

export class SelectPointLogListReturn {
count?: number;

rows?: PointLog[];
}


export class InputPointLog{
point_log_idx?: number

point_idx?: number

user_idx?: number

point_status?: string

point_amount?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
point_ready_idx
user_idx
point_rule_idx
order_transaction_idx
order_item_idx
title
description
point_status
expire_date
point_amount
create_user_idx
add_ref_table_idx
add_ref_table
regist_datetime
update_datetime
predict_datetime
complete_datetime
point_idx
memo
complete_push_title
complete_push_message
ref_idx
ref_table
message_param

*/
//easy_graphql_comment_end



export class PointReady {
point_ready_idx?: number

user_idx?: number

point_rule_idx?: number

order_transaction_idx?: number

order_item_idx?: number

title?: string

description?: string

point_status?: number

expire_date?: Date

point_amount?: number

create_user_idx?: number

add_ref_table_idx?: number

add_ref_table?: string

regist_datetime?: Date

update_datetime?: Date

predict_datetime?: Date

complete_datetime?: Date

point_idx?: number

memo?: string

complete_push_title?: string

complete_push_message?: string

ref_idx?: number

ref_table?: string

message_param?: string

//start

point_rule?: PointRule

user?: User

//end
}

export class SelectPointReadyListReturn {
count?: number;

rows?: PointReady[];
}


export class InputPointReady{
point_ready_idx?: number

user_idx?: number

point_rule_idx?: number

order_transaction_idx?: number

order_item_idx?: number

title?: string

description?: string

point_status?: number

expire_date?: Date

point_amount?: number

create_user_idx?: number

add_ref_table_idx?: number

add_ref_table?: string

regist_datetime?: Date

update_datetime?: Date

predict_datetime?: Date

complete_datetime?: Date

point_idx?: number

memo?: string

complete_push_title?: string

complete_push_message?: string

ref_idx?: number

ref_table?: string

message_param?: string


}

//class_end
//easy_graphql_comment_start
/*
point_rule_idx
point_rule_title
valid_day
limit_count
point_rule_onoff
point_amount
regist_datetime
update_datetime
max_amount
point_ratio
memo
point_rule_id
delay_day
max_count_per_day
max_count_per_ref_table_per_day
max_count_per_ref_table
max_count_per_ref_index_table_per_day
max_count_per_ref_index_table
push_alarm_delay_min
point_calculate_type
push_url
ready_message_templete_idx
message_templete_idx
is_public
push_alarm_type

*/
//easy_graphql_comment_end



export class PointRule {
point_rule_idx?: number

point_rule_title?: string

valid_day?: number

limit_count?: number

point_rule_onoff?: boolean

point_amount?: number

regist_datetime?: Date

update_datetime?: Date

max_amount?: number

point_ratio?: number

memo?: string

point_rule_id?: string

delay_day?: number

max_count_per_day?: number

max_count_per_ref_table_per_day?: number

max_count_per_ref_table?: number

max_count_per_ref_index_table_per_day?: number

max_count_per_ref_index_table?: number

push_alarm_delay_min?: number

point_calculate_type?: string

push_url?: string

ready_message_templete_idx?: number

message_templete_idx?: number

is_public?: boolean

push_alarm_type?: number

//start
message_templete?: MessageTemplete


ready_message_templete?: MessageTemplete

//end
}

export class SelectPointRuleListReturn {
count?: number;

rows?: PointRule[];
}


export class InputPointRule{
point_rule_idx?: number

point_rule_title?: string

valid_day?: number

limit_count?: number

point_rule_onoff?: boolean

point_amount?: number

regist_datetime?: Date

update_datetime?: Date

max_amount?: number

point_ratio?: number

memo?: string

point_rule_id?: string

delay_day?: number

max_count_per_day?: number

max_count_per_ref_table_per_day?: number

max_count_per_ref_table?: number

max_count_per_ref_index_table_per_day?: number

max_count_per_ref_index_table?: number

push_alarm_delay_min?: number

point_calculate_type?: string

push_url?: string

ready_message_templete_idx?: number

message_templete_idx?: number

is_public?: boolean

push_alarm_type?: number


}

//class_end
//easy_graphql_comment_start
/*
point_withdraw_idx
user_idx
amount
point_withdraw_status
bank_name
bank_owner_name
bank_account_number
bank_code
user_name
user_address
tax_number
regist_datetime
update_datetime
transmit_datetime
transmit_result
transmit_error

*/
//easy_graphql_comment_end



















export class PointWithdraw {
point_withdraw_idx?: number

user_idx?: number

amount?: number

point_withdraw_status?: string

bank_name?: string

bank_owner_name?: string

bank_account_number?: string

bank_code?: string

user_name?: string

user_address?: string

tax_number?: string

regist_datetime?: Date

update_datetime?: Date

transmit_datetime?: Date

transmit_result?: string

transmit_error?: string

}

export class SelectPointWithdrawListReturn {
count?: number;

rows?: PointWithdraw[];
}


export class InputPointWithdraw{
point_withdraw_idx?: number

user_idx?: number

amount?: number

point_withdraw_status?: string

bank_name?: string

bank_owner_name?: string

bank_account_number?: string

bank_code?: string

user_name?: string

user_address?: string

tax_number?: string

regist_datetime?: Date

update_datetime?: Date

transmit_datetime?: Date

transmit_result?: string

transmit_error?: string


}

//class_end






















//easy_graphql_comment_start
/*
post_idx
user_idx
device
title
description
post_type
video_url
preview_img_url
reply_count
like_count
view_count
admin_check_status
open_status
regist_datetime
update_datetime
recent_reply_idx
is_deleted

*/
//easy_graphql_comment_end



export class Post {
post_idx?: number

user_idx?: number

device?: string

title?: string

description?: string

post_type?: string

video_url?: string

preview_img_url?: string

reply_count?: number

like_count?: number

view_count?: number

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

open_status?: 'public'|'private'

regist_datetime?: Date

update_datetime?: Date

recent_reply_idx?: number

is_deleted?: boolean

//start
user?: User

post_resource_list?: PostResource[];

reply_list?: Reply[];

like_list?: Like[];

post_item_list?: PostItem[];

next_videos_list?: Post[];

contest_review?: ContestReview

post_tv?: PostTv


store?: Store


tag_list?: string

review?: Review


//end
}

export class SelectPostListReturn {
count?: number;

rows?: Post[];
}


export class InputPost{
post_idx?: number

user_idx?: number

device?: string

title?: string

description?: string

post_type?: string

video_url?: string

preview_img_url?: string

reply_count?: number

like_count?: number

view_count?: number

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

open_status?: 'public'|'private'

regist_datetime?: Date

update_datetime?: Date

recent_reply_idx?: number

is_deleted?: boolean


//input_start
tag_list?: string

post_item_list?: InputPostItem[];

//input_end
}

//class_end

export class SearchRecommendListReturn {

tag?: SelectTagListReturn;

post?: SelectPostListReturn;

}

export class PostRecommendItem {
post_idx?: number;

items?: Item[];
}

export class PostRecommendItems {
post_recommend_items?: PostRecommendItem[];
}
//easy_graphql_comment_start
/*
post_idx
user_idx
device
title
description
post_type
video_url
preview_img_url
reply_count
like_count
view_count
admin_check_status
open_status
regist_datetime
update_datetime
recent_reply_idx
is_deleted

*/
//easy_graphql_comment_end



















export class PostDeleted {
post_idx?: number

user_idx?: number

device?: string

title?: string

description?: string

post_type?: string

video_url?: string

preview_img_url?: string

reply_count?: number

like_count?: number

view_count?: number

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

open_status?: 'public'|'private'

regist_datetime?: Date

update_datetime?: Date

recent_reply_idx?: number

is_deleted?: boolean

}

export class SelectPostDeletedListReturn {
count?: number;

rows?: PostDeleted[];
}


export class InputPostDeleted{
post_idx?: number

user_idx?: number

device?: string

title?: string

description?: string

post_type?: string

video_url?: string

preview_img_url?: string

reply_count?: number

like_count?: number

view_count?: number

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

open_status?: 'public'|'private'

regist_datetime?: Date

update_datetime?: Date

recent_reply_idx?: number

is_deleted?: boolean


}

//class_end






















//easy_graphql_comment_start
/*
post_idx
item_idx
list_order
regist_datetime
update_datetime

*/
//easy_graphql_comment_end































export class PostItem {
post_idx?: number

item_idx?: number

list_order?: number

regist_datetime?: Date

update_datetime?: Date

//start
item?: Item

post?: Post
//end
}

export class SelectPostItemListReturn {
count?: number;

rows?: PostItem[];
}


export class InputPostItem{
post_idx?: number

item_idx?: number

list_order?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end

































//easy_graphql_comment_start
/*
post_idx
item_idx
list_order
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



















export class PostItemDeleted {
post_idx?: number

item_idx?: number

list_order?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectPostItemDeletedListReturn {
count?: number;

rows?: PostItemDeleted[];
}


export class InputPostItemDeleted{
post_idx?: number

item_idx?: number

list_order?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end






















//easy_graphql_comment_start
/*
post_item_view_history_idx
user_idx
item_idx
ref_post_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



















export class PostItemViewHistory {
post_item_view_history_idx?: number

user_idx?: number

item_idx?: number

ref_post_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
user?: User
//end
}

export class SelectPostItemViewHistoryListReturn {
count?: number;

rows?: PostItemViewHistory[];
}


export class InputPostItemViewHistory{
post_item_view_history_idx?: number

user_idx?: number

item_idx?: number

ref_post_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end






















//easy_graphql_comment_start
/*
post_resource_idx
post_idx
post_resource_url
post_resource_type
post_resource_order
width
height
color
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

































export class PostResource {
post_resource_idx?: number

post_idx?: number

post_resource_url?: string

post_resource_type?: string

post_resource_order?: number

width?: number

height?: number

color?: number

regist_datetime?: Date

update_datetime?: Date

//start
post?: Post
//end
}

export class SelectPostResourceListReturn {
count?: number;

rows?: PostResource[];
}


export class InputPostResource{
post_resource_idx?: number

post_idx?: number

post_resource_url?: string

post_resource_type?: string

post_resource_order?: number

width?: number

height?: number

color?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end

































//easy_graphql_comment_start
/*
post_resource_idx
post_idx
post_resource_url
post_resource_type
post_resource_order
width
height
color
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



















export class PostResourceDeleted {
post_resource_idx?: number

post_idx?: number

post_resource_url?: string

post_resource_type?: string

post_resource_order?: number

width?: number

height?: number

color?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectPostResourceDeletedListReturn {
count?: number;

rows?: PostResourceDeleted[];
}


export class InputPostResourceDeleted{
post_resource_idx?: number

post_idx?: number

post_resource_url?: string

post_resource_type?: string

post_resource_order?: number

width?: number

height?: number

color?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end






















//easy_graphql_comment_start
/*
post_tv_idx
post_idx
store_idx
item_sell_price
item_origin_price
post_tv_tags
admin_check_status
regist_datetime
update_datetime
video_url
item_list_title
width
height
display_order
preview_video_url
post_tv_title
post_tv_img_url
post_tv_img_url_before
post_tv_img_url_after
post_tv_banner_image_url
preview_image_url
show_home
live_start_datetime
live_end_datetime
push_send_datetime
post_tv_like_count

*/
//easy_graphql_comment_end



export class PostTv {
post_tv_idx?: number

post_idx?: number

store_idx?: number

item_sell_price?: number

item_origin_price?: number

post_tv_tags?: string

admin_check_status?: string

regist_datetime?: Date

update_datetime?: Date

video_url?: string

item_list_title?: string

width?: number

height?: number

display_order?: number

preview_video_url?: string

post_tv_title?: string

post_tv_img_url?: string

post_tv_img_url_before?: string

post_tv_img_url_after?: string

post_tv_banner_image_url?: string

preview_image_url?: string

show_home?: boolean

live_start_datetime?: Date

live_end_datetime?: Date

push_send_datetime?: Date

post_tv_like_count?: number

//start
post?: Post

store?: Store

// @AfterCreate
// static async syncPostTvCreate(post_tv: any) {
// 	console.log("!!!!!!!!!will sync firebase AfterCreate",post_tv);
// 	console.log("!!!!!!!!!will sync firebase AfterCreate dataValues",post_tv.dataValues);
// 	await FirebaseService.getInstance().updatePostTv(post_tv.dataValues.post_tv_idx);
// 	console.log("!!!!!!!!!will sync firebase end");
// }

// @BeforeBulkDestroy
// static async syncPostTvDeleteBulk(post_tv: any) {
// 	console.log("!!!!!!!!!will sync firebase delete BeforeBulkDestroy", post_tv);
// 	// console.log("!!!!!!!!!will sync firebase update BeforeBulkDestroy where", post_tv.where);
// 	// console.log("!!!!!!!!!will sync firebase update BeforeBulkDestroy post_tv_idx", post_tv.where.post_idx);
// 	await FirebaseService.getInstance().deletePostTv(post_tv.where.post_idx);
// 	console.log("!!!!!!!!!will sync firebase delete end");
// }


// @AfterDestroy
// static async syncPostTvDelete(post_tv: any) {
// 	console.log("!!!!!!!!!will sync firebase delete BeforeBulkDestroy", post_tv);
// 	// console.log("!!!!!!!!!will sync firebase update BeforeBulkDestroy where", post_tv.where);
// 	// console.log("!!!!!!!!!will sync firebase update BeforeBulkDestroy post_tv_idx", post_tv.where.post_idx);
// 	await FirebaseService.getInstance().deletePostTv(post_tv.dataValues.post_tv_idx);
// 	console.log("!!!!!!!!!will sync firebase delete end");
// }

// @AfterUpdate
// @AfterUpsert
// @AfterSave
// @AfterBulkUpdate
// static async syncPostTvUpdate(post_tv: any) {
// 	console.log("!!!!!!!!!will sync firebase update AfterBulkUpdate", post_tv);

// 	await FirebaseService.getInstance().updatePostTv(post_tv.attributes.post_tv_idx);
// 	console.log("!!!!!!!!!will sync firebase update end");
// }
//end
}

export class SelectPostTvListReturn {
count?: number;

rows?: PostTv[];
}


export class InputPostTv{
post_tv_idx?: number

post_idx?: number

store_idx?: number

item_sell_price?: number

item_origin_price?: number

post_tv_tags?: string

admin_check_status?: string

regist_datetime?: Date

update_datetime?: Date

video_url?: string

item_list_title?: string

width?: number

height?: number

display_order?: number

preview_video_url?: string

post_tv_title?: string

post_tv_img_url?: string

post_tv_img_url_before?: string

post_tv_img_url_after?: string

post_tv_banner_image_url?: string

preview_image_url?: string

show_home?: boolean

live_start_datetime?: Date

live_end_datetime?: Date

push_send_datetime?: Date

post_tv_like_count?: number


//input_start
post?: InputPost;
//input_end
}

//class_end




//easy_graphql_comment_start
/*
post_tv_idx
user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class PostTvLike {
post_tv_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectPostTvLikeListReturn {
count?: number;

rows?: PostTvLike[];
}


export class InputPostTvLike{
post_tv_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
post_tv_log_idx
user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class PostTvLikeLog {
post_tv_log_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectPostTvLikeLogListReturn {
count?: number;

rows?: PostTvLikeLog[];
}


export class InputPostTvLikeLog{
post_tv_log_idx?: number

user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
proxy_login_idx
user_idx
site
token
ip
regist_datetime
update_datetime
confirm_datetime

*/
//easy_graphql_comment_end



export class ProxyLogin {
proxy_login_idx?: number

user_idx?: number

site?: string

token?: string

ip?: string

regist_datetime?: Date

update_datetime?: Date

confirm_datetime?: Date

}

export class SelectProxyLoginListReturn {
count?: number;

rows?: ProxyLogin[];
}


export class InputProxyLogin{
proxy_login_idx?: number

user_idx?: number

site?: string

token?: string

ip?: string

regist_datetime?: Date

update_datetime?: Date

confirm_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
push_alarm_idx
user_idx
push_target_type
push_ref_table
push_alarm_status
push_ref_idx
segment
alarm_type
alarm_url
app_url
web_url
alarm_title
alarm_message
alarm_img_url
alarm_open_datetime
user_display_onoff
reservation_datetime
send_datetime
send_result
send_id
push_data
regist_datetime
update_datetime
big_picture
accent_color
recipients
onelink_idx
push_type
force_send

*/
//easy_graphql_comment_end


export class PushAlarm {
push_alarm_idx?: number

user_idx?: number

push_target_type?: string

push_ref_table?: string

push_alarm_status?: number

push_ref_idx?: number

segment?: string

alarm_type?: string

alarm_url?: string

app_url?: string

web_url?: string

alarm_title?: string

alarm_message?: string

alarm_img_url?: string

alarm_open_datetime?: Date

user_display_onoff?: boolean

reservation_datetime?: Date

send_datetime?: Date

send_result?: string

send_id?: string

push_data?: string

regist_datetime?: Date

update_datetime?: Date

big_picture?: string

accent_color?: string

recipients?: number

onelink_idx?: number

push_type?: number

force_send?: number

//start
user?: User


//end
}

export class SelectPushAlarmListReturn {
count?: number;

rows?: PushAlarm[];
}


export class InputPushAlarm{
push_alarm_idx?: number

user_idx?: number

push_target_type?: string

push_ref_table?: string

push_alarm_status?: number

push_ref_idx?: number

segment?: string

alarm_type?: string

alarm_url?: string

app_url?: string

web_url?: string

alarm_title?: string

alarm_message?: string

alarm_img_url?: string

alarm_open_datetime?: Date

user_display_onoff?: boolean

reservation_datetime?: Date

send_datetime?: Date

send_result?: string

send_id?: string

push_data?: string

regist_datetime?: Date

update_datetime?: Date

big_picture?: string

accent_color?: string

recipients?: number

onelink_idx?: number

push_type?: number

force_send?: number


}

//class_end

































//easy_graphql_comment_start
/*
push_alarm_idx
user_idx
push_target_type
push_ref_table
push_alarm_status
push_ref_idx
segment
alarm_type
alarm_url
app_url
web_url
alarm_title
alarm_message
alarm_img_url
alarm_open_datetime
user_display_onoff
reservation_datetime
send_datetime
send_result
send_id
push_data
regist_datetime
update_datetime
big_picture
accent_color
recipients
onelink_idx
push_type
force_send

*/
//easy_graphql_comment_end



export class PushAlarmLog {
push_alarm_idx?: number

user_idx?: number

push_target_type?: string

push_ref_table?: string

push_alarm_status?: number

push_ref_idx?: number

segment?: string

alarm_type?: string

alarm_url?: string

app_url?: string

web_url?: string

alarm_title?: string

alarm_message?: string

alarm_img_url?: string

alarm_open_datetime?: Date

user_display_onoff?: boolean

reservation_datetime?: Date

send_datetime?: Date

send_result?: string

send_id?: string

push_data?: string

regist_datetime?: Date

update_datetime?: Date

big_picture?: string

accent_color?: string

recipients?: number

onelink_idx?: number

push_type?: number

force_send?: number

}

export class SelectPushAlarmLogListReturn {
count?: number;

rows?: PushAlarmLog[];
}


export class InputPushAlarmLog{
push_alarm_idx?: number

user_idx?: number

push_target_type?: string

push_ref_table?: string

push_alarm_status?: number

push_ref_idx?: number

segment?: string

alarm_type?: string

alarm_url?: string

app_url?: string

web_url?: string

alarm_title?: string

alarm_message?: string

alarm_img_url?: string

alarm_open_datetime?: Date

user_display_onoff?: boolean

reservation_datetime?: Date

send_datetime?: Date

send_result?: string

send_id?: string

push_data?: string

regist_datetime?: Date

update_datetime?: Date

big_picture?: string

accent_color?: string

recipients?: number

onelink_idx?: number

push_type?: number

force_send?: number


}

//class_end
//easy_graphql_comment_start
/*
reply_idx
user_idx
post_idx
parent_reply_idx
content
admin_check_status
regist_datetime
update_datetime
ip
is_deleted

*/
//easy_graphql_comment_end

export class Reply {
reply_idx?: number

user_idx?: number

post_idx?: number

parent_reply_idx?: number

content?: string

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

regist_datetime?: Date

update_datetime?: Date

ip?: string

is_deleted?: boolean

//start
user?: User


post?: Post

reply_list?: Reply[];
//end
}

export class SelectReplyListReturn {
count?: number;

rows?: Reply[];
}


export class InputReply{
reply_idx?: number

user_idx?: number

post_idx?: number

parent_reply_idx?: number

content?: string

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

regist_datetime?: Date

update_datetime?: Date

ip?: string

is_deleted?: boolean


}

//class_end
//easy_graphql_comment_start
/*
reply_idx
user_idx
post_idx
parent_reply_idx
content
admin_check_status
regist_datetime
update_datetime
ip
is_deleted

*/
//easy_graphql_comment_end



















export class ReplyDeleted {
reply_idx?: number

user_idx?: number

post_idx?: number

parent_reply_idx?: number

content?: string

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

regist_datetime?: Date

update_datetime?: Date

ip?: string

is_deleted?: boolean

}

export class SelectReplyDeletedListReturn {
count?: number;

rows?: ReplyDeleted[];
}


export class InputReplyDeleted{
reply_idx?: number

user_idx?: number

post_idx?: number

parent_reply_idx?: number

content?: string

admin_check_status?: 'ready'|'accepted'|'rejected'|'reported'

regist_datetime?: Date

update_datetime?: Date

ip?: string

is_deleted?: boolean


}

//class_end






















//easy_graphql_comment_start
/*
report_idx
post_idx
reply_idx
report_user_idx
reported_user_idx
report_type
report_reason
regist_datetime
update_datetime
report_result
is_deleted

*/
//easy_graphql_comment_end



export class Report {
report_idx?: number

post_idx?: number

reply_idx?: number

report_user_idx?: number

reported_user_idx?: number

report_type?: string

report_reason?: string

regist_datetime?: Date

update_datetime?: Date

report_result?: string

is_deleted?: boolean

//start
report_user?: User

reported_user?: User

post?: Post

reply?: Reply
//end
}

export class SelectReportListReturn {
count?: number;

rows?: Report[];
}


export class InputReport{
report_idx?: number

post_idx?: number

reply_idx?: number

report_user_idx?: number

reported_user_idx?: number

report_type?: string

report_reason?: string

regist_datetime?: Date

update_datetime?: Date

report_result?: string

is_deleted?: boolean


}

//class_end
//easy_graphql_comment_start
/*
report_idx
post_idx
reply_idx
report_user_idx
reported_user_idx
report_type
report_reason
regist_datetime
update_datetime
report_result
is_deleted

*/
//easy_graphql_comment_end



export class ReportDeleted {
report_idx?: number

post_idx?: number

reply_idx?: number

report_user_idx?: number

reported_user_idx?: number

report_type?: string

report_reason?: string

regist_datetime?: Date

update_datetime?: Date

report_result?: string

is_deleted?: boolean

}

export class SelectReportDeletedListReturn {
count?: number;

rows?: ReportDeleted[];
}


export class InputReportDeleted{
report_idx?: number

post_idx?: number

reply_idx?: number

report_user_idx?: number

reported_user_idx?: number

report_type?: string

report_reason?: string

regist_datetime?: Date

update_datetime?: Date

report_result?: string

is_deleted?: boolean


}

//class_end
//easy_graphql_comment_start
/*
resource_idx
resource_type
resource_kind
url
w
h
color
text
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Resource {
resource_idx?: number

resource_type?: string

resource_kind?: string

url?: string

w?: number

h?: number

color?: number

text?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectResourceListReturn {
count?: number;

rows?: Resource[];
}


export class InputResource{
resource_idx?: number

resource_type?: string

resource_kind?: string

url?: string

w?: number

h?: number

color?: number

text?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
review_idx
user_idx
order_item_idx
post_idx
item_idx
rate
review_type
regist_datetime
update_datetime
is_deleted

*/
//easy_graphql_comment_end



export class Review {
review_idx?: number

user_idx?: number

order_item_idx?: number

post_idx?: number

item_idx?: number

rate?: number

review_type?: string

regist_datetime?: Date

update_datetime?: Date


//start
user?: User

order_item?: OrderItem;

post?: Post;

item?: Item;
//end

}

export class SelectReviewListReturn {
count?: number;

average_rate?: string;

review_count?: number;

rows?: Review[];
}


export class InputReview{
review_idx?: number

user_idx?: number

order_item_idx?: number

post_idx?: number

item_idx?: number

rate?: number

review_type?: string

regist_datetime?: Date

update_datetime?: Date

}


//easy_graphql_comment_start
/*
reword_check_idx
target_date
reword_type
reword_check_status
memo
error_log
order_item_idx
post_idx
review_idx
reply_idx
regist_datetime
update_datetime
reword_check_datetime
point_rule_idx
user_idx
ref_idx
ref_table
sub_ref_table
sub_ref_idx
index_ref_table
index_ref_idx
pay_price
message_param

*/
//easy_graphql_comment_end



export class RewordCheck {
reword_check_idx?: number

target_date?: string

reword_type?: string

reword_check_status?: number

memo?: string

error_log?: string

order_item_idx?: number

post_idx?: number

review_idx?: number

reply_idx?: number

regist_datetime?: Date

update_datetime?: Date

reword_check_datetime?: Date

point_rule_idx?: number

user_idx?: number

ref_idx?: number

ref_table?: string

sub_ref_table?: string

sub_ref_idx?: number

index_ref_table?: string

index_ref_idx?: number

pay_price?: number

message_param?: string

//start
user?: User

reply?: Reply

post?: Post

point_rule?: PointRule

//end
}

export class SelectRewordCheckListReturn {
count?: number;

rows?: RewordCheck[];
}


export class InputRewordCheck{
reword_check_idx?: number

target_date?: string

reword_type?: string

reword_check_status?: number

memo?: string

error_log?: string

order_item_idx?: number

post_idx?: number

review_idx?: number

reply_idx?: number

regist_datetime?: Date

update_datetime?: Date

reword_check_datetime?: Date

point_rule_idx?: number

user_idx?: number

ref_idx?: number

ref_table?: string

sub_ref_table?: string

sub_ref_idx?: number

index_ref_table?: string

index_ref_idx?: number

pay_price?: number

message_param?: string


}

//class_end
//easy_graphql_comment_start
/*
role
role_name
role_description

*/
//easy_graphql_comment_end



export class Role {
role?: string

role_name?: string

role_description?: string

}

export class SelectRoleListReturn {
count?: number;

rows?: Role[];
}


export class InputRole{
role?: string

role_name?: string

role_description?: string


}

//class_end
//easy_graphql_comment_start
/*
role_join_idx
user_idx
role
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class RoleJoin {
role_join_idx?: number

user_idx?: number

role?: string

regist_datetime?: Date

update_datetime?: Date

//start
user: User


//end
}

export class SelectRoleJoinListReturn {
count?: number;

rows?: RoleJoin[];
}


export class InputRoleJoin{
role_join_idx?: number

user_idx?: number

role?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
sales_channel_idx
sales_channel_object_id
sales_channel_name
sales_channel_id
sales_channel_fee_rate
sales_channel_description
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class SalesChannel {
sales_channel_idx?: number

sales_channel_object_id?: string

sales_channel_name?: string

sales_channel_id?: string

sales_channel_fee_rate?: number

sales_channel_description?: string

regist_datetime?: Date

update_datetime?: Date

//start
item_salse_channel_list?: ItemSalesChannel[]

item_option_sales_channel_list?: ItemOptionSalesChannel[]
//end
}

export class SelectSalesChannelListReturn {
count?: number;

rows?: SalesChannel[];
}


export class InputSalesChannel{
sales_channel_idx?: number

sales_channel_object_id?: string

sales_channel_name?: string

sales_channel_id?: string

sales_channel_fee_rate?: number

sales_channel_description?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
sales_channel_item_code_idx
sales_channel_idx
sales_channel_item_code
sales_channel_item_code_memo
sales_channel_item_count
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class SalesChannelItemCode {
sales_channel_item_code_idx?: number

sales_channel_idx?: number

sales_channel_item_code?: string

sales_channel_item_code_memo?: string

sales_channel_item_count?: number

regist_datetime?: Date

update_datetime?: Date

//start
salse_channel_item_option_barcode_list?: SalesChannelItemOptionBarcode[]

sales_channel?: SalesChannel
//end
}

export class SelectSalesChannelItemCodeListReturn {
count?: number;

rows?: SalesChannelItemCode[];
}


export class InputSalesChannelItemCode{
sales_channel_item_code_idx?: number

sales_channel_idx?: number

sales_channel_item_code?: string

sales_channel_item_code_memo?: string

sales_channel_item_count?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end


export class ResultSalesChannelItemCodeExcelList {
count?: number;

rows?: ResultSalesChannelItemCodeExcel[];
}



export class ResultSalesChannelItemCodeExcel {
sales_channel_item_code_idx?: number

sales_channel_item_code?: string

sales_channel_name?: string

salse_channel_item_option_barcode_list?: string

error?: string

}



export class InputSalesChannelItemCodeExcel {
sales_channel_item_code_idx?: number

sales_channel_item_code?: string

sales_channel_name?: string

salse_channel_item_option_barcode_list?: string
}


export class InputSalesChannelItemCodeExcelList {

data?: InputSalesChannelItemCodeExcel[];

}



//easy_graphql_comment_start
/*
sales_channel_item_option_barcode_idx
sales_channel_item_code_idx
item_idx
item_option_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class SalesChannelItemOptionBarcode {
sales_channel_item_option_barcode_idx?: number

sales_channel_item_code_idx?: number

item_idx?: number

item_option_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
item?: Item

item_option?: ItemOption

sales_channel_item_code?: SalesChannelItemCode

//end
}

export class SelectSalesChannelItemOptionBarcodeListReturn {
count?: number;

rows?: SalesChannelItemOptionBarcode[];
}


export class InputSalesChannelItemOptionBarcode{
sales_channel_item_option_barcode_idx?: number

sales_channel_item_code_idx?: number

item_idx?: number

item_option_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
search_keyword_history_idx
user_idx
keyword
regist_datetime

*/
//easy_graphql_comment_end





export class SearchKeywordHistory {
search_keyword_history_idx?: number

user_idx?: number

keyword?: string

regist_datetime?: Date

}

export class SelectSearchKeywordHistoryListReturn {
count?: number;

rows?: SearchKeywordHistory[];
}


export class InputSearchKeywordHistory{
search_keyword_history_idx?: number

user_idx?: number

keyword?: string

regist_datetime?: Date


}

//class_end






















//easy_graphql_comment_start
/*
shop_cart_idx
user_idx
item_option_idx
total_count
regist_datetime
update_datetime
arrival_source
app_open_pid
app_open_campaign
app_open_adset
onelink_idx
ref_idx
ref_table
order_transaction_pending_idx
ip

*/
//easy_graphql_comment_end



export class ShopCart {
shop_cart_idx?: number

user_idx?: number

item_option_idx?: number

total_count?: number

regist_datetime?: Date

update_datetime?: Date

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

onelink_idx?: number

ref_idx?: number

ref_table?: string

order_transaction_pending_idx?: number

ip?: string

//start
item_option?: ItemOption


user?: User
//end
}

export class SelectShopCartListReturn {
count?: number;

rows?: ShopCart[];
}


export class InputShopCart{
shop_cart_idx?: number

user_idx?: number

item_option_idx?: number

total_count?: number

regist_datetime?: Date

update_datetime?: Date

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

onelink_idx?: number

ref_idx?: number

ref_table?: string

order_transaction_pending_idx?: number

ip?: string


//input_start
item_idx?: number

//input_end
}

//class_end
//easy_graphql_comment_start
/*
shop_cart_idx
user_idx
item_option_idx
total_count
regist_datetime
update_datetime
arrival_source
app_open_pid
app_open_campaign
app_open_adset
onelink_idx
ref_idx
ref_table
order_transaction_pending_idx
shop_cart_status
ip

*/
//easy_graphql_comment_end



export class ShopCartLog {
shop_cart_idx?: number

user_idx?: number

item_option_idx?: number

total_count?: number

regist_datetime?: Date

update_datetime?: Date

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

onelink_idx?: number

ref_idx?: number

ref_table?: string

order_transaction_pending_idx?: number

shop_cart_status?: string

ip?: string

}

export class SelectShopCartLogListReturn {
count?: number;

rows?: ShopCartLog[];
}


export class InputShopCartLog{
shop_cart_idx?: number

user_idx?: number

item_option_idx?: number

total_count?: number

regist_datetime?: Date

update_datetime?: Date

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

onelink_idx?: number

ref_idx?: number

ref_table?: string

order_transaction_pending_idx?: number

shop_cart_status?: string

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
shop_section_idx
shop_section_title
shop_section_img_url
is_open
display_order
shop_section_type
shop_section_mode
shop_section_value
arrival_source
description
start_datetime
end_datetime
regist_datetime
update_datetime
section_impression_count
section_view_count
create_user_idx
is_company_section

*/
//easy_graphql_comment_end



export class ShopSection {
shop_section_idx?: number

shop_section_title?: string

shop_section_img_url?: string

is_open?: boolean

display_order?: number

shop_section_type?: string

shop_section_mode?: string

shop_section_value?: string

arrival_source?: string

description?: string

start_datetime?: Date

end_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

section_impression_count?: number

section_view_count?: number

create_user_idx?: number

is_company_section?: boolean

//start
shop_section_items?: ShopSectionItem[];

create_user?: User


//end
}

export class SelectShopSectionListReturn {
count?: number;

rows?: ShopSection[];
}


export class InputShopSection{
shop_section_idx?: number

shop_section_title?: string

shop_section_img_url?: string

is_open?: boolean

display_order?: number

shop_section_type?: string

shop_section_mode?: string

shop_section_value?: string

arrival_source?: string

description?: string

start_datetime?: Date

end_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

section_impression_count?: number

section_view_count?: number

create_user_idx?: number

is_company_section?: boolean


//input_start
shop_section_items?: InputShopSectionItem[];
//input_end
}

//class_end
//easy_graphql_comment_start
/*
shop_section_group_idx
group_type
shop_section_idx
display_order
is_open
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class ShopSectionGroup {
shop_section_group_idx?: number

group_type?: string

shop_section_idx?: number

display_order?: number

is_open?: boolean

regist_datetime?: Date

update_datetime?: Date

//start
shop_section?: ShopSection;
//end
}

export class SelectShopSectionGroupListReturn {
count?: number;

rows?: ShopSectionGroup[];
}


export class InputShopSectionGroup{
shop_section_group_idx?: number

group_type?: string

shop_section_idx?: number

display_order?: number

is_open?: boolean

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
shop_section_item_idx
shop_section_idx
item_idx
post_idx
display_order
is_open
img_url
regist_datetime
update_datetime
shop_section_impression_count
shop_section_view_count

*/
//easy_graphql_comment_end




export class ShopSectionItem {
shop_section_item_idx?: number

shop_section_idx?: number

item_idx?: number

post_idx?: number

display_order?: number

is_open?: boolean

img_url?: string

regist_datetime?: Date

update_datetime?: Date

shop_section_impression_count?: number

shop_section_view_count?: number

//start
item?: Item

post?: Post

shop_section?: ShopSection
//end
}

export class SelectShopSectionItemListReturn {
count?: number;

rows?: ShopSectionItem[];
}


export class InputShopSectionItem{
shop_section_item_idx?: number

shop_section_idx?: number

item_idx?: number

post_idx?: number

display_order?: number

is_open?: boolean

img_url?: string

regist_datetime?: Date

update_datetime?: Date

shop_section_impression_count?: number

shop_section_view_count?: number


}

//class_end
//easy_graphql_comment_start
/*
shop_wish_idx
user_idx
item_idx
regist_datetime
ip

*/
//easy_graphql_comment_end



export class ShopWish {
shop_wish_idx?: number

user_idx?: number

item_idx?: number

regist_datetime?: Date

ip?: string

//start
item?: Item

user?: User
//end
}

export class SelectShopWishListReturn {
count?: number;

rows?: ShopWish[];
}


export class InputShopWish{
shop_wish_idx?: number

user_idx?: number

item_idx?: number

regist_datetime?: Date

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
shop_wish_idx
user_idx
item_idx
regist_datetime
ip

*/
//easy_graphql_comment_end



export class ShopWishLog {
shop_wish_idx?: number

user_idx?: number

item_idx?: number

regist_datetime?: Date

ip?: string

}

export class SelectShopWishLogListReturn {
count?: number;

rows?: ShopWishLog[];
}


export class InputShopWishLog{
shop_wish_idx?: number

user_idx?: number

item_idx?: number

regist_datetime?: Date

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
sms_idx
user_idx
title
phone
message
sms_ref_idx
sms_ref_table
regist_datetime
update_datetime
send_result_text
send_result

*/
//easy_graphql_comment_end

export class Sms {
sms_idx?: number

user_idx?: number

title?: string

phone?: string

message?: string

sms_ref_idx?: number

sms_ref_table?: string

regist_datetime?: Date

update_datetime?: Date

send_result_text?: string

send_result?: boolean

//start
user?: User
//end
}

export class SelectSmsListReturn {
count?: number;

rows?: Sms[];
}


export class InputSms{
sms_idx?: number

user_idx?: number

title?: string

phone?: string

message?: string

sms_ref_idx?: number

sms_ref_table?: string

regist_datetime?: Date

update_datetime?: Date

send_result_text?: string

send_result?: boolean


}

//class_end
//easy_graphql_comment_start
/*
store_idx
user_idx
name
title
description
thumbnail_url
bookmark_count
type
update_user_idx
size
display_order
is_open
regist_datetime
update_datetime
is_on_live
live_url

*/
//easy_graphql_comment_end



export class Store {
store_idx?: number

user_idx?: number

name?: string

title?: string

description?: string

thumbnail_url?: string

bookmark_count?: number

type?: string

update_user_idx?: number

size?: string

display_order?: number

is_open?: boolean

regist_datetime?: Date

update_datetime?: Date

is_on_live?: boolean

live_url?: string

//start
item_list?: Item[]

store_bookmark?: StoreBookmark

tag_list?: string

item_count?: number

post_count?: number

review_count?: number

user?: User

update_user?: User


post_list?: Post[]


post_tv_list?: PostTv[]


//end
}

export class SelectStoreListReturn {
count?: number;

rows?: Store[];
}


export class InputStore{
store_idx?: number

user_idx?: number

name?: string

title?: string

description?: string

thumbnail_url?: string

bookmark_count?: number

type?: string

update_user_idx?: number

size?: string

display_order?: number

is_open?: boolean

regist_datetime?: Date

update_datetime?: Date

is_on_live?: boolean

live_url?: string


//input_start
tag_list?: string
//input_end
}

//class_end
//easy_graphql_comment_start
/*
store_bookmark_idx
user_idx
store_idx
regist_datetime

*/
//easy_graphql_comment_end



export class StoreBookmark {
store_bookmark_idx?: number

user_idx?: number

store_idx?: number

regist_datetime?: Date

//start
store?: Store
//end
}

export class SelectStoreBookmarkListReturn {
count?: number;

rows?: StoreBookmark[];
}


export class InputStoreBookmark{
store_bookmark_idx?: number

user_idx?: number

store_idx?: number

regist_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
subscribe_card_info_idx
user_idx
card_number
card_code
card_name
customer_uid
is_deleted
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class SubscribeCardInfo {
subscribe_card_info_idx?: number

user_idx?: number

card_number?: string

card_code?: string

card_name?: string

customer_uid?: string

is_deleted?: boolean

regist_datetime?: Date

update_datetime?: Date

}

export class SelectSubscribeCardInfoListReturn {
count?: number;

rows?: SubscribeCardInfo[];
}


export class InputSubscribeCardInfo{
subscribe_card_info_idx?: number

user_idx?: number

card_number?: string

card_code?: string

card_name?: string

customer_uid?: string

is_deleted?: boolean

regist_datetime?: Date

update_datetime?: Date


}

//class_end


export class InputIssueBillingKeyInfo {
subscribe_card_info_idx?: number

user_idx?: number

card_number?: string

expiry?: string

pwd_2digit?: string

birth?: string
}

export class IssuedBillingKeyInfo {
card_code?: string;

card_number?: string;

customer_uid?: string;
}
//easy_graphql_comment_start
/*
sync_item_stock_idx
item_idx
company_idx
url
result
last_check_datetime
regist_datetime

*/
//easy_graphql_comment_end



export class SyncItemStock {
sync_item_stock_idx?: number

item_idx?: number

company_idx?: number

url?: string

result?: string

last_check_datetime?: Date

regist_datetime?: Date

}

export class SelectSyncItemStockListReturn {
count?: number;

rows?: SyncItemStock[];
}


export class InputSyncItemStock{
sync_item_stock_idx?: number

item_idx?: number

company_idx?: number

url?: string

result?: string

last_check_datetime?: Date

regist_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
sync_item_stock_history_idx
user_idx
complete_datetime
regist_datetime
update_datetime
result
error
message

*/
//easy_graphql_comment_end

export class SyncItemStockHistory {
sync_item_stock_history_idx?: number

user_idx?: number

complete_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

result?: string

error?: string

message?: string

}

export class SelectSyncItemStockHistoryListReturn {
count?: number;

rows?: SyncItemStockHistory[];
}


export class InputSyncItemStockHistory{
sync_item_stock_history_idx?: number

user_idx?: number

complete_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

result?: string

error?: string

message?: string


}

//class_end

// { goods_name: '여름마약잠옷 (단품)/남성 상의/블랙-프리',
// option_name: '남성 상의/블랙-프리',
// barcode: '979254122120',
// stock_quantity: '5',
// insert_date: '20200722171407',
// update_date: '20200723151807' }

//easy_graphql_comment_start
/*
order_num
order_turn
invoice_num
order_class_name
good_name
good_option
quantity
insert_date
delivery_date
cancel_date
search_date
ignore_fetch
shop_name

*/
//easy_graphql_comment_end


export class SyncLogisticsLog {
order_num?: string

order_turn?: string

invoice_num?: string

order_class_name?: string

good_name?: string

good_option?: string

quantity?: string

insert_date?: string

delivery_date?: string

cancel_date?: string

search_date?: string

ignore_fetch?: boolean

shop_name?: string

}

export class SelectSyncLogisticsLogListReturn {
count?: number;

rows?: SyncLogisticsLog[];
}


export class InputSyncLogisticsLog{
order_num?: string

order_turn?: string

invoice_num?: string

order_class_name?: string

good_name?: string

good_option?: string

quantity?: string

insert_date?: string

delivery_date?: string

cancel_date?: string

search_date?: string

ignore_fetch?: boolean

shop_name?: string


}

//class_end

// { order_num: '202008191003',
// order_turn: '4116816922',
// invoice_num: '347477275256',
// order_class_name: '배송완료',
// good_name: '979254222385',
// good_option: '',
// barcode: [ '979254222385' ],
// quantity: '1',
// receiver_name: 'M0xhdGZvMjdtaDd0RnA0N2JUZHEzZz09',
// receiver_contact: 'ZWFLVFUrMHBrOGw5TVVjbHRwOWRmZz09',
// receiver_mobile: 'ZWFLVFUrMHBrOGw5TVVjbHRwOWRmZz09',
// addr:'UllDdE1vZGlMOENYbmhPU2hscnRGUnZWQnp1NzRMdGE1SERndHVtcjBsVGo0TExZZGFUblJZRFdIeEo4WUpRekMzRUdrcGdUZ0ozdU82YVFOOGdWUExETXlqTS9QaTR4d1NtR3ZNM1l6WDg9',
// delivery_memo: '',
// insert_date: '20200819103145',
// delivery_date: '20200819142751',
// cancel_date: '' }



//easy_graphql_comment_start
/*
sync_odd_concept_tag_idx
content_table
content_idx
response
complete_datetime
regist_datetime

*/
//easy_graphql_comment_end



export class SyncOddConceptTag {
sync_odd_concept_tag_idx?: number

content_table?: string

content_idx?: number

response?: string

complete_datetime?: Date

regist_datetime?: Date

}

export class SelectSyncOddConceptTagListReturn {
count?: number;

rows?: SyncOddConceptTag[];
}


export class InputSyncOddConceptTag{
sync_odd_concept_tag_idx?: number

content_table?: string

content_idx?: number

response?: string

complete_datetime?: Date

regist_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
tag_idx
tag_string
tag_origin_idx
tag_content_count
tag_post_count
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class Tag {
tag_idx?: number

tag_string?: string

tag_origin_idx?: string

tag_content_count?: number

tag_post_count?: number

regist_datetime?: Date

update_datetime?: Date

//start
tag_content_list?: TagContent[];
//end
}

export class SelectTagListReturn {
count?: number;

rows?: Tag[];
}


export class InputTag{
tag_idx?: number

tag_string?: string

tag_origin_idx?: string

tag_content_count?: number

tag_post_count?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
tag_idx
content_table
content_idx
tag_content_type
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class TagContent {
tag_idx?: number

content_table?: string

content_idx?: number

tag_content_type?: number

regist_datetime?: Date

update_datetime?: Date

//start
tag?: Tag

post_count?: number
//end
}

export class SelectTagContentListReturn {
count?: number;

rows?: TagContent[];
}


export class InputTagContent{
tag_idx?: number

content_table?: string

content_idx?: number

tag_content_type?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
tag_idx
parent_tag_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class TagGroup {
tag_idx?: number

parent_tag_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
tag?: Tag

parent_tag?: Tag
//end
}

export class SelectTagGroupListReturn {
count?: number;

rows?: TagGroup[];
}


export class InputTagGroup{
tag_idx?: number

parent_tag_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
tag_string
post_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class TagPost {
tag_string?: string

post_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
post?: Post

tag?: Tag
//end
}

export class SelectTagPostListReturn {
count?: number;

rows?: TagPost[];
}


export class InputTagPost{
tag_string?: string

post_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
temp_file_idx
file_type
file_url
regist_datetime
update_datetime
file_delete_datetime

*/
//easy_graphql_comment_end



export class TempFile {
temp_file_idx?: number

file_type?: string

file_url?: string

regist_datetime?: Date

update_datetime?: Date

file_delete_datetime?: Date

}

export class SelectTempFileListReturn {
count?: number;

rows?: TempFile[];
}


export class InputTempFile{
temp_file_idx?: number

file_type?: string

file_url?: string

regist_datetime?: Date

update_datetime?: Date

file_delete_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
item_idx
item_status
is_open

*/
//easy_graphql_comment_end



export class TempItem {
item_idx?: number

item_status?: number

is_open?: number

}

export class SelectTempItemListReturn {
count?: number;

rows?: TempItem[];
}


export class InputTempItem{
item_idx?: number

item_status?: number

is_open?: number


}

//class_end
//easy_graphql_comment_start
/*
temp_order_idx
sales_channel_id
sales_channel_idx
temp_order_status
sales_channel_order_number
sales_channel_order_number2
order_item_name
item_name
time
receiver_name
receiver_phone
receiver_phone_backup
order_user_name
order_user_phone
address
post_code
count
sales_channel_item_code
sales_channel_item_code_idx
request
price
temp_order_error
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class TempOrder {
temp_order_idx?: number

sales_channel_id?: string

sales_channel_idx?: number

temp_order_status?: number

sales_channel_order_number?: string

sales_channel_order_number2?: string

order_item_name?: string

item_name?: string

time?: number

receiver_name?: string

receiver_phone?: string

receiver_phone_backup?: string

order_user_name?: string

order_user_phone?: string

address?: string

post_code?: string

count?: number

sales_channel_item_code?: string

sales_channel_item_code_idx?: number

request?: string

price?: number

temp_order_error?: string

regist_datetime?: Date

update_datetime?: Date

//start
sales_channel_item_code_object?: SalesChannelItemCode


sales_channel?: SalesChannel
//end
}

export class SelectTempOrderListReturn {
count?: number;

rows?: TempOrder[];
}


export class InputTempOrder{
temp_order_idx?: number

sales_channel_id?: string

sales_channel_idx?: number

temp_order_status?: number

sales_channel_order_number?: string

sales_channel_order_number2?: string

order_item_name?: string

item_name?: string

time?: number

receiver_name?: string

receiver_phone?: string

receiver_phone_backup?: string

order_user_name?: string

order_user_phone?: string

address?: string

post_code?: string

count?: number

sales_channel_item_code?: string

sales_channel_item_code_idx?: number

request?: string

price?: number

temp_order_error?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end

export class InputTempOrderList {

count?: number;

rows?: InputTempOrder[];
}



export class InsertTempOrderResult {

total?: number;

already?: number;

no_item?: number;

possible?: number;

message?: string

}

/*
address: "부산광역시 해운대구 해운대로 428  (우동, 동부올림픽타운) 111동 704호"
count: 1
item_name: "펀샵 에디터도 놀란잠옷"
order_user_name: "이장민"
order_user_phone: "01046037936"
post_code: undefined
price: 43800
receiver_name: "이장민"
receiver_phone: "01046037936"
regist_datetime: Fri Aug 07 2020 08:00:30 GMT+0900 (대한민국 표준시) {}
request: ""
sales_channel: "funshop"
sales_channel_item_code: "펀샵 에디터도 놀란잠옷남성 반바지 세트 FREE SIZE [스카이블루]"
sales_channel_order_number: "12950976"
sales_channel_order_number2: "4976632"
time: 1596754830000

*/
//easy_graphql_comment_start
/*
source_url
s3_url
width
height
color

*/
//easy_graphql_comment_end



export class TempResource {
source_url?: string

s3_url?: string

width?: number

height?: number

color?: string

}

export class SelectTempResourceListReturn {
count?: number;

rows?: TempResource[];
}


export class InputTempResource{
source_url?: string

s3_url?: string

width?: number

height?: number

color?: string


}

//class_end
//easy_graphql_comment_start
/*
temp_stock_idx
item_option_code
item_option_barcode
item_option_stock
item_option_name
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class TempStock {
temp_stock_idx?: number

item_option_code?: string

item_option_barcode?: number

item_option_stock?: number

item_option_name?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectTempStockListReturn {
count?: number;

rows?: TempStock[];
}


export class InputTempStock{
temp_stock_idx?: number

item_option_code?: string

item_option_barcode?: number

item_option_stock?: number

item_option_name?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
temp_user_mapping_idx
user_idx
temp_user_idx
sync_datetime
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class TempUserMapping {
temp_user_mapping_idx?: number

user_idx?: number

temp_user_idx?: number

sync_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

}

export class SelectTempUserMappingListReturn {
count?: number;

rows?: TempUserMapping[];
}


export class InputTempUserMapping{
temp_user_mapping_idx?: number

user_idx?: number

temp_user_idx?: number

sync_datetime?: Date

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
topic_idx
topic_title
topic_img_url
topic_detail
topic_onoff
topic_start_datetime
topic_end_datetime
topic_click_count
topic_tag
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Topic {
topic_idx?: number

topic_title?: string

topic_img_url?: string

topic_detail?: string

topic_onoff?: boolean

topic_start_datetime?: Date

topic_end_datetime?: Date

topic_click_count?: number

topic_tag?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectTopicListReturn {
count?: number;

rows?: Topic[];
}


export class InputTopic{
topic_idx?: number

topic_title?: string

topic_img_url?: string

topic_detail?: string

topic_onoff?: boolean

topic_start_datetime?: Date

topic_end_datetime?: Date

topic_click_count?: number

topic_tag?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
file_idx
file_type
file_url
regist_datetime
update_datetime
delete_datetime
file_name
file_kind
width
height

*/
//easy_graphql_comment_end



export class UploadFile {
file_idx?: number

file_type?: string

file_url?: string

regist_datetime?: Date

update_datetime?: Date

delete_datetime?: Date

file_name?: string

file_kind?: string

width?: number

height?: number

}

export class SelectUploadFileListReturn {
count?: number;

rows?: UploadFile[];
}


export class InputUploadFile{
file_idx?: number

file_type?: string

file_url?: string

regist_datetime?: Date

update_datetime?: Date

delete_datetime?: Date

file_name?: string

file_kind?: string

width?: number

height?: number


}

//class_end
//easy_graphql_comment_start
/*
user_idx
user_object_id
login_type
source
user_id
push_count
user_pw
user_profile
total_like_count
total_post_count
total_reply_count
total_following_count
total_follower_count
social_id
access_token
social_access_token
user_name
user_email
user_phone
user_birthday
user_gender
age_range
push_alarm_onoff
ph_authen
ph_authen_datetime
user_img_url
purchase_count
total_purchase_count
wish_count
total_coupon_count
recommend_user_count
fumist_user_count
is_fumist
bank_name
bank_account
bank_owner
bank_code
login_count
last_login_datetime
accept_policy_datetime
user_droped_datetime
is_black_user
black_reason
point_balance
point_expire_soon
point_ready_balance
regist_datetime
update_datetime
transfer_datetime
install_source
complete_datetime
merge_type
target_merge_user_idx
linker_type
max_linking_count
linker_count
linking_count
user_level_icon
review_enable_count
review_write_count
is_deleted

*/
//easy_graphql_comment_end



export class OneSignalData {
user_name?: string;
last_cart_update_time?: any;
last_cart_update_item?: string;
last_cart_update_item_info?: Item;
last_wish?: ShopWish;
last_coupon?: Coupon;
last_order_time?: any;
amount_spent?: number;
birthdate?: string;
birth_year?: number;
age_range?: string;
user_type?: string;
cart_category?: string;
}

export class User {
user_idx?: number

user_object_id?: string

login_type?: 'local'|'kakao'|'facebook'|'naver'|'offline'|'agent'|'apple'|'email'|'temp'|'merged'

source?: string

user_id?: string

push_count?: number

user_pw?: string

user_profile?: string

total_like_count?: number

total_post_count?: number

total_reply_count?: number

total_following_count?: number

total_follower_count?: number

social_id?: string

access_token?: string

social_access_token?: string

user_name?: string

user_email?: string

user_phone?: string

user_birthday?: Date

user_gender?: '남'|'여'|'male'|'female'

age_range?: string

push_alarm_onoff?: number

ph_authen?: boolean

ph_authen_datetime?: Date

user_img_url?: string

purchase_count?: number

total_purchase_count?: number

wish_count?: number

total_coupon_count?: number

recommend_user_count?: number

fumist_user_count?: number

is_fumist?: number

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

login_count?: number

last_login_datetime?: Date

accept_policy_datetime?: Date

user_droped_datetime?: Date

is_black_user?: boolean

black_reason?: string

point_balance?: number

point_expire_soon?: number

point_ready_balance?: number

regist_datetime?: Date

update_datetime?: Date

transfer_datetime?: Date

install_source?: string

complete_datetime?: Date

merge_type?: string

target_merge_user_idx?: number

linker_type?: number

max_linking_count?: number

linker_count?: number

linking_count?: number

user_level_icon?: string

review_enable_count?: number

review_write_count?: number

is_deleted?: boolean

//start

post_list: Post[];


order_list: OrderTransaction[];

order_item_list: OrderItem[];

point_list: Point[];

address_book_list: AddressBook[];


card_number_list: UserCardNumber[];


role_join_list?: RoleJoin[]


company_manager_list?: CompanyManager[]

user_info?: UserInfo

user_refund_account_list: UserRefundAccount[];

one_signal_data: OneSignalData;


user_device_list: UserDevice[];


from_user_list: UserInviteHistory[];

to_user_list: UserInviteHistory[];


class_join?: ClassJoin


//end
}

export class SelectUserListReturn {
count?: number;

rows?: User[];
}


export class InputUser{
user_idx?: number

user_object_id?: string

login_type?: 'local'|'kakao'|'facebook'|'naver'|'offline'|'agent'|'apple'|'email'|'temp'|'merged'

source?: string

user_id?: string

push_count?: number

user_pw?: string

user_profile?: string

total_like_count?: number

total_post_count?: number

total_reply_count?: number

total_following_count?: number

total_follower_count?: number

social_id?: string

access_token?: string

social_access_token?: string

user_name?: string

user_email?: string

user_phone?: string

user_birthday?: Date

user_gender?: '남'|'여'|'male'|'female'

age_range?: string

push_alarm_onoff?: number

ph_authen?: boolean

ph_authen_datetime?: Date

user_img_url?: string

purchase_count?: number

total_purchase_count?: number

wish_count?: number

total_coupon_count?: number

recommend_user_count?: number

fumist_user_count?: number

is_fumist?: number

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

login_count?: number

last_login_datetime?: Date

accept_policy_datetime?: Date

user_droped_datetime?: Date

is_black_user?: boolean

black_reason?: string

point_balance?: number

point_expire_soon?: number

point_ready_balance?: number

regist_datetime?: Date

update_datetime?: Date

transfer_datetime?: Date

install_source?: string

complete_datetime?: Date

merge_type?: string

target_merge_user_idx?: number

linker_type?: number

max_linking_count?: number

linker_count?: number

linking_count?: number

user_level_icon?: string

review_enable_count?: number

review_write_count?: number

is_deleted?: boolean


}

//class_end

export class LoginTypeListReturn {
rows?: string[];
}

export class SourceListReturn {
rows?: string[];
}

export class LoginSocialReturn {
token?: string;

status?: string;

point_amount?: number
}

export class LoginEmailReturn {
token?: string;
}



export class InputMergeUser {
from_user_idx: number

to_user_idx: number
}

export class DeleteUserResult {
success?: boolean;

message?: string;
}

export class UserData {
user?: User;

review_count?: number;

select_coupon_info?: SelectCouponInfo;
}


export class UserItemInteractionData {

event_id?: string;

event_type?: string;

event_value?: number;

source?: string;

location?: string;

user_id?: string;

item_id?: string;

regist_datetime?: Date

}


export class LoginSocialProxyReturn {
app_token?: string;

web_token?: string;

token?: string;

//sign_up / login
status?: string;

}

//easy_graphql_comment_start
/*
user_attendance_idx
user_idx
target_date
regist_datetime
update_datetime
reword_status
reword_check_datetime
memo
ip

*/
//easy_graphql_comment_end



export class UserAttendance {
user_attendance_idx?: number

user_idx?: number

target_date?: string

regist_datetime?: Date

update_datetime?: Date

reword_status?: number

reword_check_datetime?: Date

memo?: string

ip?: string

//start
user?: User
//end
}

export class SelectUserAttendanceListReturn {
count?: number;

rows?: UserAttendance[];
}


export class InputUserAttendance{
user_attendance_idx?: number

user_idx?: number

target_date?: string

regist_datetime?: Date

update_datetime?: Date

reword_status?: number

reword_check_datetime?: Date

memo?: string

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
user_attendance_idx
user_idx
target_date
regist_datetime
update_datetime
reword_status
reword_check_datetime
memo
ip

*/
//easy_graphql_comment_end



export class UserAttendanceLog {
user_attendance_idx?: number

user_idx?: number

target_date?: string

regist_datetime?: Date

update_datetime?: Date

reword_status?: number

reword_check_datetime?: Date

memo?: string

ip?: string

}

export class SelectUserAttendanceLogListReturn {
count?: number;

rows?: UserAttendanceLog[];
}


export class InputUserAttendanceLog{
user_attendance_idx?: number

user_idx?: number

target_date?: string

regist_datetime?: Date

update_datetime?: Date

reword_status?: number

reword_check_datetime?: Date

memo?: string

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
user_black_idx
user_idx
ip
memo
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserBlack {
user_black_idx?: number

user_idx?: number

ip?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserBlackListReturn {
count?: number;

rows?: UserBlack[];
}


export class InputUserBlack{
user_black_idx?: number

user_idx?: number

ip?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_idx
block_user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class UserBlock {
user_idx?: number

block_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

//start
user?: User


block_user?: Post
//end
}

export class SelectUserBlockListReturn {
count?: number;

rows?: UserBlock[];
}


export class InputUserBlock{
user_idx?: number

block_user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_card_number_idx
user_idx
card_number
card_code
seq
card_company
card_owner
card_type
expire_date
hide
regist_datetime
update_datetime

*/
//easy_graphql_comment_end

export class UserCardNumber {
user_card_number_idx?: number

user_idx?: number

card_number?: string

card_code?: string

seq?: string

card_company?: string

card_owner?: string

card_type?: string

expire_date?: string

hide?: boolean

regist_datetime?: Date

update_datetime?: Date

//start
user?: User
//end
}

export class SelectUserCardNumberListReturn {
count?: number;

rows?: UserCardNumber[];
}


export class InputUserCardNumber{
user_card_number_idx?: number

user_idx?: number

card_number?: string

card_code?: string

seq?: string

card_company?: string

card_owner?: string

card_type?: string

expire_date?: string

hide?: boolean

regist_datetime?: Date

update_datetime?: Date


}

//class_end

export class InputPayCard {

user_idx?: number

order_transaction_idx?: number

order_transcation_title?: string

total_user_pay_price?: number

order_user_name?: string

order_user_phone?: string

card_quota?: string

card_number?: string

expire_date?: string

}



//easy_graphql_comment_start
/*
object_id
card_number
expire_date
seq
card_code

*/
//easy_graphql_comment_end



export class UserCardNumberLegacy {
object_id?: string

card_number?: string

expire_date?: string

seq?: string

card_code?: string

}

export class SelectUserCardNumberLegacyListReturn {
count?: number;

rows?: UserCardNumberLegacy[];
}


export class InputUserCardNumberLegacy{
object_id?: string

card_number?: string

expire_date?: string

seq?: string

card_code?: string


}

//class_end
//easy_graphql_comment_start
/*
user_check_in_out_idx
user_idx
user_device_idx
check_in_datetime
check_out_datetime
auto_check_out_datetime
check_out_type
check_result_datetime

*/
//easy_graphql_comment_end



export class UserCheckInOut {
user_check_in_out_idx?: number

user_idx?: number

user_device_idx?: number

check_in_datetime?: Date

check_out_datetime?: Date

auto_check_out_datetime?: Date

check_out_type?: boolean

check_result_datetime?: Date

}

export class SelectUserCheckInOutListReturn {
count?: number;

rows?: UserCheckInOut[];
}


export class InputUserCheckInOut{
user_check_in_out_idx?: number

user_idx?: number

user_device_idx?: number

check_in_datetime?: Date

check_out_datetime?: Date

auto_check_out_datetime?: Date

check_out_type?: boolean

check_result_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_check_in_out_idx
user_idx
user_device_idx
check_in_datetime
check_out_datetime
auto_check_out_datetime
check_out_type
check_result_datetime

*/
//easy_graphql_comment_end



export class UserCheckInOutLog {
user_check_in_out_idx?: number

user_idx?: number

user_device_idx?: number

check_in_datetime?: Date

check_out_datetime?: Date

auto_check_out_datetime?: Date

check_out_type?: boolean

check_result_datetime?: Date

}

export class SelectUserCheckInOutLogListReturn {
count?: number;

rows?: UserCheckInOutLog[];
}


export class InputUserCheckInOutLog{
user_check_in_out_idx?: number

user_idx?: number

user_device_idx?: number

check_in_datetime?: Date

check_out_datetime?: Date

auto_check_out_datetime?: Date

check_out_type?: boolean

check_result_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_idx
user_object_id
login_type
source
user_id
push_count
user_pw
user_profile
total_like_count
total_post_count
total_reply_count
total_following_count
total_follower_count
social_id
access_token
social_access_token
user_name
user_email
user_phone
user_birthday
user_gender
age_range
push_alarm_onoff
ph_authen
ph_authen_datetime
user_img_url
purchase_count
total_purchase_count
wish_count
total_coupon_count
recommend_user_count
fumist_user_count
is_fumist
bank_name
bank_account
bank_owner
bank_code
login_count
last_login_datetime
accept_policy_datetime
user_droped_datetime
is_black_user
black_reason
point_balance
point_expire_soon
point_ready_balance
regist_datetime
update_datetime
transfer_datetime
install_source
complete_datetime
merge_type
target_merge_user_idx
linker_type
max_linking_count
linker_count
linking_count
user_level_icon
review_enable_count
review_write_count
is_deleted

*/
//easy_graphql_comment_end



export class UserDeleted {
user_idx?: number

user_object_id?: string

login_type?: 'local'|'kakao'|'facebook'|'naver'|'offline'|'agent'|'apple'|'temp'

source?: string

user_id?: string

push_count?: number

user_pw?: string

user_profile?: string

total_like_count?: number

total_post_count?: number

total_reply_count?: number

total_following_count?: number

total_follower_count?: number

social_id?: string

access_token?: string

social_access_token?: string

user_name?: string

user_email?: string

user_phone?: string

user_birthday?: Date

user_gender?: '남'|'여'|'male'|'female'

age_range?: string

push_alarm_onoff?: number

ph_authen?: boolean

ph_authen_datetime?: Date

user_img_url?: string

purchase_count?: number

total_purchase_count?: number

wish_count?: number

total_coupon_count?: number

recommend_user_count?: number

fumist_user_count?: number

is_fumist?: number

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

login_count?: number

last_login_datetime?: Date

accept_policy_datetime?: Date

user_droped_datetime?: Date

is_black_user?: boolean

black_reason?: string

point_balance?: number

point_expire_soon?: number

point_ready_balance?: number

regist_datetime?: Date

update_datetime?: Date

transfer_datetime?: Date

install_source?: string

complete_datetime?: Date

merge_type?: string

target_merge_user_idx?: number

linker_type?: number

max_linking_count?: number

linker_count?: number

linking_count?: number

user_level_icon?: string

review_enable_count?: number

review_write_count?: number

is_deleted?: boolean

}

export class SelectUserDeletedListReturn {
count?: number;

rows?: UserDeleted[];
}


export class InputUserDeleted{
user_idx?: number

user_object_id?: string

login_type?: 'local'|'kakao'|'facebook'|'naver'|'offline'|'agent'|'apple'|'temp'

source?: string

user_id?: string

push_count?: number

user_pw?: string

user_profile?: string

total_like_count?: number

total_post_count?: number

total_reply_count?: number

total_following_count?: number

total_follower_count?: number

social_id?: string

access_token?: string

social_access_token?: string

user_name?: string

user_email?: string

user_phone?: string

user_birthday?: Date

user_gender?: '남'|'여'|'male'|'female'

age_range?: string

push_alarm_onoff?: number

ph_authen?: boolean

ph_authen_datetime?: Date

user_img_url?: string

purchase_count?: number

total_purchase_count?: number

wish_count?: number

total_coupon_count?: number

recommend_user_count?: number

fumist_user_count?: number

is_fumist?: number

bank_name?: string

bank_account?: string

bank_owner?: string

bank_code?: string

login_count?: number

last_login_datetime?: Date

accept_policy_datetime?: Date

user_droped_datetime?: Date

is_black_user?: boolean

black_reason?: string

point_balance?: number

point_expire_soon?: number

point_ready_balance?: number

regist_datetime?: Date

update_datetime?: Date

transfer_datetime?: Date

install_source?: string

complete_datetime?: Date

merge_type?: string

target_merge_user_idx?: number

linker_type?: number

max_linking_count?: number

linker_count?: number

linking_count?: number

user_level_icon?: string

review_enable_count?: number

review_write_count?: number

is_deleted?: boolean


}

//class_end
//easy_graphql_comment_start
/*
user_device_idx
user_idx
player_id
uuid
platform
os
name
regist_datetime
update_datetime
app_build
app_version
adid
idfa
portal_version
ip
screen_width
screen_height
pixel_ratio

*/
//easy_graphql_comment_end



export class UserDevice {
user_device_idx?: number

user_idx?: number

player_id?: string

uuid?: string

platform?: string

os?: string

name?: string

regist_datetime?: Date

update_datetime?: Date

app_build?: string

app_version?: string

adid?: string

idfa?: string

portal_version?: string

ip?: string

screen_width?: number

screen_height?: number

pixel_ratio?: number

}

export class SelectUserDeviceListReturn {
count?: number;

rows?: UserDevice[];
}


export class InputUserDevice{
user_device_idx?: number

user_idx?: number

player_id?: string

uuid?: string

platform?: string

os?: string

name?: string

regist_datetime?: Date

update_datetime?: Date

app_build?: string

app_version?: string

adid?: string

idfa?: string

portal_version?: string

ip?: string

screen_width?: number

screen_height?: number

pixel_ratio?: number


}

//class_end
//easy_graphql_comment_start
/*
user_device_idx
user_idx
player_id
uuid
platform
os
name
regist_datetime
update_datetime
app_build
app_version
adid
idfa
portal_version
ip
screen_width
screen_height
pixel_ratio

*/
//easy_graphql_comment_end



export class UserDeviceLog {
user_device_idx?: number

user_idx?: number

player_id?: string

uuid?: string

platform?: string

os?: string

name?: string

regist_datetime?: Date

update_datetime?: Date

app_build?: string

app_version?: string

adid?: string

idfa?: string

portal_version?: string

ip?: string

screen_width?: number

screen_height?: number

pixel_ratio?: number

}

export class SelectUserDeviceLogListReturn {
count?: number;

rows?: UserDeviceLog[];
}


export class InputUserDeviceLog{
user_device_idx?: number

user_idx?: number

player_id?: string

uuid?: string

platform?: string

os?: string

name?: string

regist_datetime?: Date

update_datetime?: Date

app_build?: string

app_version?: string

adid?: string

idfa?: string

portal_version?: string

ip?: string

screen_width?: number

screen_height?: number

pixel_ratio?: number


}

//class_end
//easy_graphql_comment_start
/*
user_feed_idx
user_idx
post_idx
feed_type
is_deleted
view_datetime
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserFeed {
user_feed_idx?: number

user_idx?: number

post_idx?: number

feed_type?: string

is_deleted?: boolean

view_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserFeedListReturn {
count?: number;

rows?: UserFeed[];
}


export class InputUserFeed{
user_feed_idx?: number

user_idx?: number

post_idx?: number

feed_type?: string

is_deleted?: boolean

view_datetime?: Date

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_idx
height
top_size
top_size_gender
bottom_size
regist_datetime
update_datetime

*/
//easy_graphql_comment_end


export class UserInfo {
user_idx?: number

height?: number

top_size?: number

top_size_gender?: string

bottom_size?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserInfoListReturn {
count?: number;

rows?: UserInfo[];
}


export class InputUserInfo{
user_idx?: number

height?: number

top_size?: number

top_size_gender?: string

bottom_size?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_install_idx
user_idx
af_status
af_message
user_device_idx
platform
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserInstall {
user_install_idx?: number

user_idx?: number

af_status?: string

af_message?: string

user_device_idx?: number

platform?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserInstallListReturn {
count?: number;

rows?: UserInstall[];
}


export class InputUserInstall{
user_install_idx?: number

user_idx?: number

af_status?: string

af_message?: string

user_device_idx?: number

platform?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_invite_device_idx
user_idx
platform
uuid
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserInviteDevice {
user_invite_device_idx?: number

user_idx?: number

platform?: string

uuid?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserInviteDeviceListReturn {
count?: number;

rows?: UserInviteDevice[];
}


export class InputUserInviteDevice{
user_invite_device_idx?: number

user_idx?: number

platform?: string

uuid?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_invite_history_idx
app_install_idx
app_open_idx
from_user_idx
to_user_idx
invite_status
invite_result
regist_datetime
update_datetime
complete_datetime
ip

*/
//easy_graphql_comment_end



export class UserInviteHistory {
user_invite_history_idx?: number

app_install_idx?: number

app_open_idx?: number

from_user_idx?: number

to_user_idx?: number

invite_status?: string

invite_result?: string

regist_datetime?: Date

update_datetime?: Date

complete_datetime?: Date

ip?: string

//start
to_user?: User

from_user?: User
//end
}

export class SelectUserInviteHistoryListReturn {
count?: number;

rows?: UserInviteHistory[];
}


export class InputUserInviteHistory{
user_invite_history_idx?: number

app_install_idx?: number

app_open_idx?: number

from_user_idx?: number

to_user_idx?: number

invite_status?: string

invite_result?: string

regist_datetime?: Date

update_datetime?: Date

complete_datetime?: Date

ip?: string


}

//class_end
//easy_graphql_comment_start
/*
user_idx
current_rank
last_rank
total_count
differ_rank
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserInviteRank {
user_idx?: number

current_rank?: number

last_rank?: number

total_count?: number

differ_rank?: number

regist_datetime?: Date

update_datetime?: Date

//start
user?: User
//end
}

export class SelectUserInviteRankListReturn {
count?: number;

rows?: UserInviteRank[];
}


export class InputUserInviteRank{
user_idx?: number

current_rank?: number

last_rank?: number

total_count?: number

differ_rank?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_invite_request
from_user_idx
to_user_idx
ip
result
memo
user_invite_history_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserInviteRequest {
user_invite_request?: number

from_user_idx?: number

to_user_idx?: number

ip?: string

result?: string

memo?: string

user_invite_history_idx?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserInviteRequestListReturn {
count?: number;

rows?: UserInviteRequest[];
}


export class InputUserInviteRequest{
user_invite_request?: number

from_user_idx?: number

to_user_idx?: number

ip?: string

result?: string

memo?: string

user_invite_history_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_item_recommend_idx
recommend_key
recommend_key_idx
user_idx
item_idx
score
view_count
recommend_id
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserItemRecommend {
user_item_recommend_idx?: number

recommend_key?: string

recommend_key_idx?: number

user_idx?: number

item_idx?: number

score?: number

view_count?: number

recommend_id?: string

regist_datetime?: Date

update_datetime?: Date

//start
item?: Item
//end
}

export class SelectUserItemRecommendListReturn {
count?: number;

rows?: UserItemRecommend[];
}


export class InputUserItemRecommend{
user_item_recommend_idx?: number

recommend_key?: string

recommend_key_idx?: number

user_idx?: number

item_idx?: number

score?: number

view_count?: number

recommend_id?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_item_view_idx
user_idx
item_idx
event_type
view_count
arrival_source
app_open_pid
app_open_campaign
app_open_adset
regist_datetime
update_datetime
item_view_datetime
onelink_idx
event_datetime

*/
//easy_graphql_comment_end



export class UserItemView {
user_item_view_idx?: number

user_idx?: number

item_idx?: number

event_type?: number

view_count?: number

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

regist_datetime?: Date

update_datetime?: Date

item_view_datetime?: Date

onelink_idx?: number

event_datetime?: Date

//start
item?: Item
//end
}

export class SelectUserItemViewListReturn {
count?: number;

rows?: UserItemView[];
}


export class InputUserItemView{
user_item_view_idx?: number

user_idx?: number

item_idx?: number

event_type?: number

view_count?: number

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

regist_datetime?: Date

update_datetime?: Date

item_view_datetime?: Date

onelink_idx?: number

event_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_item_view_log_idx
fb_key
user_idx
item_idx
event_type
view_count
arrival_source
app_open_pid
app_open_campaign
app_open_adset
regist_datetime
update_datetime
item_view_datetime
event_datetime

*/
//easy_graphql_comment_end



export class UserItemViewLog {
user_item_view_log_idx?: number

fb_key?: string

user_idx?: number

item_idx?: number

event_type?: number

view_count?: number

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

regist_datetime?: Date

update_datetime?: Date

item_view_datetime?: Date

event_datetime?: Date

}

export class SelectUserItemViewLogListReturn {
count?: number;

rows?: UserItemViewLog[];
}


export class InputUserItemViewLog{
user_item_view_log_idx?: number

fb_key?: string

user_idx?: number

item_idx?: number

event_type?: number

view_count?: number

arrival_source?: string

app_open_pid?: string

app_open_campaign?: string

app_open_adset?: string

regist_datetime?: Date

update_datetime?: Date

item_view_datetime?: Date

event_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_link_idx
from_user_idx
to_user_idx
link_force
link_type
memo
regist_datetime
update_datetime
effect_count

*/
//easy_graphql_comment_end



export class UserLink {
user_link_idx?: number

from_user_idx?: number

to_user_idx?: number

link_force?: number

link_type?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

effect_count?: number

//start
to_user?: User

from_user?: User
//end
}

export class SelectUserLinkListReturn {
count?: number;

rows?: UserLink[];
}


export class InputUserLink{
user_link_idx?: number

from_user_idx?: number

to_user_idx?: number

link_force?: number

link_type?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

effect_count?: number


}

//class_end
//easy_graphql_comment_start
/*
user_link_idx
from_user_idx
to_user_idx
link_force
link_type
memo
regist_datetime
update_datetime
effect_count

*/
//easy_graphql_comment_end



export class UserLinkHistory {
user_link_idx?: number

from_user_idx?: number

to_user_idx?: number

link_force?: number

link_type?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

effect_count?: number

}

export class SelectUserLinkHistoryListReturn {
count?: number;

rows?: UserLinkHistory[];
}


export class InputUserLinkHistory{
user_link_idx?: number

from_user_idx?: number

to_user_idx?: number

link_force?: number

link_type?: string

memo?: string

regist_datetime?: Date

update_datetime?: Date

effect_count?: number


}

//class_end
//easy_graphql_comment_start
/*
user_link_request_idx
user_idx
status
memo
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserLinkRequest {
user_link_request_idx?: number

user_idx?: number

status?: number

memo?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserLinkRequestListReturn {
count?: number;

rows?: UserLinkRequest[];
}


export class InputUserLinkRequest{
user_link_request_idx?: number

user_idx?: number

status?: number

memo?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_migration_idx
from_user_idx
to_user_idx
admin_note
manager_user_idx
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserMigration {
user_migration_idx?: number

from_user_idx?: number

to_user_idx?: number

admin_note?: string

manager_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserMigrationListReturn {
count?: number;

rows?: UserMigration[];
}


export class InputUserMigration{
user_migration_idx?: number

from_user_idx?: number

to_user_idx?: number

admin_note?: string

manager_user_idx?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_migration_idx
from_user_idx
to_user_idx
admin_note
manager_user_idx
regist_datetime
update_datetime
migration_status
migration_complete_datetime

*/
//easy_graphql_comment_end



export class UserMigrationRequest {
user_migration_idx?: number

from_user_idx?: number

to_user_idx?: number

admin_note?: string

manager_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

migration_status?: number

migration_complete_datetime?: Date

}

export class SelectUserMigrationRequestListReturn {
count?: number;

rows?: UserMigrationRequest[];
}


export class InputUserMigrationRequest{
user_migration_idx?: number

from_user_idx?: number

to_user_idx?: number

admin_note?: string

manager_user_idx?: number

regist_datetime?: Date

update_datetime?: Date

migration_status?: number

migration_complete_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_idx
object_id

*/
//easy_graphql_comment_end

export class UserObject {
user_idx?: number

object_id?: string

}

export class SelectUserObjectListReturn {
count?: number;

rows?: UserObject[];
}


export class InputUserObject{
user_idx?: number

object_id?: string


}

//class_end
//easy_graphql_comment_start
/*
user_page_view_idx
user_idx
user_device_idx
url
etc
regist_datetime
update_datetime
event_datetime

*/
//easy_graphql_comment_end



export class UserPageView {
user_page_view_idx?: number

user_idx?: number

user_device_idx?: number

url?: string

etc?: string

regist_datetime?: Date

update_datetime?: Date

event_datetime?: Date

}

export class SelectUserPageViewListReturn {
count?: number;

rows?: UserPageView[];
}


export class InputUserPageView{
user_page_view_idx?: number

user_idx?: number

user_device_idx?: number

url?: string

etc?: string

regist_datetime?: Date

update_datetime?: Date

event_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_policy_agree_history_idx
user_idx
is_agree
memo
policy_type
regist_datetime
update_datetimeCopy

*/
//easy_graphql_comment_end



export class UserPolicyAgreeHistory {
user_policy_agree_history_idx?: number

user_idx?: number

is_agree?: boolean

memo?: string

policy_type?: string

regist_datetime?: Date

update_datetimeCopy?: Date

}

export class SelectUserPolicyAgreeHistoryListReturn {
count?: number;

rows?: UserPolicyAgreeHistory[];
}


export class InputUserPolicyAgreeHistory{
user_policy_agree_history_idx?: number

user_idx?: number

is_agree?: boolean

memo?: string

policy_type?: string

regist_datetime?: Date

update_datetimeCopy?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_post_view_idx
user_idx
post_idx
arrival_source
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserPostView {
user_post_view_idx?: number

user_idx?: number

post_idx?: number

arrival_source?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserPostViewListReturn {
count?: number;

rows?: UserPostView[];
}


export class InputUserPostView{
user_post_view_idx?: number

user_idx?: number

post_idx?: number

arrival_source?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_refund_account_idx
user_idx
account_number
bank_name
account_holder
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserRefundAccount {
user_refund_account_idx?: number

user_idx?: number

account_number?: string

bank_name?: string

account_holder?: string

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserRefundAccountListReturn {
count?: number;

rows?: UserRefundAccount[];
}


export class InputUserRefundAccount{
user_refund_account_idx?: number

user_idx?: number

account_number?: string

bank_name?: string

account_holder?: string

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
user_request_manager_idx
user_idx
user_name
user_depart
status
regist_datetime
update_datetime
request_type
company_name

*/
//easy_graphql_comment_end



export class UserRequestManager {
user_request_manager_idx?: number

user_idx?: number

user_name?: string

user_depart?: string

status?: 'ready'|'accepted'|'rejected'|''

regist_datetime?: Date

update_datetime?: Date

request_type?: string

company_name?: string

//start
user: User

role_join_list?: RoleJoin[]

company_list?: CompanyManager[]
//end
}

export class SelectUserRequestManagerListReturn {
count?: number;

rows?: UserRequestManager[];
}


export class InputUserRequestManager{
user_request_manager_idx?: number

user_idx?: number

user_name?: string

user_depart?: string

status?: 'ready'|'accepted'|'rejected'|''

regist_datetime?: Date

update_datetime?: Date

request_type?: string

company_name?: string


}

//class_end






export class InputUserRequestManagerData{
user_request_manager_idx?: number

user_idx?: number

user_depart?: string

status?: 'ready'|'accepted'|'rejected'|''

company_name?: string

role_list?: string[];

company_idx_list?: number[];

}


//easy_graphql_comment_start
/*
user_sales_family_info_idx
user_idx
family_name
relationship
birthday
wedding_anniversary
health_state
job
company
job_position
memo

*/
//easy_graphql_comment_end



export class UserSalesFamilyInfo {
user_sales_family_info_idx?: number

user_idx?: number

family_name?: string

relationship?: string

birthday?: Date

wedding_anniversary?: Date

health_state?: string

job?: string

company?: string

job_position?: string

memo?: string

}

export class SelectUserSalesFamilyInfoListReturn {
count?: number;

rows?: UserSalesFamilyInfo[];
}


export class InputUserSalesFamilyInfo{
user_sales_family_info_idx?: number

user_idx?: number

family_name?: string

relationship?: string

birthday?: Date

wedding_anniversary?: Date

health_state?: string

job?: string

company?: string

job_position?: string

memo?: string


}

//class_end
//easy_graphql_comment_start
/*
user_sales_info_idx
user_idx
user_name
user_phone
user_email
user_birthday
user_age
user_gender
address
religion
wedding_anniversary
hobby
personality
job
company
job_position
social_meeting
health_state
product_interest
current_using_product
use_experience_our
use_experience_other
product_image
preferred_product

*/
//easy_graphql_comment_end



export class UserSalesInfo {
user_sales_info_idx?: number

user_idx?: number

user_name?: string

user_phone?: string

user_email?: string

user_birthday?: Date

user_age?: number

user_gender?: 'male'|'female'

address?: string

religion?: string

wedding_anniversary?: Date

hobby?: string

personality?: string

job?: string

company?: string

job_position?: string

social_meeting?: string

health_state?: string

product_interest?: string

current_using_product?: string

use_experience_our?: string

use_experience_other?: string

product_image?: string

preferred_product?: string

}

export class SelectUserSalesInfoListReturn {
count?: number;

rows?: UserSalesInfo[];
}


export class InputUserSalesInfo{
user_sales_info_idx?: number

user_idx?: number

user_name?: string

user_phone?: string

user_email?: string

user_birthday?: Date

user_age?: number

user_gender?: 'male'|'female'

address?: string

religion?: string

wedding_anniversary?: Date

hobby?: string

personality?: string

job?: string

company?: string

job_position?: string

social_meeting?: string

health_state?: string

product_interest?: string

current_using_product?: string

use_experience_our?: string

use_experience_other?: string

product_image?: string

preferred_product?: string


}

//class_end
//easy_graphql_comment_start
/*
user_segment_rule_idx
title
description
query
create_user_idx
update_user_idx
last_target_count
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class UserSegmentRule {
user_segment_rule_idx?: number

title?: string

description?: string

query?: string

create_user_idx?: number

update_user_idx?: number

last_target_count?: number

regist_datetime?: Date

update_datetime?: Date

}

export class SelectUserSegmentRuleListReturn {
count?: number;

rows?: UserSegmentRule[];
}


export class InputUserSegmentRule{
user_segment_rule_idx?: number

title?: string

description?: string

query?: string

create_user_idx?: number

update_user_idx?: number

last_target_count?: number

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
verify_phone_idx
phone
user_idx
code
expire_datetime
complete_datetime
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class VerifyPhone {
verify_phone_idx?: number

phone?: string

user_idx?: number

code?: string

expire_datetime?: Date

complete_datetime?: Date

regist_datetime?: Date

update_datetime?: Date

}

export class SelectVerifyPhoneListReturn {
count?: number;

rows?: VerifyPhone[];
}


export class InputVerifyPhone{
verify_phone_idx?: number

phone?: string

user_idx?: number

code?: string

expire_datetime?: Date

complete_datetime?: Date

regist_datetime?: Date

update_datetime?: Date


}

//class_end
//easy_graphql_comment_start
/*
worker_idx
user_idx
worker_name
worker_email
jandi_email
start_work_datetime
retire_datetime
worker_profile
worker_img_url
worker_status
regist_datetime
update_datetime

*/
//easy_graphql_comment_end



export class Worker {
worker_idx?: number

user_idx?: number

worker_name?: string

worker_email?: string

jandi_email?: string

start_work_datetime?: Date

retire_datetime?: Date

worker_profile?: string

worker_img_url?: string

worker_status?: 'working'|'retired'|'absence'

regist_datetime?: Date

update_datetime?: Date

}

export class SelectWorkerListReturn {
count?: number;

rows?: Worker[];
}


export class InputWorker{
worker_idx?: number

user_idx?: number

worker_name?: string

worker_email?: string

jandi_email?: string

start_work_datetime?: Date

retire_datetime?: Date

worker_profile?: string

worker_img_url?: string

worker_status?: 'working'|'retired'|'absence'

regist_datetime?: Date

update_datetime?: Date


}

//class_end
