import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';

import { AppsflyerTrackerService } from './appsflyer-tracker.service';
import { FirebaseTrackerService } from './firebase-tracker.service';
import { FirebaseUserService } from '../firebase-user.service';
import { OrderTransaction } from '../graphql/models';
import { OnesignalTrackerService } from './onesignal-tracker.service';
import { TrakingBase } from './tracker-base';

import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FumiTrackerService } from './fumi-tracker.service';
import { OnesignalService } from '../onesignal.service';
import { AppsflyerService } from '../appsflyer.service';
import { UtilService } from '../util.service';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { ConstantService } from '../constants.service';
import { PixelTrackerService } from './pixel-tracker.service';

@Injectable({
  providedIn: 'root'
})
export class TrackerService implements TrakingBase {
  user_data = {
    user_idx: 0,
    user_device_idx: 0
  };

  isInitialized = false;
  initHolder = [];
  isInitializing = false;
  trackerList: TrakingBase[] = [];

  constructor(
    public router: Router,
    private user_service: FirebaseUserService,
    private onesignal_service: OnesignalService,
    private appsflyer_service: AppsflyerService,
    private firebase_tracker: FirebaseTrackerService,
    private appsflyer_tracker: AppsflyerTrackerService,
    private onesignal_tracker: OnesignalTrackerService,
    private fumi_tracker: FumiTrackerService,
    private pexl_tracker: PixelTrackerService,
    private utils: UtilService,
    private constant: ConstantService
  ) {
    this.trackerList.push(this.firebase_tracker);
    this.trackerList.push(this.fumi_tracker);
    this.trackerList.push(this.pexl_tracker);

    if ('web' != Capacitor.getPlatform()) {
      this.trackerList.push(this.appsflyer_tracker);
      this.trackerList.push(this.onesignal_tracker);
    }

    this.user_service.getUserDataChangeListner().subscribe((user) => {
      if (user) {
        this.user_data = { ...this.user_data, user_idx: user.user_idx };
        this.setUserIdx();
      }
    });

    this.user_service.getUserDeviceDataChangeLister().subscribe((device) => {
      if (device) {
        this.user_data = { ...this.user_data, user_device_idx: device.user_device_idx };
      }
    });
  }

  initialize(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isInitialized) {
        resolve(true);
        return;
      }
      this.initHolder.push(resolve);
      if (!this.isInitializing) {
        this.isInitializing = true;
        this.initTrackerService().then(async (result) => {
          this.user_data = {
            user_idx: await this.user_service.getUserIdx(),
            user_device_idx: await this.user_service.getUserDeviceIdx()
          };
          this.setUserIdx();

          this.isInitialized = true;
          for (let i = 0; i < this.initHolder.length; i++) {
            this.initHolder[i](true);
          }
          this.initHolder = [];
          this.isInitializing = true;

          //초기화 로그
          this.appInit(null);
        });
      }
    });
  }

  async initTrackerService() {
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].initialize());
    }

    try {
      await Promise.all(list);
    } catch (error) {
      console.error('initTrackerService', error);
    }
    return true;
  }

  public async appInit(data: any): Promise<boolean> {
    let portal_version = environment.package_app_version;
    let app_info: any = {};
    if (Capacitor.getPlatform() != 'web') {
      app_info = await App.getInfo();
    }

    data = {
      user_idx: await this.user_service.getUserIdx(),
      user_device_idx: await this.user_service.getUserDeviceIdx(),
      platform: Capacitor.getPlatform(),
      app_init_datetime: new Date().toISOString(),
      portal_version,
      app_build: app_info.build,
      app_version: app_info.version
    };

    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].appInit(data));
    }

    try {
      await Promise.all(list);
    } catch (error) {
      console.error('appInit', error);
    }
    return true;
  }

  async appInstall(data: any) {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];

    const save_data = {
      ...data,
      ...this.user_data,
      platform: Capacitor.getPlatform()
    };

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].appInstall(save_data));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('2 appInstall error', error);
    }

    return true;
  }

  public async appOpen(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];

    const user_data = {
      user_idx: await this.user_service.getUserIdx(),
      user_device_idx: await this.user_service.getUserDeviceIdx()
    };
    const save_data = {
      platform: Capacitor.getPlatform(),
      ...data,
      ...user_data,
      regist_datetime: new Date()
    };
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].appOpen(save_data));
    }

    try {
      await Promise.all(list);
    } catch (error) {
      console.error('appOpen', error);
    }
    return true;
  }

  /** userId 표시 */
  async setUserIdx() {
    await this.initialize();

    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].setUserIdx(this.user_data.user_idx));
    }

    try {
      await Promise.all(list);
    } catch (error) {
      console.error('setUserIdx', error);
    }
    return true;
  }

  async setUserProperty(data: any) {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].setUserProperty(data));
    }

    try {
      await Promise.all(list);
    } catch (error) {
      console.error('setUserProperty', error);
    }
    return true;
  }

  //푸미스트 목록 노출
  async logFumistImpression(class_room: any) {
    if (!class_room) {
      return;
    }
    await this.initialize();
    const list = [];

    class_room = this.utils.filterObject(class_room, this.constant.log_filer.class_room);

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logFumistImpression({
          ...class_room,
          ...this.user_data,
          event_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logFumistImpression', error);
    }
    return true;
  }

  public async logFumistClick(class_room: any): Promise<boolean> {
    if (!class_room) {
      return;
    }
    await this.initialize();
    const list = [];

    class_room = this.utils.filterObject(class_room, this.constant.log_filer.class_room);

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logFumistClick({
          ...class_room,
          ...this.user_data,
          event_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logFumistClick', error);
    }
    return true;
  }

  //푸미스트 상세 진입
  async logFumistView(class_room: any) {
    if (!class_room) {
      return;
    }
    await this.initialize();
    const list = [];

    class_room = this.utils.filterObject(class_room, this.constant.log_filer.class_room);

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logFumistView({
          ...class_room,
          ...this.user_data,
          event_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logFumistView', error);
    }
    return true;
  }

  //푸미스트 등록
  async logFumistJoin(class_room: any) {
    if (!class_room) {
      return;
    }
    await this.initialize();
    const list = [];

    class_room = this.utils.filterObject(class_room, this.constant.log_filer.class_room);

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logFumistJoin({
          ...class_room,
          ...this.user_data,
          event_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logFumistJoin', error);
    }
    return true;
  }
  async logItemImpression(item: any) {
    if (!item) {
      return;
    }
    await this.initialize();
    const list = [];

    item = this.utils.filterObject(item, this.constant.log_filer.item);

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logItemImpression({
          ...item,
          ...this.user_data,
          event_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logItemImpression', error);
    }
    return true;
  }

  //아이템 뷰 이벤트
  async logItemView(item: any) {
    if (!item) {
      return;
    }
    await this.initialize();
    const list = [];

    item = this.utils.filterObject(item, this.constant.log_filer.item);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logItemView({
          ...item,
          ...this.user_data,
          item_view_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logItemView', error);
    }
    return true;
  }

  async logItemClick(item: any) {
    if (!item) {
      return;
    }
    await this.initialize();
    const list = [];

    item = this.utils.filterObject(item, this.constant.log_filer.item);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logItemClick({
          ...item,
          ...this.user_data,
          item_click_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logItemClick', error);
    }
    return true;
  }

  public async logPageView(url: string): Promise<boolean> {
    if (!url) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logPageView({ url, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logPageView', error);
    }
    return true;
  }

  complete_order_transaction = {};

  public async logCompleteOrderTransaction(data: OrderTransaction): Promise<boolean> {
    if (!data) {
      return;
    }
    console.log('!!from root logCompleteOrderTransaction' + JSON.stringify(data));
    //중복방지
    if (this.complete_order_transaction[data.order_transaction_idx]) {
      return;
    }
    this.complete_order_transaction[data.order_transaction_idx] = true;

    await this.initialize();
    const list = [];

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logCompleteOrderTransaction({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }

    //order item
    let order_item_list = data.order_item_list;
    for (let i = 0; i < order_item_list.length; i++) {
      list.push(this.logCompleteOrderItem(order_item_list[i]));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logCompleteOrderTransaction', error);
    }

    return true;
  }

  public async logCompleteOrderItem(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];

    data = this.utils.filterObject(data, this.constant.log_filer.order_item);

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logCompleteOrderItem({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logCompleteOrderItem', error);
    }
    return true;
  }

  //장바구니 담기 로그
  async logAddToCart(item: any) {
    if (!item) {
      return;
    }
    await this.initialize();
    const list = [];

    item = this.utils.filterObject(item, this.constant.log_filer.cart);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(
        this.trackerList[i].logAddToCart({
          ...item,
          ...this.user_data,
          add_cart_datetime: new Date().toISOString()
        })
      );
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logAddToCart', error);
    }
    return true;
  }

  async logBannerView(banner: any) {
    await this.initialize();
    const list = [];

    banner = this.utils.filterObject(banner, this.constant.log_filer.banner);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logBannerView({ ...banner, ...this.user_data, event_datetime: new Date().toISOString() }));
    }

    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerView', error);
    }
    return true;
  }

  public async logBannerImpression(banner: any): Promise<boolean> {
    await this.initialize();
    const list = [];
    banner = this.utils.filterObject(banner, this.constant.log_filer.banner);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logBannerImpression({ ...banner, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }
    return true;
  }

  public async logShopSectionView(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    data = this.utils.filterObject(data, this.constant.log_filer.shop_section);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logShopSectionView({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }

    return true;
  }
  public async logShopSectionImpression(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];

    data = this.utils.filterObject(data, this.constant.log_filer.shop_section);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logShopSectionImpression({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }
    return true;
  }
  public async logNoticeDialogView(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    data = this.utils.filterObject(data, this.constant.log_filer.notice_dialog);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logNoticeDialogView({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }
    return true;
  }
  public async logNoticeDialogImpression(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    data = this.utils.filterObject(data, this.constant.log_filer.notice_dialog);
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logNoticeDialogImpression({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }
    return true;
  }
  public async logEvent(data: any): Promise<boolean> {
    return true;
  }
  public async login(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].login({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }
    return true;
  }

  public async logPostImpression(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logPostImpression({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logPostImpression', error);
    }
    return true;
  }
  public async logPostClick(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logPostClick({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }
    return true;
  }
  public async logPostView(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logPostView({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }

    return true;
  }
  public async logPostLike(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logPostLike({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }

    return true;
  }
  public async logPostReply(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logPostReply({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }

    return true;
  }

  public async logWishItem(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logWishItem({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logBannerImpression', error);
    }

    return true;
  }

  public async logSearchItem(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logSearchItem({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logSearchItem', error);
    }

    return true;
  }
  public async logSearchPost(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];
    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logSearchPost({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logSearchPost', error);
    }

    return true;
  }

  public async logUserCheckIn(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];

    if (this.user_data && !this.user_data.user_idx) {
      this.user_data.user_idx = await this.user_service.getUserIdx();
    }

    if (this.user_data && !this.user_data.user_device_idx) {
      this.user_data.user_device_idx = await this.user_service.getUserDeviceIdx();
    }

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logUserCheckIn({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }
    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logUserCheckIn', error);
    }
    return true;
  }

  public async logUserCheckOut(data: any): Promise<boolean> {
    if (!data) {
      return;
    }
    await this.initialize();
    const list = [];

    if (this.user_data && !this.user_data.user_idx) {
      this.user_data.user_idx = await this.user_service.getUserIdx();
    }

    if (this.user_data && !this.user_data.user_device_idx) {
      this.user_data.user_device_idx = await this.user_service.getUserDeviceIdx();
    }

    for (let i = 0; i < this.trackerList.length; i++) {
      list.push(this.trackerList[i].logUserCheckOut({ ...data, ...this.user_data, event_datetime: new Date().toISOString() }));
    }

    try {
      await Promise.all(list);
    } catch (error) {
      console.error('logUserCheckOut', error);
    }
    return true;
  }

  async reset() {
    if ('web' != Capacitor.getPlatform()) {
      await this.initialize();
      await FirebaseAnalytics.reset();
    }
  }
}
