import { Component, ViewChild, NgZone } from '@angular/core';

import { Platform, IonRouterOutlet, ModalController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';

import * as moment from 'moment';
import { AppService } from './services/app.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TrackerService } from './services/tracker/tracker.service';
import { AnimationItem } from 'lottie-web';
import { environment } from 'src/environments/environment';
import Portals from '@ionic/portals';
import { AppUpdateService } from './services/app-update.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @ViewChild(IonRouterOutlet, { static: true })
  routerOutlet: IonRouterOutlet;
  public isIphoneWithNotch: boolean = false;

  constructor(
    public app: AppService,
    private tracker: TrackerService,
    public route: ActivatedRoute,
    public router: Router,
    private platform: Platform,
    private zone: NgZone,
    private app_update: AppUpdateService,
    public modalController: ModalController
  ) {
    moment.locale('ko');
    this.initializeApp();
  }

  async initializeApp() {
    this.app.setLocalData('allow_temp', true);

    this.app.splash.start_time = new Date().getTime();

    this.platform.ready().then(async () => {
      if (this.app.isApp()) {
        setTimeout(() => {
          SplashScreen.hide();
        }, 600);

        await this.app.getUserIdx();

        this.platform.resume.subscribe(async () => {
          this.tracker.logUserCheckIn({});
        });

        this.platform.pause.subscribe(() => {
          this.tracker.logUserCheckOut({});
        });
      }

      if (environment.is_native_portal) {
        this.initNativePortal();
      }

      // 웹 프로세스 초기화 하기
      await this.initApp();
    });
  }

  async initNativePortal() {
    // Passed in value is { foo: 'bar' }
    Portals.getInitialContext<{ url: string; param: any }>().then((context) => {
      if (context && context.value) {
        console.log('getInitialContext url', context.value.url);
        console.log('getInitialContext param', JSON.stringify(context.value.param));
        if (context.value.url) {
          console.log('initNativePortal gogo!', context.value.url);
          this.app.nativeGo(context.value.url, context.value.param, false);
        }
      }
    });

    // const callback = (result: { topic: string; data: any }) => {
    //   /* run callback code here on publish */
    //   alert(result.topic);
    // };
    // const subscribtion = await Portals.subscribe({ topic: 'alert' }, callback);
  }

  //앱 초기화하기
  async initApp() {
    this.app.routerOutlet = this.routerOutlet;

    //앱서비스 초기화
    this.app.init();

    //tracker init
    // await this.tracker.initialize();

    return true;
  }

  private onResize(event: UIEvent) {
    this.app.changeWindowSize();
  }

  async initializeNativeAppProcess() {
    // 종료페이지 지정하기
    // this.platform.backButton.subscribeWithPriority(-1, () => {
    //   if (!this.routerOutlet.canGoBack()) {
    //     let current = this.router.url;
    //     if (current.startsWith('app/home/recommend')) {
    //       this.app.justGo('app/home/recommend');
    //     } else {
    //       App.exitApp();
    //     }
    //   }
    // });
    // app url open
    // App.addListener('appUrlOpen', (data: any) => {
    //   this.zone.run(async () => {
    //     try {
    //       alert('appUrlOpen' + JSON.stringify(data));
    //       // this.app.goUrlDirect(data);
    //       const slug = decodeURIComponent(data.url.split('fumi.co.kr').pop()).substr(1);
    //       if (slug && data.url.includes('fumi.co.kr')) {
    //         this.app.goUrlDirect(slug);
    //         return;
    //       }
    //     } catch (e) {
    //       console.error('에러!!!', JSON.stringify(e));
    //     }
    //   });
    // });
  }

  // onSuccessAppsFlyerInit(result) {
  //   const conversionData = JSON.parse(result);
  //   // alert(
  //   //   'onSuccessAppsFlyerInit conversionData' +
  //   //     JSON.stringify(conversionData),
  //   // );
  //   if (conversionData.data.is_first_launch) {
  //     // First Launch
  //     console.log('First launch');
  //   }
  //   if (
  //     conversionData.data.is_first_launch &&
  //     conversionData.data.deep_link_value
  //   ) {
  //     this.checkDeeplink(conversionData.data.deep_link_value);
  //   }
  // }

  // checkDeeplink(data) {
  //   try {
  //     const url = decodeURIComponent(data.split('fumi.co.kr').pop()).substr(1);
  //     this.app.goUrlDirect(url);
  //   } catch (e) {
  //     console.error('checkDeepLink error', e);
  //   }
  // }

  clickChannelTalkOpen() {
    // this.channel.showMessenger();
  }

  // async syncOneSignalTag() {
  //   const user = this.app.currentUser();
  //   if (user) {
  //     try {
  //       this.db.user_select_onesignal().subscribe((onesignal: OneSignalData) => {
  //         const last_cart_update_time = this.util.convertToUnixTimestamp(onesignal.last_cart_update_time);
  //         const last_order_time = this.util.convertToUnixTimestamp(onesignal.last_order_time);
  //         const onesignal_value = {
  //           user_name: onesignal.user_name,
  //           last_cart_update_item: onesignal.last_cart_update_item,
  //           amount_spent: onesignal.amount_spent,
  //           birth_year: onesignal.birth_year,
  //           age_range: onesignal.age_range,
  //           birthdate: this.util.getBirthdayTimestamp(onesignal.birthdate, 'day'),
  //           birth_month: this.util.getBirthdayTimestamp(onesignal.birthdate, 'month'),
  //           last_cart_update_time,
  //           last_order_time,
  //           last_cart_update_item_category_name: onesignal.last_cart_update_item_info.item_category.category_name,
  //           last_cart_update_item_idx: onesignal.last_cart_update_item_info.item_idx,
  //           user_idx: user.user_idx,
  //           user_type: 'normal',
  //           push_alarm_onoff: user.push_alarm_onoff,
  //           last_wish_update_item: onesignal.last_wish.item.title,
  //           last_wish_update_item_idx: onesignal.last_wish.item.item_idx,
  //           last_wish_update_time: this.util.convertToUnixTimestamp(onesignal.last_wish.regist_datetime),
  //           last_wish_update_item_category_name: onesignal.last_wish.item.item_category.category_name,
  //           last_wish_update_item_category_name_idx: onesignal.last_wish.item.item_category.category_idx,
  //           last_coupon_update_time: this.util.convertToUnixTimestamp(onesignal.last_coupon.regist_datetime),
  //           last_coupon_exprie_time: this.util.convertToUnixTimestamp(onesignal.last_coupon.expire_datetime),
  //           last_coupon_update_idx: onesignal.last_coupon.coupon_idx,
  //           last_coupon_update_name: onesignal.last_coupon.coupon_rule.title,
  //           last_coupon_update_amount: onesignal.last_coupon.coupon_rule.amount
  //         };
  //         this.app.oneSignalSync(onesignal_value);
  //       });
  //     } catch (e) {
  //       console.warn(e);
  //     }
  //   } else {
  //     const wish: ShopWish[] = await this.wishService.getLocalWishList({
  //       skip: 0,
  //       take: 1
  //     });
  //     let last_wish_item: Item;
  //     if (wish.length > 0) {
  //       const temp = await this.db.select_item_list_by_idx_list([wish[0].item_idx]);
  //       last_wish_item = temp.length > 0 ? temp[0] : null;
  //     }
  //     this.app.oneSignalSync({
  //       user_idx: this.app.getTempUserIdx(),
  //       user_type: 'anonymous',
  //       last_wish_update_item: last_wish_item?.title,
  //       last_wish_update_item_idx: last_wish_item?.item_idx,
  //       last_wish_update_time: wish.length > 0 ? this.util.convertToUnixTimestamp(wish[0].regist_datetime) : null
  //     });
  //   }
  // }

  showModal = true;

  onDidDismiss() {}

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }
}
